import { createAction, createReducer, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { companyApi } from '@/api';
import { RootState } from '@/store';

export interface State {
    sharedCompanyList: Domain.SharedCompaniesByTypeItem[];
}
const initialState: State = {
    sharedCompanyList: [],
};

export const setSharedCompanyList = createAction(
    '@organisation/sharedCompanies/setSharedCompanyList',
    withPayloadType<Domain.SharedCompaniesByTypeItem[]>(),
);
export const selectSharedCompanyList: Selector<RootState, Domain.SharedCompaniesByTypeItem[]> = state =>
    state.company.sharedCompanies.sharedCompanyList;

export const loadSharedCompanyList =
    (contentType: Domain.SharedCompanyContentType): ThunkAction =>
    async dispatch => {
        const appointmentTypeList = await companyApi.GetSharedCompaniesByType(contentType);
        dispatch(setSharedCompanyList(appointmentTypeList));
    };

export const sharedCompaniesReducer = createReducer(initialState, builder =>
    builder.addCase(setSharedCompanyList, (state, action) => {
        state.sharedCompanyList = action.payload;
    }),
);
