import {
    Pagination,
    Sorting,
    CollectStockAndStatuses,
    CollectConfiguration,
    CollectLockerImagesPage,
    UpdateCollectConfigurationPayload,
    UpdateLockerItemsPayload,
    UpdateLockerStatusesPayload,
    RegisterCollectModuleStatusPayload,
    APICollectModuleStatusHistories,
    CollectModuleStatusHistories,
    DisableCollectLockerPayload,
    EnableCollectLockerPayload,
    DeliverLockerItemsPayload,
    AddLockerImagePayload,
    AssignLockerImagePayload,
    MarkCurrentLockerImageAsUsedPayload,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class CollectEndpoint extends Endpoint {
    public async GetStockAndStatuses(deviceId?: string): Promise<CollectStockAndStatuses> {
        const stock = await this.client.get({
            url: deviceId ? `/device/${deviceId}/lockers` : '/device/lockers',
        });

        this.guardResponseSchema('#/definitions/CollectStockAndStatuses', stock);

        return stock;
    }

    public async GetModuleStatuses(deviceId: string): Promise<CollectModuleStatusHistories> {
        const moduleStatuses: APICollectModuleStatusHistories = await this.client.get({
            url: `/device/${deviceId}/module-metrics`,
        });

        this.guardResponseSchema('#/definitions/APICollectModuleStatusHistories', moduleStatuses);

        const data: CollectModuleStatusHistories = {};
        if (Array.isArray(moduleStatuses)) {
            for (const index in moduleStatuses) {
                data[index] = moduleStatuses[index];
            }
        } else {
            for (const index of Object.keys(moduleStatuses)) {
                data[index] = moduleStatuses[index];
            }
        }

        return data;
    }

    public async GetConfiguration(): Promise<CollectConfiguration> {
        const configuration: CollectConfiguration = await this.client.get({
            url: '/device/locker-group-configuration',
        });

        this.guardResponseSchema('#/definitions/CollectConfiguration', configuration);

        return configuration;
    }

    public async SaveConfiguration(payload: UpdateCollectConfigurationPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateDeviceLockerGroupConfiguration',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Device\\UpdateDeviceLockerGroupConfiguration\\UpdateDeviceLockerGroupConfiguration',
                payload,
            },
        });
    }

    public async UpdateLockerItems(payload: UpdateLockerItemsPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateLockerItems',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Device\\Locker\\UpdateLockerItems\\UpdateLockerItems',
                payload,
            },
        });
    }

    public async AddLockerImage(payload: AddLockerImagePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/AddLockerImage',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Device\\Locker\\LockerImage\\AddLockerImage\\AddLockerImage',
                payload,
            },
        });
    }

    public async AssignLockerImage(payload: AssignLockerImagePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/AssignLockerImage',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Device\\Locker\\LockerImage\\AssignLockerImage\\AssignLockerImage',
                payload,
            },
        });
    }

    public async MarkCurrentLockerImageAsUsed(payload: MarkCurrentLockerImageAsUsedPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/MarkCurrentLockerImageAsUsed',
            body: {
                commandName:
                    'App\\Domain\\WriteModel\\Device\\Locker\\LockerImage\\MarkCurrentLockerImageAsUsed\\MarkCurrentLockerImageAsUsed',
                payload,
            },
        });
    }

    public async GetLockerImages(
        deviceId: string,
        pagination: Pagination,
        sorting: Sorting,
        filters: {
            moduleId?: string;
            lockerId?: string;
        },
    ): Promise<CollectLockerImagesPage> {
        const data = await this.client.get({
            url: `/locker/locker-image/device/${deviceId}`,
            params: {
                pagination,
                sorting,
                filters,
            },
        });

        this.guardResponseSchema('#/definitions/CollectLockerImagesPage', data);

        return data;
    }

    public async GetLockerImagesForPickup(pickupId: string, pagination: Pagination, sorting: Sorting): Promise<CollectLockerImagesPage> {
        const data = await this.client.get({
            url: `/locker/locker-image/pickup/${pickupId}`,
            params: {
                pagination,
                sorting,
            },
        });

        this.guardResponseSchema('#/definitions/CollectLockerImagesPage', data);

        return data;
    }

    public async GetLockerImagesForOrder(orderId: string, pagination: Pagination, sorting: Sorting): Promise<CollectLockerImagesPage> {
        const data = await this.client.get({
            url: `/locker/locker-image/order/${orderId}`,
            params: {
                pagination,
                sorting,
            },
        });

        this.guardResponseSchema('#/definitions/CollectLockerImagesPage', data);

        return data;
    }

    public async DeliverLockerItems(payload: DeliverLockerItemsPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/DeliverLockerItems',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Device\\Locker\\DeliverLockerItems\\DeliverLockerItems',
                payload,
            },
        });
    }

    public async UpdateLockerStatuses(payload: UpdateLockerStatusesPayload): Promise<void> {
        try {
            await this.client.post({
                url: '/command/dispatch/UpdateLockerStatuses',
                body: {
                    commandName: 'App\\Domain\\WriteModel\\Device\\Locker\\UpdateLockerStatuses\\UpdateLockerStatuses',
                    payload,
                },
            });
        } catch (e) {
            console.error(e);
        }
    }

    public async RegisterCollectModuleStatus(payload: RegisterCollectModuleStatusPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RegisterCollectModuleStatus',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Device\\Locker\\ModuleMetrics\\RegisterModuleMetrics\\RegisterModuleMetrics',
                payload,
            },
        });
    }

    public async DisableLocker(payload: DisableCollectLockerPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/DisableLocker',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Device\\Locker\\DisableLocker\\DisableLocker',
                payload,
            },
        });
    }

    public async EnableLocker(payload: EnableCollectLockerPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/EnableLocker',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Device\\Locker\\EnableLocker\\EnableLocker',
                payload,
            },
        });
    }

    public GetLockerImageURL(imageId: string): string {
        return this.client.buildApiURL(`/locker/locker-image/${imageId}`, true);
    }

    public async UpdateLockerItemsAndMarkCurrentLockerImageAsUsed(payload: UpdateLockerItemsPayload): Promise<void> {
        await this.UpdateLockerItems(payload);

        if (payload.items.length === 0) {
            await this.MarkCurrentLockerImageAsUsed({
                deviceId: payload.deviceId,
                moduleId: payload.moduleId,
                lockerId: payload.lockerId,
            });
        }
    }
}
