import * as React from 'react';

import { Domain } from 'api';
import { useGettext } from 'data-store';
import { Pill, color } from 'ui-components';

import styles from '../PublicAnnouncementsOverview.scss';

export default function PublicTagsList(props: {
    className?: string;
    tags: Domain.CmsTag[];
    type: 'item-tags' | 'sidebar-fiter-tags';
    onTagClick?: (tagId: Domain.CmsTag['cmsTagId']) => Promise<void>;
    activeTagId?: Domain.CmsTag['cmsTagId'];
    allowClick: boolean;
}) {
    const { gettext } = useGettext();

    return (
        <div
            className={`${props.className || ''} ${styles.PublicAnnouncementTags} ${props.type === 'sidebar-fiter-tags' ? 'SidebarFiterTags' : 'ItemTags'} ${props.allowClick ? 'AllowClick' : ''}`}
        >
            <>
                {props.type === 'sidebar-fiter-tags' ? (
                    <div
                        className={!props.activeTagId ? styles.ActiveFilterTag : ''}
                        onClick={() => (props.onTagClick ? props.onTagClick('') : null)}
                        data-testid="tag-all"
                    >
                        <span></span>
                        <span>{gettext('All')}</span>
                    </div>
                ) : null}
                {props.tags.map(tag => {
                    return (
                        <div
                            className={props.activeTagId === tag.cmsTagId ? styles.ActiveFilterTag : ''}
                            key={tag.cmsTagId}
                            onClick={() => (props.onTagClick ? props.onTagClick(tag.cmsTagId) : null)}
                            data-testid={`tag-${tag.name}`}
                        >
                            <Pill
                                className={color.White + ' px-5 py-0 mr-7 mb-7'}
                                style={{
                                    backgroundColor: tag.color,
                                }}
                                size="xs"
                            >
                                {tag.name}
                            </Pill>
                        </div>
                    );
                })}
            </>
        </div>
    );
}
