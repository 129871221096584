import { Domain } from 'api';

import { ThunkAction } from '@/action';
import { pickupApi } from '@/api';
import * as Integration from '@/Integration';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';
import { URLParams, URLQuery } from '@/routing';

export const selectors = makeSelectors<Domain.PickupDetails>({
    getState: rootState => rootState.pickup.details,
});

export const reducerActions = makeReducerActions<Domain.PickupDetails>({
    reducerPrefix: '@pickup/details',
});

export const baseActions = makeActions<Domain.PickupDetails>({
    loadApi: options => pickupApi.GetPickupDetails(options.urlParams.pickupId),
    reducerActions,
    selectors,
});
export const actions = {
    ...baseActions,
    load:
        (options: { urlParams: URLParams; urlQuery: URLQuery }): ThunkAction =>
        async dispatch => {
            const promises: any[] = [dispatch(baseActions.load(options))];

            promises.push(dispatch(Integration.configuredIntegrationsTypeState.loadConfiguredIntegrationsByType('deliverPickups')));

            await Promise.all(promises);
        },
};

export const detailsReducer = makeReducer<Domain.PickupDetails>({
    reducerActions,
});
