import { Branch } from './Branch';
import { Brand } from './Brand';
import { Country, Locale, LocalizedValue } from './Localisation';
import { Organisation } from './Organisation';
import { Pagination } from './Pagination';
import { Price } from './Price';
import { ProductNotSuitedForReasons } from './Product';
import { ProductCategory, UnfoldedCategory } from './ProductCategory';
import { ProductCodes } from './ProductCode';
import { ProductDescriptions } from './ProductDescriptions';
import { ProductDetails, ProductConservation } from './ProductDetails';
import { ProductDimensions } from './ProductDimensions';
import { ProductImage } from './ProductImage';
import { Sorting } from './Sorting';
import { Stock } from './Stock';

export interface APIAvailableProductCategory {
    categoryId: string;
    localizedNames: LocalizedValue;
    sequence: number;
    parentCategoryId: string | null;
    industry: 'Pharmacy';
    country: Country;
    subCategories: APIAvailableProductCategories;
    productSourceId?: string | null;
}

export type APIAvailableProductCategories = APIAvailableProductCategory[];

export type AvailableProductCategory = Omit<APIAvailableProductCategory, 'subCategories'> & {
    subCategories: AvailableProductCategory[];
};

export type SelectedProductType = 'customProduct' | 'availableProduct';

export type SelectedProductImageShotType = 'productshot' | 'packshot' | 'frontal';

export const ProductSelectionTypes = ['webShopProductSelection', 'inStoreProductSelection', 'robotProductSelection'];
export type ProductSelectionType = (typeof ProductSelectionTypes)[number];

export const ProductCompletenessFilterTypes = [
    'suitedForWebshop',
    'suitedForProductWall',
    'suitedForCustomPage',
    'suitedForVendingMachine',
];
export type ProductCompletenessFilter = (typeof ProductCompletenessFilterTypes)[number];

export type ProductSelectionStringBoolFilter = 'true' | 'false';

export interface ProductSelection {
    organisationIdsToBeExcluded: Organisation['organisationId'][];
    brandIdsToBeExcluded: Brand['brandId'][];
    activeIngredientIdsToBeExcluded: string[];
    categoryIdsToBeExcluded: AvailableProductCategory['categoryId'][];
    scientificCategoryIdsToBeExcluded: string[];
    productIdsToBeExcluded: string[];
    stockSourceIdsToBeIncluded: string[];
    conservationsToBeExcluded: ProductConservation[];
    excludeRequiresPrescription?: boolean | null;
    excludeRequiresWrittenRequest?: boolean | null;
    excludeUncategorizedProducts?: boolean | null;
    productIdsToBeIncluded: string[];
    productIdsToBeIncludedForRobotHomeScreen: string[];
    productIdsToBeIncludedFromImport: string[];
}

export interface SelectedProduct {
    country?: string;
    productId: string;
    productType: SelectedProductType;
    productCodes: ProductCodes;
    localizedNames: LocalizedValue;
    industry: 'Pharmacy';
    status: 'active' | 'inactive';
    advisedPriceIncludingVat?: Price | null;
    priceIncludingVat?: Price | null;
    priceIncludingVatImported?: Price | null;
    priceIncludingVatOverride?: Price | null;
    promotionalPriceIncludingVat?: Price | null;
    promotionalPriceIncludingVatImported?: Price | null;
    promotionalPriceIncludingVatOverride?: Price | null;
    companyPriceIncludingVatOverride?: Price | null;
    companyPromotionalPriceIncludingVatOverride?: Price | null;
    vatRate?: number | null;
    vatRateOverride?: number | null;
    companyVatRateOverride?: number | null;
    brandIds: string[];
    organisationIds: string[];
    categoryIds: string[];
    unfoldedCategories?: UnfoldedCategory[];
    images: ProductImage[];
    frontals: ProductImage[];
    updatedOn?: string | null;

    brands?: Brand[];
    organisations?: Organisation[];
    categories?: ProductCategory[];

    branchName: Branch['branchName'] | null;
    branchId: Branch['branchId'] | null;

    isSuitedForProductWall: boolean;
    isSuitedForCustomPage: boolean;
    isSuitedForWebshop: boolean;
    isSuitedForVendingMachine: boolean;
    localizedDescriptions: ProductDescriptions;

    selectionTypes?: ProductSelectionType[];

    stock: Stock[];

    requiresPrescription?: boolean | null;
}

export type SelectedProductDetails = SelectedProduct & {
    details: ProductDetails;
    dimensions: ProductDimensions;
    weight?: number | null;
    activeIngredientIds: string[];
    activeIngredients: string[];
    packageQuantity?: string | null;
    requiresWrittenRequestByPatient?: boolean | null;
    publicPrice?: Price | null;
    pharmacistPrice?: Price | null;
    refundValueWithOmnio?: Price | null;
    refundValueWithoutOmnio?: Price | null;
    notSuitedForReasons?: ProductNotSuitedForReasons | null;
};

export interface SelectedProductsPage {
    items: SelectedProduct[];
    total: number;
    count: number;
    pagination: Pagination;
    sorting?: Sorting<keyof SelectedProduct> | null;
    search?: string | null;
    locale?: Locale | null;
    selectionType?: ProductSelectionType | null;
}

export interface SelectionRuleTreeFilters {
    categoryIds: AvailableProductCategory['categoryId'][];
    organisationIds: Organisation['organisationId'][];
    brandIds: Brand['brandId'][];
}
