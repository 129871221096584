import { Pagination, Sorting, OrdersPage, ErpOrderSyncStatus } from '@/Domain';

import Endpoint from './Endpoint';

export default class OrdersEndpoint extends Endpoint {
    public async GetOrders(
        pagination: Pagination,
        sorting: Sorting,
        filters: {
            paymentMethod?: string;
            shippingMethod?: string;
            orderStatus?: string;
            salesChannelId?: string;
            createdFrom?: string;
            createTill?: string;
            isUnderReview?: string;
            transactions?: string;
            paymentStatus?: string;
            mustMatchExactPaymentStatuses?: string;
            deliveries?: string;
            salesChannelType: 'webshop' | 'device';
            isExportedToIntegration?: ErpOrderSyncStatus;
        },
        search?: string,
    ): Promise<OrdersPage> {
        let url = `/order/overview/salesChannel/${filters?.salesChannelId}`;
        if (filters?.salesChannelId == 'all') {
            url = `/order/overview/salesChannelType/${filters.salesChannelType}`;
        }

        let paymentStatus = filters?.paymentStatus?.split(',');
        let mustMatchExactPaymentStatuses = filters?.mustMatchExactPaymentStatuses;
        if (filters?.transactions) {
            mustMatchExactPaymentStatuses = 'true';
            switch (filters?.transactions) {
                case 'failedAttemptsOnly':
                    paymentStatus = ['FAILURE'];
                    break;
                case 'paidAttemptsOnly':
                    paymentStatus = ['PAID'];
                    break;
                case 'failedAndPaidAttemptsOnly':
                    paymentStatus = ['FAILURE', 'PAID'];
                    break;
            }
        }
        const filtersObject = {
            ...filters,
            paymentStatus,
            mustMatchExactPaymentStatuses,
            deliveryStatus: filters?.deliveries?.split(',').filter(Boolean),
            orderStatuses: filters?.orderStatus?.split(',').filter(Boolean),
            paymentMethods: filters?.paymentMethod?.split(',').filter(Boolean),
            shippingMethods: filters?.shippingMethod?.split(',').filter(Boolean),
            deliveries: null,
            orderStatus: null,
            isExportedToIntegration: filters?.isExportedToIntegration
                ? filters?.isExportedToIntegration === 'success'
                    ? true
                    : false
                : undefined,
        };

        if (filters && filters.salesChannelId) {
            delete filtersObject.salesChannelId;
        }
        if (filters && filters.salesChannelType) {
            // @ts-ignore
            delete filtersObject.salesChannelType;
        }
        const response = await this.client.get({
            url,
            params: {
                pagination,
                sorting,
                filters: filtersObject,
                search,
            },
        });

        this.guardResponseSchema('#/definitions/OrdersPage', response);

        return response;
    }

    public async RequeueFailedOrderToBeExportedToIntegration({
        branchId,
        companyId,
        orderId,
    }: {
        branchId?: string | null;
        companyId: string;
        orderId: string;
    }): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RequeueFailedOrderToBeExportedToIntegration',
            body: {
                commandName:
                    'App\\Domain\\WriteModel\\Order\\RequeueFailedOrderToBeExportedToIntegration\\RequeueFailedOrderToBeExportedToIntegration',
                payload: {
                    companyId,
                    branchId,
                    orderId,
                },
            },
        });
    }
}
