import * as React from 'react';

import { Domain } from 'api';
import { useGettext } from 'data-store';
import { Modal, ModalHeader, ModalBody, ModalFooter, Tx, Button, useMediaQuery } from 'ui-components';

import LocalizedValue from '@/LocalizedValue';

import CmsBlocksRenderer from './CmsBlocksRenderer';

export default function InfoCmsItemModal({ onClose, cmsItem }: { onClose: () => void; cmsItem: Domain.CmsItem }) {
    const { gettext } = useGettext();
    const onSmallScreen = useMediaQuery('(max-width: 1023px)');
    const spacing = onSmallScreen ? '5%' : '20%';

    return (
        <Modal
            onClose={onClose}
            style={{
                padding: '0',
                top: 'auto',
                right: spacing,
                bottom: 'auto',
                left: spacing,
                maxHeight: 'calc(100% - 48px)',
            }}
        >
            <ModalHeader
                onClose={onClose}
                withCloseAction
            >
                <Tx
                    level="heading-4"
                    className="mb-0"
                >
                    <LocalizedValue
                        value={cmsItem.name}
                        fallback
                        hideFallbackLangPrefix
                    />
                </Tx>
            </ModalHeader>

            <ModalBody overflowScroll>
                <CmsBlocksRenderer blocks={cmsItem.blocks as Domain.CmsItemBlock[]} />
            </ModalBody>

            <ModalFooter>
                <Button
                    variant="tertiary"
                    variantSize="s"
                    onClick={onClose}
                >
                    {gettext('Close')}
                </Button>
            </ModalFooter>
        </Modal>
    );
}
