import LocalCommClientException from './LocalCommClientException';
import { MaticPlc } from './MaticPLC';
import {
    LocalcommStatus,
    DeviceInfo,
    ReceivedBarcodeMessage,
    ReceivedGetInfoMessage,
    ReceivedWatchdogMessage,
    keyMessageCallback,
    DEVICEINFO_DEFAULT,
    ReceivedPrintscreenMessage,
    PrintscreenStatus,
    ReceivedHatchPhotoMessage, ThermoValuesResponseMessage, LockerStatusResponseMessage,
    SetLightIndicatorResponseMessage,
    PrintResponseMessage
} from '../../Domain';
import { Signal } from '../Signal';


export default class LocalCommClient {
    ThermoValuesReceived = new Signal<LocalCommClient, ThermoValuesResponseMessage>();
    LockerStatusReceived = new Signal<LocalCommClient, LockerStatusResponseMessage>();
    SetLightIndicatorResponseReceived = new Signal<LocalCommClient, SetLightIndicatorResponseMessage>();
    PrintResponseReceived = new Signal<LocalCommClient, PrintResponseMessage>();

    private ws: WebSocket;
    private lastMessage: any;
    private watchdogTimer: ReturnType<typeof setTimeout>;
    private lastBarcodeValue: string = '';
    private lastGetInfoValue: DeviceInfo = DEVICEINFO_DEFAULT;
    private lastPrintScreenStatusValue: PrintscreenStatus | undefined;
    private lastHatchPhoto: string = '';
    private videoCallResponse: any = undefined;
    private GetInfoProcess: boolean = false;
    private TakePrintscreenProcess: boolean = false;
    private GetInfoProcessCanceled: boolean = false;
    private TakePrintscreenProcessCanceled: boolean = false;
    private _isDisabled: boolean;

    public localCommStatus: LocalcommStatus = {
        scannerIsEnabled: false,
        connected: false,
        ip: '',
        macPC: ''
    };

    private hasMaticPLC: boolean = true;
    public maticPLC: MaticPlc;

    public onGetInfoMessageCallbacks: keyMessageCallback = {};
    public onBarcodeMessageCallbacks: keyMessageCallback = {};
    public onPrinterStatusMessageCallBacks: keyMessageCallback = {};

    public onConnectionOpenCallbacks: keyMessageCallback = {};
    public onConnectionCloseCallbacks: keyMessageCallback = {};

    public constructor() {
        this.maticPLC = new MaticPlc(this);
        this.localCommStatus.PLC = this.maticPLC.plcStatus;
    }

    public disable() {
        this._isDisabled = true;
    }

    public enable() {
        this._isDisabled = false;
    }

    public isDisabled() {
        return this._isDisabled;
    }

    public async listen(
        url: string,
        errorHandler: (error: Error | Event) => void
    ): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            if (this._isDisabled) {
                setTimeout(() => {
                    this.listen(url, errorHandler);
                }, 10000);

                return;
            }

            this.ws = new WebSocket(url);
            this.lastMessage = undefined;
            this.localCommStatus.ip = url;

            this.ws.onmessage = (event: MessageEvent) => {
                try {
                    if (event.data) {
                        const message = JSON.parse(event.data);

                        if (!this.handleMessageInternally(message)) {
                            this.lastMessage = message;
                        }
                    }
                } catch (error) {
                    if (error instanceof LocalCommClientException) {
                        errorHandler(error);
                        return;
                    }

                    if (error instanceof SyntaxError) {
                        error.message = 'JSON Parse error: ' + error.message;
                    } else {
                        (error as Error).message = event.type;
                    }

                    errorHandler(error as Error);
                }
            };

            this.ws.onopen = () => {
                // wait 3 seconds until we call onConnectionOpenCallbacks
                setTimeout( () => {
                    this.localCommStatus.connected = true;
                    //this.stopWatchdog();
                    
                    Object.keys(this.onConnectionOpenCallbacks).forEach( callBackId => {
                        this.onConnectionOpenCallbacks[callBackId]();
                    });
                }, 3000);
            };

            this.ws.onerror = (error: Event) => {
                errorHandler(error);
            };

            this.ws.onclose = (event: CloseEvent) => {
                this.localCommStatus.connected = false;

                Object.keys(this.onConnectionCloseCallbacks).forEach( callBackId => {
                    this.onConnectionCloseCallbacks[callBackId](event);
                });

                setTimeout(() => {
                    this.listen(url, errorHandler);
                }, 2000);

                errorHandler(new LocalCommClientException(
                    event.reason
                ));
            };

            const checkReadyStateInterval = setInterval(() => {
                if (this.ws.readyState === 1) {
                    clearInterval(checkReadyStateInterval);
                    resolve(true);
                } else if (this.ws.readyState === 3) {
                    clearInterval(checkReadyStateInterval);
                    this.ws.close();
                }
            }, 100);
        });
    }

    public isConnected() {
        return this.ws && this.ws.readyState === WebSocket.OPEN;
    }

    public send(data: object) {
        if (!this._isDisabled && this.isConnected()) {
            this.ws.send(JSON.stringify(data));
        }
    };

    public getMessage(): Promise<any> {
        return new Promise(resolve => {
            if (this.lastMessage) {
                const message = this.lastMessage;
                this.lastMessage = undefined;
                resolve(message);
            } else {
                setTimeout(() => {
                    resolve(this.getMessage());
                }, 50);
            }
        });
    }

    public getLastInfo(): DeviceInfo {
        return this.lastGetInfoValue;
    }

    public getLastBarcodeInfo(): string {
        return this.lastBarcodeValue;
    }

    private handleMessageInternally(message: any): boolean {
        if (
            message.action === 'command'
            && message.command
            && message.command.watchdog
        ) {
            this.handleWatchdogMessage(message);
            return true;
        }

        if (
            message.action === 'plc' && this.hasMaticPLC && this.maticPLC
        ) {
            this.maticPLC.handlePLCMessage(message)
        }

        if (
            message.action === 'scanner'
            && message.barcode
        ) {
            this.handleBarcodeMessage(message);
        }

        if (
            message.action === 'info'
        ) {
            this.handleGetInfoMessage(message);
        }

        if (
            message.action === 'printscreen'
        ) {
            this.handlePrintscreenMessage(message);
        }


        if (
            message.action === 'hatchPhoto'
        ) {
            this.handleHatchPhotoMessage(message);
        }

        if (
            message.action === 'videoCall-Call'
            || message.action === 'videoCall-EnsureSipConfigured'
            || message.action === 'videoCall-GetCallStatus'
        ) {
            this.videoCallResponse = message;
        }

        if ('locker_configuration' === message.messagetype) {
            this.handleLockerConfigurationResponseMessage(message);
        }

        if ('thermo_values' === message.messagetype) {
            this.handleThermoValuesResponseMessage(message);
        }

        if ('locker_status' === message.messagetype) {
            this.handleLockerStatusResponseMessage(message);
        }

        if ('kiosk-color-light-indicator' === message.action) {
            this.handleSetLightIndicatorResponseMessage(message);
        }

        if ('MT_Printer' === message.action) {
            this.handlePrintResponseMessage(message);
        }

        return false;
    }

    private handleWatchdogMessage(message: ReceivedWatchdogMessage) {
        if (
            message.command
            && message.command.watchdog === 'start'
            && message.command.status === 'ok'
        ) {
            this.sendWatchdogLiveBit();
        } else if (
            message.command
            && message.command.watchdog
            && (message.command.watchdog === 'livebit'
                || message.command.watchdog === 'timeout')
        ) {
            this.sendWatchdogLiveBit();
        } else if (
            message.command
            && message.command.watchdog
            && message.command.watchdog === 'stop'
            && message.command.status === 'ok'
        ) {
            if (this.watchdogTimer) {
                clearTimeout(this.watchdogTimer);
            }
        }
    }

    public stopWatchdog() {
        this.send({
            action: 'command',
            command: {
                watchdog: 'stop'
            }
        });
    }

    public startWatchdog() {
        this.send({
            action: 'command',
            command: {
                watchdog: 'start'
            }
        });
    }

    private sendWatchdogLiveBit() {
        if (this.watchdogTimer) {
            clearTimeout(this.watchdogTimer);
        }

        this.send({
            action: 'command',
            command: {
                watchdog: 'livebit'
            }
        });

        this.watchdogTimer = setTimeout(() => {
            this.sendWatchdogLiveBit();
        }, 9000);
    }

    public setOnGetInfoMessageCallback(callback: (data: DeviceInfo) => void, id: string = 'default') {
        this.onGetInfoMessageCallbacks[id] = callback;
    }

    public unsetOnGetInfoMessageCallback(id: string = 'default') {
        if(typeof this.onGetInfoMessageCallbacks[id] !== 'undefined'){
            delete this.onGetInfoMessageCallbacks[id];
        }
    }

    private handleGetInfoMessage(message: ReceivedGetInfoMessage) {
        if (
            message.network
            && message.network.ip
            && message.network.mac
            && message.network.mask
        ) {
            this.lastGetInfoValue.network.ip = message.network.ip;
            this.lastGetInfoValue.network.mac = message.network.mac;
            this.lastGetInfoValue.network.mask = message.network.mask;
            this.lastGetInfoValue.network.gateway = message.network.gateway;

            this.localCommStatus.macPC = this.lastGetInfoValue.network.mac;

            if (message.network.mode) {
                this.lastGetInfoValue.network.mode = message.network.mode;
            }

            if (message.network.dns1) {
                this.lastGetInfoValue.network.dns1 = message.network.dns1;
            }

            if (message.network.dns2) {
                this.lastGetInfoValue.network.dns1 = message.network.dns2;
            }

            if (message.network.dns) {
                // fallback of older version of localcomm
                this.lastGetInfoValue.network.dns1 = message.network.dns;
            }

            if (typeof message.network.dhcp !== 'undefined') {
                // fallback of older version of localcomm
                this.lastGetInfoValue.network.mode = message.network.dhcp === '1' ? 'dhcp' : 'static';
            }
        }

        if (message.screen && message.screen.rotation) {
            this.lastGetInfoValue.screen.rotation = message.screen.rotation;
        }

        if (message.user && message.user.screenId && message.user.id) {
            this.lastGetInfoValue.user.screenId = message.user.screenId;
            this.lastGetInfoValue.user.id = message.user.id;
        }

        if (message.pharmacy && message.pharmacy.screenid && message.pharmacy.id) {
            this.lastGetInfoValue.user.screenId = message.pharmacy.screenid;
            this.lastGetInfoValue.user.id = message.pharmacy.id;
        }

        if (message.error) {
            this.lastGetInfoValue.error = message.error;
        }

        if (message.version) {
            this.lastGetInfoValue.version = message.version;
            this.lastGetInfoValue.versionInteger = parseInt(this.lastGetInfoValue.version.replace('.',''));
        }
        if (message.settings && typeof message.settings.scanner !== 'undefined') {
            this.lastGetInfoValue.settings.scanner = message.settings.scanner === '1' ? true : false;
        }
        if (message.settings && typeof message.settings.screen_standby !== 'undefined') {
            this.lastGetInfoValue.settings.screenStandby = message.settings.screen_standby === '1' ? true : false;
        }
        if (message.status) {
            this.lastGetInfoValue.status = message.status;
        }

        this.GetInfoProcess=false;

        Object.keys(this.onGetInfoMessageCallbacks).forEach( callBackId => {
            this.onGetInfoMessageCallbacks[callBackId](this.lastGetInfoValue);
        });
    }

    private handlePrintscreenMessage(message: ReceivedPrintscreenMessage){
        console.log('handlePrintscreenMessage', message);
        this.lastPrintScreenStatusValue = {
            endPoint : message.command.end_point,
            filename: message.command.filename,
            imageData: message.command.base64_image,
            statusCode: message.status_code
        };

        this.TakePrintscreenProcess = false;
    }

    private handleHatchPhotoMessage(message: ReceivedHatchPhotoMessage){
        this.lastHatchPhoto = message.photoData;
    }

    public setOnBarcodeMessageCallback(callback: (data: string) => void, id: string = 'default') {
        this.onBarcodeMessageCallbacks[id] = callback;
    }

    public unsetOnBarcodeMessageCallback(id: string = 'default') {
        if(typeof this.onBarcodeMessageCallbacks[id] !== 'undefined'){
            delete this.onBarcodeMessageCallbacks[id];
        }
    }

    private handleBarcodeMessage(message: ReceivedBarcodeMessage) {
        this.lastBarcodeValue = message.barcode;
        Object.keys(this.onBarcodeMessageCallbacks).forEach( callBackId => {
            this.onBarcodeMessageCallbacks[callBackId](this.lastBarcodeValue);
        });
    }

    public SendGetInfo(tvid?: number): Promise<boolean> {
        return new Promise( resolve => {
            if (!this.GetInfoProcess) {
                this.GetInfoProcess = true;
                this.send({
                    action: 'info',
                    tvid
                });
                resolve(true);
            }
            else {
                resolve(false);
            }
        });
    }

    public async SendGetInfoAndProcess(tvid?: number):Promise<boolean | DeviceInfo> {
        if (await this.SendGetInfo(tvid)){
            this.GetInfoProcessCanceled = false;

            const timeoutTimer = setTimeout( () => {
                if (this.GetInfoProcess){
                    this.GetInfoProcessCanceled = true;
                }
            },90000);
            const deviceInfo = await this.GetInfoProcessing();
            clearTimeout(timeoutTimer);

            return deviceInfo;
        }

        return false;
    }

    public GetInfoProcessing():Promise<boolean | DeviceInfo> {
        return new Promise(resolve => {
            if (this.GetInfoProcessCanceled) {
                resolve(false);
            }
            else if (this.GetInfoProcess) {
                setTimeout(()=>{
                    resolve(this.GetInfoProcessing())
                },500)
            }
            else {
                resolve(this.lastGetInfoValue);
            }
        });
    }

    public async SendTakePrintscreen(endPoint: string, filename: string) {
        if(!this.TakePrintscreenProcess){
            this.TakePrintscreenProcess=true;
            this.send({
                action: 'printscreen',
                command: {
                    end_point: endPoint,
                    filename: filename
                }
            });
            return true;
        }
        return false;
    }

    public async SendTakePrintscreenAndProcess(endPoint: string, filename: string): Promise<boolean | PrintscreenStatus> {
        this.lastPrintScreenStatusValue = undefined;
        if(await this.SendTakePrintscreen(endPoint,filename)){
            this.TakePrintscreenProcessCanceled = false;
            let timeoutTimer = setTimeout( () => {
                if(this.TakePrintscreenProcess){
                    this.TakePrintscreenProcessCanceled = true;
                }
            },15000);
            let takePrintscreenStatus = await this.TakePrintscreenProcessing();
            clearTimeout(timeoutTimer);
            return takePrintscreenStatus;
        }
        return false;
    }

    public TakePrintscreenProcessing(): Promise<boolean | PrintscreenStatus> {
        return new Promise( resolve => {
            if(this.TakePrintscreenProcessCanceled){
                resolve(false);
            }else if(this.TakePrintscreenProcess){
                setTimeout(()=>{
                    resolve(this.TakePrintscreenProcessing())
                },500)
            }else if (this.lastPrintScreenStatusValue){
                resolve(this.lastPrintScreenStatusValue);
            }
        });
    }

    public SetOnConnectionOpen(callback: () => void, id: string = 'default') {
        this.onConnectionOpenCallbacks[id] = callback;
    }

    public UnSetOnConnectionOpen(id: string = 'default') {
        if(typeof this.onConnectionOpenCallbacks[id] !== 'undefined') {
            delete this.onConnectionOpenCallbacks[id];
        }
    }

    public SetOnConnectionClose(callback: (event: CloseEvent) => void, id: string = 'default') {
        this.onConnectionCloseCallbacks[id] = callback;
    }

    public UnSetOnConnectionClose(id: string = 'default') {
        if(typeof this.onConnectionCloseCallbacks[id] !== 'undefined'){
            delete this.onConnectionCloseCallbacks[id];
        }
    }

    public async GetHatchPhoto():Promise<false | string> {
        this.lastHatchPhoto = '';
        this.send({
            action: 'hatchPhoto'
        });

        return new Promise(resolve => {
            const CHECK_DELAY = 100; // ms
            let totalTimeSpentChecking = 0;
            const checkForNewPhoto = () => {
                if (this.lastHatchPhoto) {
                    resolve(this.lastHatchPhoto);
                }
                else {
                    totalTimeSpentChecking += CHECK_DELAY;
                    if (totalTimeSpentChecking > 3000) {
                        resolve(false);
                    }
                    else {
                        setTimeout(checkForNewPhoto, CHECK_DELAY);
                    }
                }
            };

            checkForNewPhoto();
        });
    }

    public async EnsureSipConfigured(payload: any) {
        this.videoCallResponse = undefined;

        this.send({
            action: 'videoCall-EnsureSipConfigured',
            payload
        });

        return new Promise(resolve => {
            const CHECK_DELAY = 100; // ms
            let totalTimeSpentChecking = 0;
            const checkForResponse = () => {
                if (this.videoCallResponse) {
                    resolve({
                        Id: this.videoCallResponse.response['SIPAccountId']
                    });
                }
                else {
                    totalTimeSpentChecking += CHECK_DELAY;
                    if (totalTimeSpentChecking > 3000) {
                        resolve(false);
                    }
                    else {
                        setTimeout(checkForResponse, CHECK_DELAY);
                    }
                }
            };

            checkForResponse();
        });
    }

    public async Call(payload: any) {
        this.videoCallResponse = undefined;
        
        this.send({
            action: 'videoCall-Call',
            payload
        });

        return new Promise(resolve => {
            const CHECK_DELAY = 100; // ms
            let totalTimeSpentChecking = 0;
            const checkForResponse = () => {
                if (this.videoCallResponse) {
                    resolve(this.videoCallResponse.response);
                }
                else {
                    totalTimeSpentChecking += CHECK_DELAY;
                    if (totalTimeSpentChecking > 3000) {
                        resolve(false);
                    }
                    else {
                        setTimeout(checkForResponse, CHECK_DELAY);
                    }
                }
            };

            checkForResponse();
        });
    }

    public async GetCallStatus(callId?: string) {
        this.videoCallResponse = undefined;

        this.send({
            action: 'videoCall-GetCallStatus',
            payload: {
                CallId: callId
            }
        });

        return new Promise(resolve => {
            const CHECK_DELAY = 100; // ms
            let totalTimeSpentChecking = 0;
            const checkForResponse = () => {
                if (this.videoCallResponse) {
                    resolve(this.videoCallResponse.response['CallStatus']);
                }
                else {
                    totalTimeSpentChecking += CHECK_DELAY;
                    if (totalTimeSpentChecking > 3000) {
                        resolve(false);
                    }
                    else {
                        setTimeout(checkForResponse, CHECK_DELAY);
                    }
                }
            };

            checkForResponse();
        });
    }

    public async TerminateCall(callId: string) {
        return this.send({
            action: 'videoCall-TerminateCall',
            payload: {
                CallId: callId
            }
        });
    }

    public async SetLightIndicator(rgb: Array<number>) {
        return this.send({
            action: 'kiosk-color-light-indicator',
            command: {
                type: 'set_color',
                rgb_color: rgb
            }
        });
    }

    public async Print(url: string) {
        return this.send({
            action: 'MT_Printer',
            command: {
                type: 'print_pdf',
                pdf_link: url
            }
        });
    }

    // --- --- ---
    private readonly lastResponseMessages = new Map<string, any>();

    public handleLockerConfigurationResponseMessage(message: any) {
        this.lastResponseMessages.set('locker_configuration', message);
    }

    public handleThermoValuesResponseMessage(message: ThermoValuesResponseMessage) {
        this.lastResponseMessages.set('thermo_values', message);
        this.ThermoValuesReceived.trigger(this, message);
    }

    public handleLockerStatusResponseMessage(message: LockerStatusResponseMessage) {
        this.lastResponseMessages.set('locker_status', message);
        this.LockerStatusReceived.trigger(this, message);
    }

    public handleSetLightIndicatorResponseMessage(message: SetLightIndicatorResponseMessage) {
        this.lastResponseMessages.set('kiosk-color-light-indicator', message);
        this.SetLightIndicatorResponseReceived.trigger(this, message);
    }

    public handlePrintResponseMessage(message: PrintResponseMessage) {
        this.lastResponseMessages.set('MT_Printer', message);
        this.PrintResponseReceived.trigger(this, message);
    }

    public async GetResponseMessage(
        message: string,
        interval: number,
        timeout: number):
        Promise<any> {

        return new Promise(resolve => {
            if (this.lastResponseMessages.get(message)) {
                const responseMessage = this.lastResponseMessages.get(message)
                this.lastResponseMessages.set(message, undefined);
                resolve(responseMessage);

            } else {
                timeout = timeout - interval
                if (0 >= timeout) {
                    resolve(false);
                } else {
                    setTimeout(() => {
                        resolve(this.GetResponseMessage(message, interval, timeout));}, interval);
                }
            }
        });
    }
}
