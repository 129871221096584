import { Route } from 'data-store';

import backsideStockingRoutes from './BacksideStocking/routes';
import branchCartRules from './BranchCartRules/routes';
import landingPagesRoutes from './LandingPages/routes';
import nightHatchV2Routes from './NightHatchV2/routes';
import overviewRoutes from './Overview/routes';
import stockReplenishRoutes from './StockReplenish/routes';
import stockReplenishExportRoutes from './StockReplenishExport/routes';
import vendingMachineConfigurationRoutes from './VendingMachineConfiguration/routes';
import VendingMachineGoodieBagRoutes from './VendingMachineGoodieBag/routes';
import vendingMachineHomeScreenProductsRoutes from './VendingMachineHomeScreenProducts/routes';
import vendingMachineLayoutRoutes from './VendingMachineLayout/routes';
import vendingMachineNightHatchRoutes from './VendingMachineNightHatch/routes';
import vendingMachineOrderRoutes from './VendingMachineOrders/routes';
import vendingMachineOrderExportRoutes from './VendingMachineOrdersExport/routes';
import videoCallScheduleRoutes from './VideoCallSchedule/routes';
const routes: Route[] = [
    ...overviewRoutes,
    ...vendingMachineOrderRoutes,
    ...vendingMachineLayoutRoutes,
    ...vendingMachineConfigurationRoutes,
    ...vendingMachineHomeScreenProductsRoutes,
    ...videoCallScheduleRoutes,
    ...vendingMachineNightHatchRoutes,
    ...nightHatchV2Routes,
    ...landingPagesRoutes,
    ...vendingMachineOrderExportRoutes,
    ...backsideStockingRoutes,
    ...stockReplenishRoutes,
    ...stockReplenishExportRoutes,
    ...VendingMachineGoodieBagRoutes,
    ...branchCartRules,
];

export default routes;
