import merge from 'lodash.merge';

import LocalComm from './Infrastructure/LocalComm/LocalComm';
import Wwks2Comm from './Infrastructure/Wwks2Comm/Wwks2Comm';

import * as Wwks2Domain from './Domain/Wwks2';
import * as PlcDomain from './Domain/MeditechDevicePlc';
import * as localcommDomein from './Domain/LocalComm';
import {
    DeviceSettings,
    ShoppingCart,
    DeviceStatus,
    Initialization,
    DEVICESETTINGS,
    SHOPPINGCART,
    DEVICESTATUS,
    ReadyForDeliveryStatus
} from './Domain';


export default class MeditechDeviceClient {
    public localCommApi: LocalComm;
    public wwks2Pharma: Wwks2Comm;
    public wwks2StorageSystem: Wwks2Comm;

    public settings: DeviceSettings = localcommDomein.deepClone(DEVICESETTINGS);
    // private deliveredProductsInDeliveryBoxIntervalTimer: ReturnType<typeof setInterval>;
    // private deliveredProductsInDeliveryBoxTimeout: ReturnType<typeof setTimeout>;
    private waitUntilLocalCommIsConnectedTimeout: ReturnType<typeof setTimeout>;
    private waitUntilLocalCommIsConnectedCancel = false;
    private waitUntilPlcIsReadyTimeout: ReturnType<typeof setTimeout>;
    private waitUntilPlcIsReadyCancel = false;
    private waitUntilPlcStatusReceivedByLocalcommTimeout:  ReturnType<typeof setTimeout>;
    private waitUntilPlcStatusReceivedByLocalcommCancel = false;
    private autoSaveDiagnosesToLochtingTimeout: ReturnType<typeof setTimeout>;

    private shoppingCart: ShoppingCart = localcommDomein.deepClone(SHOPPINGCART);

    private maticDeviceStatus: DeviceStatus = localcommDomein.deepClone(DEVICESTATUS);
    public GetMaticDeviceStatus() {
        return this.maticDeviceStatus;
    }

    private onMaticDeviceDiagnoseTimeout: ReturnType<typeof setTimeout>;
    private checkMaticDeviceDiagnoseAfterPLCStatusIdleTimeout: ReturnType<typeof setTimeout>;
    private reInitializationTimeout: ReturnType<typeof setTimeout>;

    private initializationEventCallback: (initEvent: string, initializationData: Initialization) => void;

    public onDeviceDiagnose: (MaticDeviceStatus: DeviceStatus) => void;
    public onReadedBarcode: (barcode: string, timestamp: number) => void;
    public onStorageSystemProductStockChange: (data: Wwks2Domain.wwks2StockInfoMessage) => void;
    public onPharmacySoftwareProductStockChange: (data: Wwks2Domain.wwks2StockInfoMessage) => void;

    public onStorageSystemConnectionOpen: () => void;
    public onStorageSystemConnectionClose: () => void;

    public onPharmacySoftwareConnectionOpen: () => void;
    public onPharmacySoftwareConnectionClose: () => void;

    public onWwks2ConnectionOpen: () => void;
    public onWwks2ConnectionClose: () => void;
    public onWwks2ProductStockChange: (data: Wwks2Domain.wwks2StockInfoMessage) => void;

    public constructor(
        localCommApi: LocalComm
    ) {
        this.localCommApi = localCommApi;
        setInterval(() => {
            this.DeviceDiagnose();
        }, 10 * 1000);
    }

    //EntryPoint

    public async StartMatic(settings: DeviceSettings, initializationEventCallback: (initEvent: string, initializationData: Initialization) => void): Promise<{ status: boolean, msg: string, errorMsg: string[] }> {

        settings.deviceType = 'matic';

        this.UpdateSettings(settings);

        if (typeof this.localCommApi === 'undefined') {
            return { status: false, msg: 'failed', errorMsg: ['noLocalCommApiIsGiven'] };
        }
        if (typeof this.settings.deviceId === 'undefined') {
            return { status: false, msg: 'failed', errorMsg: ['noDeviceIdGiven'] };
        }

        this.initializationEventCallback = initializationEventCallback;

        return await this.Initialization();
    }

    public async StartVision(settings: DeviceSettings, initializationEventCallback: (initEvent: string, initializationData: Initialization) => void): Promise<{ status: boolean, msg: string, errorMsg: string[] }> {
        
        settings.deviceType = 'vision';

        this.UpdateSettings(settings);

        if (typeof this.localCommApi === 'undefined') {
            return { status: false, msg: 'failed', errorMsg: ['noLocalCommApiIsGiven'] };
        }
        if (typeof this.settings.deviceId === 'undefined') {
            return { status: false, msg: 'failed', errorMsg: ['noDeviceIdGiven'] };
        }

        this.initializationEventCallback = initializationEventCallback;

        return await this.InitalizationVision();
    }

    public UpdateSettings(settings: DeviceSettings) {
        this.settings = merge(localcommDomein.deepClone(this.settings), localcommDomein.deepClone(settings));
        this.checkSettings();
    }

    private checkSettings() {
        if(typeof this.settings.barcodeScannerAvailable !== 'undefined'){
            this.maticDeviceStatus.barcodeScanner.enabled = this.settings.barcodeScannerAvailable;
        }
        if (this.settings.delivery && this.settings.delivery.stockLocation && this.maticDeviceStatus.wwks2.device.stockLocation !== this.settings.delivery.stockLocation) {
            this.maticDeviceStatus.wwks2.device.stockLocation = this.settings.delivery.stockLocation;
        }
        if (this.settings.delivery && typeof this.settings.delivery.deliveryOutput !== 'undefined' && this.maticDeviceStatus.wwks2.device.deliveryOutput !== this.settings.delivery.deliveryOutput) {
            this.maticDeviceStatus.wwks2.device.deliveryOutput = this.settings.delivery.deliveryOutput;
        }
        if (this.settings.delivery && this.settings.delivery.autoFixAfterDeliveryFail) {
            this.maticDeviceStatus.delivery.autoFixAfterDeliveryFail = this.settings.delivery.autoFixAfterDeliveryFail;
        }
        if (this.settings.shoppingcart && this.settings.shoppingcart.currency) {
            this.maticDeviceStatus.shoppingCart.currency = this.settings.shoppingcart.currency;
        }
        if (this.settings.shoppingcart && this.settings.shoppingcart.limitOfProducts) {
            this.maticDeviceStatus.shoppingCart.limitOfProductsInCart = this.settings.shoppingcart.limitOfProducts;
        }
        if (typeof this.settings.reInitializeDeviceWhenPLCReleased !== 'undefined') {
            this.maticDeviceStatus.reInitializeDeviceWhenPLCReleased = this.settings.reInitializeDeviceWhenPLCReleased;
        }
        if (this.settings.storageSystem && typeof this.settings.storageSystem.useRobotStorageSystem !== 'undefined' && this.maticDeviceStatus.wwks2.storageSystem.isStorageSystemConnectionNeeded !== this.settings.storageSystem.useRobotStorageSystem) {
            this.maticDeviceStatus.wwks2.storageSystem.isStorageSystemConnectionNeeded = this.settings.storageSystem.useRobotStorageSystem;
        }
        if (this.settings.pharmacySoftware && typeof this.settings.pharmacySoftware.usePharmacySoftware !== 'undefined' && this.maticDeviceStatus.wwks2.pharmacySoftware.isPharmaSoftwareConnectionNeeded !== this.settings.pharmacySoftware.usePharmacySoftware) {
            this.maticDeviceStatus.wwks2.pharmacySoftware.isPharmaSoftwareConnectionNeeded = this.settings.pharmacySoftware.usePharmacySoftware;
        }
        if(this.settings.delivery && typeof this.settings.delivery.transportTimeBoxSensorAndDeliveryBox !== 'undefined'){
            this.maticDeviceStatus.delivery.transportTimeBoxSensorAndDeliveryBox = this.settings.delivery.transportTimeBoxSensorAndDeliveryBox;
        }
        if(this.settings.delivery && typeof this.settings.delivery.transportTimeRobotAndDevice !== 'undefined'){
            this.maticDeviceStatus.delivery.transportTimeRobotAndDevice = this.settings.delivery.transportTimeRobotAndDevice;
        }
        if(this.settings.delivery && typeof this.settings.delivery.deliveryCameraIp !== 'undefined'){
            this.maticDeviceStatus.delivery.deliveryCameraIp = this.settings.delivery.deliveryCameraIp;
        }
        if(this.settings.delivery && typeof this.settings.delivery.autoEmptyShoppingCartAfterDelivery !== 'undefined'){
            this.maticDeviceStatus.delivery.autoEmptyShoppingCartAfterDelivery = this.settings.delivery.autoEmptyShoppingCartAfterDelivery;
        }
        if(typeof this.settings.deviceType !== 'undefined'){
            this.maticDeviceStatus.deviceType = this.settings.deviceType;
        }
    }

    private Sleep(timems: number): Promise<boolean> {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(true);
            }, timems);
        });
    }

    //STATUS

    public async IsMaticReadyForDelivery(): Promise<ReadyForDeliveryStatus> {

        const xPlc = await this.IsMaticPlcReadyForJob();
        const xInitDevice = this.maticDeviceStatus.initialization.done;
        const xTransportChannelRobotAndDeviceIsClear = await this.IsTransportChannelBetweenStorageSystemAndDeviceBlocked();

        return {
            initDeviceDone: xInitDevice,
            plc: xPlc,
            wwks2: true,
            storageSystem: true,
            transportChannelBetweenStorageSystemAndDeviceIsBlocked: xTransportChannelRobotAndDeviceIsClear,
            internetConnection: this.maticDeviceStatus.internetConnection,
            status: (xInitDevice && xPlc && true && true && xTransportChannelRobotAndDeviceIsClear && this.maticDeviceStatus.internetConnection)!
        };
    }

    public async IsVisionReadyForDelivery(): Promise<ReadyForDeliveryStatus> {

        return {
            initDeviceDone: true,
            wwks2: true,
            storageSystem: true!,
            internetConnection: this.maticDeviceStatus.internetConnection,
            status: (true && true && true && this.maticDeviceStatus.internetConnection)!
        };
    }

    public async IsMaticPlcReadyForJob() {
        if (typeof this.maticDeviceStatus.plc.status !== 'boolean') {
            return this.maticDeviceStatus.plc.status.MTMATIC.status.mode === 'auto'
                && this.maticDeviceStatus.plc.status.MTMATIC.status.state === 'idle'
        }
        return false;
    }

    public async IsMaticPlcSoftwareReady() {
        /*if (typeof this.maticDeviceStatus.plc.status !== 'boolean') {
            return this.maticDeviceStatus.plc.status.MTMATIC.status.mode === 'auto';
        }*/
        return this.maticDeviceStatus.plc.released;
    }

    public async IsMaticWwks2Ready(): Promise<boolean> {
        if (this.settings.pharmacySoftware && this.settings.pharmacySoftware.usePharmacySoftware && this.maticDeviceStatus.wwks2.pharmacySoftware.connectionStatus) {
            return true;
        } else if (this.settings.pharmacySoftware && !this.settings.pharmacySoftware.usePharmacySoftware && this.maticDeviceStatus.wwks2.storageSystem.isStorageSystemConnectionNeeded && this.maticDeviceStatus.wwks2.storageSystem.connectionStatus) {
            return true;
        }

        return false;
    }

    public async IsMaticStorageSystemReady() {
        //if (this.maticDeviceStatus.wwks2.storageSystem.statusInfo && this.maticDeviceStatus.wwks2.storageSystem.statusInfo.storageSystem && this.maticDeviceStatus.wwks2.device.stockLocation && this.maticDeviceStatus.wwks2.storageSystem.statusInfo.storageSystem[this.maticDeviceStatus.wwks2.device.stockLocation]) {
        //    return this.maticDeviceStatus.wwks2.storageSystem.statusInfo.storageSystem[this.maticDeviceStatus.wwks2.device.stockLocation].state === 'ready';
        //}
        if(this.maticDeviceStatus.wwks2.storageSystem.isStorageSystemConnectionNeeded){
            return this.maticDeviceStatus.wwks2.storageSystem.connectionStatus && this.maticDeviceStatus.wwks2.storageSystem.statusInfo && this.maticDeviceStatus.wwks2.storageSystem.statusInfo.state === 'ready';
        }
        return false;
    }

    public async IsTransportChannelBetweenStorageSystemAndDeviceBlocked(){
        return !this.maticDeviceStatus.delivery.transportChannelBetweenStorageSystemAndDeviceIsBlocked;
    }

    public IsMaticDeviceInUse() {
        return this.maticDeviceStatus.maticDeviceIsInUse;
    }

    public SetMaticDeviceInUse(value: boolean) {
        if (value) {
            this.localCommApi.SendDeliveryBoxLEDOn();
            this.localCommApi.SendTransactionBusy();
        } else {
            this.localCommApi.SendTransactionNotBusy();
            this.localCommApi.SendDeliveryBoxLEDOff();
        }
        this.maticDeviceStatus.maticDeviceIsInUse = value;
    }

    //WWKS2

    public GetWwks2ProcessData(dialogId: string | number) {
        return this.wwks2StorageSystem.GetProcessStatus(dialogId)
    }

    public SetOnStorageSystemProductStockChange(callback: (data: Wwks2Domain.wwks2StockInfoMessage) => void){
        this.onStorageSystemProductStockChange = callback;
    }

    public SetOnStorageSystemConnectionOpen( callback: () => void){
        this.onStorageSystemConnectionOpen = callback;
    }

    public SetOnStorageSystemConnectionClose( callback: () => void){
        this.onStorageSystemConnectionClose = callback;
    }

    public SetOnPharmacySoftwareProductStockChange(callback: (data: Wwks2Domain.wwks2StockInfoMessage) => void){
        this.onPharmacySoftwareProductStockChange = callback;
    }

    public SetOnPharmacySoftwareConnectionOpen( callback: () => void){
        this.onPharmacySoftwareConnectionOpen = callback;
    }

    public SetOnPharmacySoftwareConnectionClose( callback: () => void){
        this.onPharmacySoftwareConnectionClose = callback;
    }

    public SetOnWwks2ConnectionOpen( callback:() => void ){
        this.onWwks2ConnectionOpen = callback;
    }

    public SetOnWwks2ConnectionClose( callback:() => void ){
        this.onWwks2ConnectionClose = callback;
    }

    public SetOnWwks2ProductStockChange(callback: (data: Wwks2Domain.wwks2StockInfoMessage) => void){
        this.onWwks2ProductStockChange = callback;
    }

    //misc hardware

    public async TakePictureOfDeliveryBox(throughLocalcom: boolean): Promise<false | string> {
        const deliveryCameraIp = this.settings.delivery?.deliveryCameraIp || '200.0.0.10';
        let url = 'http://' + deliveryCameraIp + ':5000' + '/delivery-snapshot';
        if (throughLocalcom) {
            url = "http://localhost/delivery-snapshot"
        }

        return await fetch(url)
            .then((fetchResponse) => fetchResponse.json())
            .then((data) => { return data['base64EncodedImage']; })
            .catch(() => { return false; })
    }

    public async CallWithPharmacist() {
        if (typeof this.settings.parlephoneIp !== 'undefined') {
            this.localCommApi.StartCalling(this.settings.parlephoneIp);
            return true;
        }
        return false;
    }

    //plc

    public async OpenDeliveryDoor() {

        let statusResult = false;
        let dataResult: PlcDomain.PlcJobStatus | boolean = false;
        const openDeliveryDoorResult = await this.localCommApi.SendStartOpenDeliveryDoor();
        if (typeof openDeliveryDoorResult !== 'boolean') {
            dataResult = localcommDomein.deepClone(openDeliveryDoorResult);
            if (openDeliveryDoorResult.status === 'done') {
                statusResult = true;
            }else{
                this.maticDeviceStatus.plc.failedEventlogs.push({
                    command: 'openDeliveryDoor',
                    timestamp: Date.now(),
                    details: dataResult
                });
                this.DeviceDiagnose();
            }
        }
        return { status: statusResult, data: dataResult };
    }

    public async CloseDeliveryDoor() {

        let statusResult = false;
        let dataResult: PlcDomain.PlcJobStatus | boolean = false;
        const closeDeliveryDoorResult = await this.localCommApi.SendStartCloseDeliveryDoor();
        if (typeof closeDeliveryDoorResult !== 'boolean') {
            dataResult = localcommDomein.deepClone(closeDeliveryDoorResult);
            if (closeDeliveryDoorResult.status === 'done') {
                statusResult = true;
            }else{
                this.maticDeviceStatus.plc.failedEventlogs.push({
                    command: 'closeDeliveryDoor',
                    timestamp: Date.now(),
                    details: dataResult
                });
                this.DeviceDiagnose();
            }
        }
        return { status: statusResult, data: dataResult };
    }

    public async StartReject() {
        let statusResult = false;
        let dataResult: PlcDomain.PlcJobStatus | boolean = false;
        const rejectResult = await this.localCommApi.SendStartRejectOfDeliveryBox();
        if (typeof rejectResult !== 'boolean') {
            dataResult = localcommDomein.deepClone(rejectResult);
            if (rejectResult.status === 'done') {
                statusResult = true;
                this.localCommApi.ResetBoxDetectCounter();
            }else{
                this.maticDeviceStatus.plc.failedEventlogs.push({
                    command: 'startReject',
                    timestamp: Date.now(),
                    details: dataResult
                });
                this.DeviceDiagnose();
            }
        }
        return { status: statusResult, data: dataResult };
    }

    public async StartCloseDeliveryDoorAndReject() {
        let statusResult = false;
        let dataResult: PlcDomain.PlcJobStatus | boolean = false;
        const rejectResult = await this.localCommApi.SendStartCloseDeliveryDoorAndRejectOfDeliveryBox();
        if (typeof rejectResult !== 'boolean') {
            dataResult = localcommDomein.deepClone(rejectResult);
            if (rejectResult.status === 'done') {
                statusResult = true;
            }else{
                this.maticDeviceStatus.plc.failedEventlogs.push({
                    command: 'closeDeliveryDoorAndReject',
                    timestamp: Date.now(),
                    details: dataResult
                });
                this.DeviceDiagnose();
            }
        }
        return { status: statusResult, data: dataResult };
    }

    public async OpenRearDoor() {
        let statusResult = false;
        let dataResult: PlcDomain.PlcJobStatus | boolean = false;
        const openRejectDoorResult = await this.localCommApi.SendStartOpenRejectDoor();
        if (typeof openRejectDoorResult !== 'boolean') {
            dataResult = localcommDomein.deepClone(openRejectDoorResult);
            if (openRejectDoorResult.status === 'done') {
                statusResult = true;
            }else{
                this.maticDeviceStatus.plc.failedEventlogs.push({
                    command: 'openRearDoor',
                    timestamp: Date.now(),
                    details: dataResult
                });
                this.DeviceDiagnose();
            }
        }
        return { status: statusResult, data: dataResult };
    }

    public async CloseRearDoor() {
        let statusResult = false;
        let dataResult: PlcDomain.PlcJobStatus | boolean = false;
        const closeRejectDoorResult = await this.localCommApi.SendStartCloseRejectDoor();
        if (typeof closeRejectDoorResult !== 'boolean') {
            dataResult = localcommDomein.deepClone(closeRejectDoorResult);
            if (closeRejectDoorResult.status === 'done') {
                statusResult = true;
            }else{
                this.maticDeviceStatus.plc.failedEventlogs.push({
                    command: 'closeRearDoor',
                    timestamp: Date.now(),
                    details: dataResult
                });
                this.DeviceDiagnose();
            }
        }
        return { status: statusResult, data: dataResult };
    }

    public async ForwardCVAndCloseRearDoor() {
        let statusResult = false;
        let dataResult: PlcDomain.PlcJobStatus | boolean = false;
        const forwardCVAndCloseRearDoorResult = await this.localCommApi.SendStartForwardCVAndCloseRearDoor();
        if (typeof forwardCVAndCloseRearDoorResult !== 'boolean') {
            dataResult = localcommDomein.deepClone(forwardCVAndCloseRearDoorResult);
            if (forwardCVAndCloseRearDoorResult.status === 'done') {
                statusResult = true;
            }else{
                this.maticDeviceStatus.plc.failedEventlogs.push({
                    command: 'forwardCVAndCloseRearDoor',
                    timestamp: Date.now(),
                    details: dataResult
                });
                this.DeviceDiagnose();
            }
        }
        return { status: statusResult, data: dataResult };
    }

    public SwitchLedDeliveryBoxOn(){
        this.localCommApi.SendDeliveryBoxLEDOn();
    }

    public SwitchLedDeliveryBoxOff(){
        this.localCommApi.SendDeliveryBoxLEDOff();
    }

    //barcode
    public SetOnBarcodeIsReaded(callback: (barcode: string, timestamp: number) => void) {
        this.onReadedBarcode = callback;
    }

    public EnableBarcodeScanner(){
        if(this.settings.barcodeScannerAvailable){
            this.localCommApi.SendBarcodeScannerEnable();
        }
    }

    public DisableBarcodeScanner(){
        if(this.settings.barcodeScannerAvailable){
            this.localCommApi.SendBarcodeScannerDisable();
        }
    }

    private TriggerBarcodeIsReaded() {
        if (typeof this.onReadedBarcode !== 'undefined') {
            this.onReadedBarcode(this.maticDeviceStatus.barcodeScanner.lastValue, this.maticDeviceStatus.barcodeScanner.lastValueTimestamp);
        }
    }

    //Start Software - Initialization

    public GetInitializationStatus(){
        return this.maticDeviceStatus.initialization;
    }

    // public async ConnectWithStorageSystem() {
    //     //robot storage system connection can be established without plc
    //     if (this.settings.delivery && this.settings.storageSystem && this.settings.storageSystem.useRobotStorageSystem) {
    //         this.maticDeviceStatus.initialization.isStorageSystemConnectionNeeded = true;
    //         if (this.settings.storageSystem.IP && this.settings.storageSystem.sourceId && this.settings.delivery.stockLocation && this.settings.delivery.deliveryOutput && this.settings.storageSystem.compatibilities) {
    //
    //             this.maticDeviceStatus.wwks2.storageSystem.IP = this.settings.storageSystem.IP;
    //             this.maticDeviceStatus.wwks2.device.storageSystemId = this.settings.storageSystem.sourceId;
    //             this.maticDeviceStatus.wwks2.device.storageSystemCompatibilities = this.settings.storageSystem.compatibilities;
    //
    //             this.maticDeviceStatus.wwks2.device.stockLocation = this.settings.delivery.stockLocation;
    //             this.maticDeviceStatus.wwks2.device.deliveryOutput = this.settings.delivery.deliveryOutput;
    //
    //             this.ChangeInitializationStep('startConnectionWithRobotStorageSystem');
    //             this.maticDeviceStatus.initialization.connectedWithStorageSystem =
    //                 ((await this.StartStorageSystemConnection(
    //                     this.settings.storageSystem.IP,
    //                     this.settings.storageSystem.sourceId,
    //                     this.settings.delivery.stockLocation,
    //                     this.settings.delivery.zone,
    //                     this.settings.delivery.deliveryOutput,
    //                     this.settings.storageSystem.compatibilities))
    //                     ?.status) ? true : false;
    //
    //             // if(connectionStatusOfRobotStorageSystem.connectionStatus === 'reconnecting'){
    //             //     this.maticDeviceStatus.initialization.retryToConnectWithStorageSystem = true;
    //             //     this.ChangeInitializationStep('retryToConnectWithRobotStorageSystem');
    //             // }else if(connectionStatusOfRobotStorageSystem.connectionStatus === 'connected'){
    //             //     this.maticDeviceStatus.initialization.connectedWithStorageSystem = true;
    //             //     this.ChangeInitializationStep('hasConnectionWithRobotStorageSystem');
    //             // }else if(connectionStatusOfRobotStorageSystem.connectionStatus === 'failed'){
    //             //     this.maticDeviceStatus.initialization.failedToConnectWithStorageSystem = true;
    //             //     this.ChangeInitializationStep('failedToConnectWithRobotStorageSystem');
    //             // }
    //             //this.maticDeviceStatus.initialization.startConnectionWithStorageSystem = await this.startStorageSystemConnection(this.settings.storageSystem.IP, this.settings.storageSystem.sourceId, this.settings.delivery.stockLocation, this.settings.delivery.deliveryOutput, this.settings.storageSystem.compatibilities);
    //
    //         } else {
    //             this.ChangeInitializationStep('noStartConnectionWithStorageSystem');
    //         }
    //     }
    // }
    
    // private async StartStorageSystemConnection(connectionIP: string, sourceId: number, stockLocation: Array<string>, zone: string | undefined, deliveryOutput: number, compatibilities: string[]) {
    //     if (this.maticDeviceStatus.wwks2.storageSystem.connectionStatus) {
    //         return;
    //     }
    //
    //     if (undefined != this.wwks2StorageSystem && null != this.wwks2StorageSystem) {
    //         this.wwks2StorageSystem.closeConnection();
    //     }
    //
    //     this.wwks2StorageSystem = new Wwks2Comm();
    //     this.wwks2StorageSystem.SetOnOpenConnectionCallback(() => {
    //         this.maticDeviceStatus.wwks2.storageSystem.connectionStatus = true;
    //         this.maticDeviceStatus.wwks2.storageSystem.connectedTimestamp = Date.now();
    //         this.maticDeviceStatus.wwks2.storageSystem.closedTimestamp = 0;
    //
    //         this.wwks2StorageSystem.StatusInfo(true).then(statusInfoResult => {
    //             if (statusInfoResult.status.status) {
    //                 if (typeof statusInfoResult.data !== 'boolean') {
    //                     this.maticDeviceStatus.wwks2.storageSystem.statusInfo = statusInfoResult.data as Wwks2Domain.wwks2StatusResponse;
    //                 }
    //             }
    //         });
    //
    //         if(typeof this.onStorageSystemConnectionOpen !== 'undefined'){
    //             this.onStorageSystemConnectionOpen();
    //         }
    //
    //         if(!(this.settings.pharmacySoftware && this.settings.pharmacySoftware.usePharmacySoftware)){
    //             if(typeof this.onStorageSystemConnectionOpen !== 'undefined'){
    //                 this.onWwks2ConnectionOpen();
    //             }
    //         }
    //
    //         this.DeviceDiagnose();
    //     });
    //
    //     this.wwks2StorageSystem.SetOnCloseConnectionCallback(() => {
    //         this.maticDeviceStatus.wwks2.storageSystem.connectionStatus = false;
    //
    //         this.maticDeviceStatus.wwks2.storageSystem.compatibilities = []
    //         this.maticDeviceStatus.wwks2.storageSystem.id = 0;
    //         this.maticDeviceStatus.wwks2.storageSystem.connectedTimestamp = 0
    //         this.maticDeviceStatus.wwks2.storageSystem.closedTimestamp = Date.now();
    //         this.maticDeviceStatus.wwks2.storageSystem.statusInfo = undefined;
    //
    //         if(typeof this.onStorageSystemConnectionClose !== 'undefined'){
    //             this.onStorageSystemConnectionClose();
    //         }
    //
    //         if(!(this.settings.pharmacySoftware && this.settings.pharmacySoftware.usePharmacySoftware)){
    //             if(typeof this.onStorageSystemConnectionOpen !== 'undefined'){
    //                 this.onWwks2ConnectionOpen();
    //             }
    //         }
    //
    //         this.DeviceDiagnose();
    //     });
    //
    //     this.wwks2StorageSystem.SetOnStatusChangeCallback((status) => {
    //         this.maticDeviceStatus.wwks2.storageSystem.statusInfo = status;
    //         this.DeviceDiagnose();
    //     });
    //
    //     this.wwks2StorageSystem.SetOnStockChangeCallback( (data: Wwks2Domain.wwks2StockInfoMessage) => {
    //         if(typeof this.onStorageSystemProductStockChange !== 'undefined'){
    //             this.onStorageSystemProductStockChange(data);
    //         }
    //         if(!(this.settings.pharmacySoftware && this.settings.pharmacySoftware.usePharmacySoftware)){
    //             if(typeof this.onWwks2ProductStockChange !== 'undefined'){
    //                 this.onWwks2ProductStockChange(data);
    //             }
    //         }
    //     });
    //
    //     const storageSystemConnection = await this.wwks2StorageSystem.Listen(_error => {
    //         // tslint:disable-next-line:no-console
    //         //console.log(error);
    //     });
    //
    //     return storageSystemConnection;
    // }

    private waitUntilLocalcommIsConnected(): Promise<boolean> {
        return new Promise(resolve => {

            if (this.waitUntilLocalCommIsConnectedCancel) {
                resolve(false);
            } else if (this.localCommApi.IsLocalCommConnected() === false) {
                setTimeout(() => {
                    resolve(this.waitUntilLocalcommIsConnected())
                }, 500);
            } else {
                resolve(true);
            }
        });
    }

    private startTimeoutForWaitUntilLocalcommIsConnected() {
        clearTimeout(this.waitUntilLocalCommIsConnectedTimeout);
        this.waitUntilLocalCommIsConnectedCancel = false;
        this.waitUntilLocalCommIsConnectedTimeout = setTimeout(() => {
            this.waitUntilLocalCommIsConnectedCancel = true;
        }, 30000);
    }

    private waitUntilPlcIsReady(): Promise<boolean> {
        return new Promise(resolve => {
            if (this.settings.disablePLC) {
                resolve(false);
            }
            else if (this.waitUntilPlcIsReadyCancel) {
                resolve(false);
            } else {
                this.IsMaticPlcReadyForJob().then(isPlcReady => {
                    if (isPlcReady) {
                        resolve(true);
                    } else {
                        setTimeout(() => {
                            resolve(this.waitUntilPlcIsReady())
                        }, 500);
                    }
                });
            }
        });
    }

    private startTimeoutForWaitUntilPlcIsReady() {
        clearTimeout(this.waitUntilPlcIsReadyTimeout);
        this.waitUntilPlcIsReadyCancel = false;
        this.waitUntilPlcIsReadyTimeout = setTimeout(() => {
            this.waitUntilPlcIsReadyCancel = true;
        }, 30000);
    }

    private waitUntilPlcStatusReceivedByLocalcomm(): Promise<boolean> {
        return new Promise(resolve => {
            if (this.waitUntilPlcStatusReceivedByLocalcommCancel) {
                resolve(false);
            } else {

                if(
                    typeof this.maticDeviceStatus.plc.status !== 'boolean' 
                    && this.maticDeviceStatus.plc.status.MTMATIC.status.mode
                    && this.maticDeviceStatus.plc.status.MTMATIC.status.state
                    && !this.maticDeviceStatus.plc.status.status.emergency.hmiEStopBtn1
                    && this.maticDeviceStatus.plc.status.status.emergency.hmiPilzStatus
                    && this.maticDeviceStatus.plc.status.status.emergency.stopCircuitClosed
                ){
                    resolve(true);
                }else{
                    setTimeout(() => {
                        resolve(this.waitUntilPlcStatusReceivedByLocalcomm());
                    }, 500);
                }
            }
        });
    }

    private startTimeoutForWaitUntilPlcStatusReceivedByLocalcomm() {
        clearTimeout(this.waitUntilPlcStatusReceivedByLocalcommTimeout);
        this.waitUntilPlcStatusReceivedByLocalcommCancel = false;
        this.waitUntilPlcStatusReceivedByLocalcommTimeout = setTimeout(() => {
            this.waitUntilPlcStatusReceivedByLocalcommCancel = true;
        }, 15000);
    }

    private TriggerInitializationStatusChange() {
        if (typeof this.initializationEventCallback !== 'undefined') {
            this.initializationEventCallback(this.maticDeviceStatus.initialization.currentStep, localcommDomein.deepClone(this.maticDeviceStatus.initialization));
        }
    }

    private ChangeInitializationStep(step: string) {
        this.maticDeviceStatus.initialization.currentStep = step;
        this.maticDeviceStatus.initialization.steps.push({
            step: step,
            timestamp: Date.now()
        });
        this.TriggerInitializationStatusChange();

    }

    public async Initialization() {
        if (!this.maticDeviceStatus.initialization.done && !this.maticDeviceStatus.initialization.busy) {

            this.ChangeInitializationStep('init');
            this.maticDeviceStatus.initialization.busy = true;
            this.maticDeviceStatus.initialization.starttime = Date.now();

            if (typeof this.settings.deviceId !== 'undefined') {
                this.maticDeviceStatus.deviceId = this.settings.deviceId;
                this.maticDeviceStatus.initialization.deviceIdIsGiven = true;
            }

            this.ChangeInitializationStep('waitUntilLocalCommIsReady');
            this.maticDeviceStatus.initialization.localcommConnected = await this.initLocalComm();
            //check Terminal status - TODO ???

            if (this.maticDeviceStatus.initialization.deviceIdIsGiven && this.maticDeviceStatus.initialization.localcommConnected) {
                
                //only proceed if localcomm connection is established
                
                this.ChangeInitializationStep('checkIfPLCModeStatusIsReceivedByLocalcomm');
                this.startTimeoutForWaitUntilPlcStatusReceivedByLocalcomm();
                const plcStatusReceivedByLocalcommFlag = await this.waitUntilPlcStatusReceivedByLocalcomm();
                if(typeof this.maticDeviceStatus.plc.status !== 'boolean'){
                    if(this.maticDeviceStatus.plc.status.MTMATIC.status.mode){
                        this.maticDeviceStatus.initialization.plcModeReceivedByLocalcomm = true;
                    }
                    if(this.maticDeviceStatus.plc.status.MTMATIC.status.state){
                        this.maticDeviceStatus.initialization.plcStateReceivedByLocalcomm = true;
                    }
                    if(!this.maticDeviceStatus.plc.status.status.emergency.hmiEStopBtn1){
                        this.maticDeviceStatus.initialization.plcEmergencyStopBtnReceivedByLocalcomm = true;
                    }
                    if(this.maticDeviceStatus.plc.status.status.emergency.hmiPilzStatus){
                        this.maticDeviceStatus.initialization.plcEmergencyPilzBtnReceivedByLocalcomm = true;
                    }
                    if(this.maticDeviceStatus.plc.status.status.emergency.stopCircuitClosed){
                        this.maticDeviceStatus.initialization.plcEmergencyStopCircuitReceivedByLocalcomm = true;
                    }
                }
                if(plcStatusReceivedByLocalcommFlag){
                    this.ChangeInitializationStep('waitUntilPlcIsReady');
                    this.startTimeoutForWaitUntilPlcIsReady();
                    this.maticDeviceStatus.initialization.plcIsReady = await this.waitUntilPlcIsReady();
                    //ifTimeout > restart software
                    if (this.maticDeviceStatus.initialization.plcIsReady) {

                        this.ChangeInitializationStep('ejectTicketIfPresent');

                        this.maticDeviceStatus.initialization.rejectProcessIsBusy = true;
                        this.ChangeInitializationStep('rejectIsBusy');
                        // let rejectProcess = await this.StartCloseDeliveryDoorAndReject();
                        this.maticDeviceStatus.initialization.rejectProcessIsBusy = false;
                        this.maticDeviceStatus.initialization.rejectProcessIsDone = true
                        // if (rejectProcess.status) {
                        //     this.maticDeviceStatus.initialization.rejectProcessIsDone = true;
                        // } else {
                        //     this.maticDeviceStatus.initialization.rejectProcessIsFailed = true;
                        // }

                        this.ChangeInitializationStep('setHardwareToIdle');
                        this.localCommApi.SendTransactionNotBusy();
                        this.localCommApi.SendDeliveryBoxLEDOff();
                        this.localCommApi.ResetBoxDetectCounter();
                        this.localCommApi.ReadMachineType();
                        this.maticDeviceStatus.maticDeviceIsInUse = false;
                        
                    } else {
                        this.ChangeInitializationStep('timeoutWaitUntilPlcIsReady');
                        this.maticDeviceStatus.initialization.timeoutOnPlcReady = true;
                    }

                }else{
                    this.ChangeInitializationStep('notAllPLCStatusReceivedByLocalcomm');
                }

            } else {
                if (!this.maticDeviceStatus.initialization.localcommConnected) {
                    this.ChangeInitializationStep('timeoutWaitUntilLocalCommIsReady');
                    this.maticDeviceStatus.initialization.timeoutOnLocalCommReady = true;
                }
                if (!this.maticDeviceStatus.initialization.deviceIdIsGiven) {
                    this.ChangeInitializationStep('noDeviceId');
                }
            }

            this.StopAutoSaveToDiagnosesToLochting();
            if(this.settings.diagnoses && typeof this.settings.diagnoses.autoSaveToLochting !== 'undefined' && typeof this.settings.diagnoses.autoSaveToLochtingInterval !== 'undefined'){
                this.maticDeviceStatus.diagnoses.autoSaveDiagnosesToLochting = this.settings.diagnoses.autoSaveToLochting;
                this.maticDeviceStatus.diagnoses.autoSaveDiagnosesToLochtingInterval = this.settings.diagnoses.autoSaveToLochtingInterval;
                if(this.maticDeviceStatus.diagnoses.autoSaveDiagnosesToLochting){
                    this.StartAutoSaveToDiagnosesToLochting();
                }
            }

            this.maticDeviceStatus.initialization.stoptime = Date.now();
            this.maticDeviceStatus.initialization.busy = false;
            await this.DeviceDiagnose();

            if (
                this.maticDeviceStatus.initialization.deviceIdIsGiven
                && this.maticDeviceStatus.initialization.localcommConnected
                && this.maticDeviceStatus.initialization.plcModeReceivedByLocalcomm
                && this.maticDeviceStatus.initialization.plcStateReceivedByLocalcomm
                && this.maticDeviceStatus.initialization.plcEmergencyStopBtnReceivedByLocalcomm
                && this.maticDeviceStatus.initialization.plcEmergencyPilzBtnReceivedByLocalcomm
                && this.maticDeviceStatus.initialization.plcEmergencyStopCircuitReceivedByLocalcomm
                && this.maticDeviceStatus.initialization.plcIsReady
                && this.maticDeviceStatus.initialization.rejectProcessIsDone
                && ((this.maticDeviceStatus.initialization.isStorageSystemConnectionNeeded && (this.maticDeviceStatus.initialization.connectedWithStorageSystem || this.maticDeviceStatus.initialization.retryToConnectWithStorageSystem)) || !this.maticDeviceStatus.initialization.isStorageSystemConnectionNeeded)
            ) {
                this.ChangeInitializationStep('done');
                this.maticDeviceStatus.initialization.done = true;
                this.localCommApi.ResetPlcMessages();
                return { status: true, msg: 'done', errorMsg: [] }
            } else {
                this.maticDeviceStatus.initialization.error = true;
                const errors: string[] = [];

                if(!this.maticDeviceStatus.initialization.deviceIdIsGiven){
                    errors.push('DeviceIdNotGiven');
                }
                if(!this.maticDeviceStatus.initialization.localcommConnected){
                    errors.push('LocalcommNotConnected');
                }
                if(!this.maticDeviceStatus.initialization.plcIsReady){
                    errors.push('PLCNotReady');
                }
                if(!this.maticDeviceStatus.initialization.rejectProcessIsDone){
                    errors.push('RejectFailed');
                }
                if((this.maticDeviceStatus.initialization.isStorageSystemConnectionNeeded && this.maticDeviceStatus.initialization.failedToConnectWithStorageSystem) || !this.maticDeviceStatus.initialization.isStorageSystemConnectionNeeded){
                    errors.push('NoConnectionStartedWitRobotStorageSystem');
                }
                if((this.maticDeviceStatus.initialization.isPharmaSoftwareConnectionNeeded && this.maticDeviceStatus.initialization.startConnectionWithPharmacySoftware) || !this.maticDeviceStatus.initialization.isPharmaSoftwareConnectionNeeded){
                    errors.push('NoConnectionStartedWithPharmacySoftware');
                }
                if(!this.maticDeviceStatus.initialization.plcModeReceivedByLocalcomm){
                    errors.push('plcModeNotReceivedByLocalcomm');
                }
                if(!this.maticDeviceStatus.initialization.plcStateReceivedByLocalcomm){
                    errors.push('plcStateNotReceivedByLocalcomm');
                }
                if(!this.maticDeviceStatus.initialization.plcEmergencyStopBtnReceivedByLocalcomm){
                    errors.push('plcEmergenyStopBtnNotReceivedByLocalcomm');
                }
                if(!this.maticDeviceStatus.initialization.plcEmergencyPilzBtnReceivedByLocalcomm){
                    errors.push('plcEmergenyPilzBtnNotReceivedByLocalcomm');
                }
                if(!this.maticDeviceStatus.initialization.plcEmergencyStopCircuitReceivedByLocalcomm){
                    errors.push('plcEmergenyStopCircuitBtnNotReceivedByLocalcomm');
                }

                this.ChangeInitializationStep('failed');

                this.localCommApi.ResetPlcMessages();

                return { status: false, msg: 'failed', errorMsg: errors }
            }

        } else {
            await this.DeviceDiagnose();
            return { status: this.maticDeviceStatus.initialization.done, msg: this.maticDeviceStatus.initialization.done ? 'done' : 'busy', errorMsg: [] }
        }

    }

    private async InitalizationVision() {
        if (!this.maticDeviceStatus.initialization.done && !this.maticDeviceStatus.initialization.busy) {

            this.ChangeInitializationStep('init');
            this.maticDeviceStatus.initialization.busy = true;
            this.maticDeviceStatus.initialization.starttime = Date.now();

            if (typeof this.settings.deviceId !== 'undefined') {
                this.maticDeviceStatus.deviceId = this.settings.deviceId;
                this.maticDeviceStatus.initialization.deviceIdIsGiven = true;
            }

            this.ChangeInitializationStep('waitUntilLocalCommIsReady');
            this.maticDeviceStatus.initialization.localcommConnected = await this.initLocalCommVision();
            //check Terminal status - TODO ???

            if (this.maticDeviceStatus.initialization.deviceIdIsGiven && this.maticDeviceStatus.initialization.localcommConnected) {

                this.ChangeInitializationStep('localCommIsReady');

                //enable the barcode scanner if available
                // if(this.settings.barcodeScannerAvailable){
                //     this.EnableBarcodeScanner();
                // }

            } else {
                if (!this.maticDeviceStatus.initialization.localcommConnected) {
                    this.ChangeInitializationStep('timeoutWaitUntilLocalCommIsReady');
                    this.maticDeviceStatus.initialization.timeoutOnLocalCommReady = true;
                }
                if (!this.maticDeviceStatus.initialization.deviceIdIsGiven) {
                    this.ChangeInitializationStep('noDeviceId');
                }
            }

            this.maticDeviceStatus.initialization.stoptime = Date.now();
            this.maticDeviceStatus.initialization.busy = false;
            this.ChangeInitializationStep('done');
            //await this.DeviceDiagnose();

            console.log(this.settings);
            console.log(this.maticDeviceStatus.initialization);

            if (
                this.maticDeviceStatus.initialization.deviceIdIsGiven
                && this.maticDeviceStatus.initialization.localcommConnected
                && ((this.maticDeviceStatus.initialization.isStorageSystemConnectionNeeded && (this.maticDeviceStatus.initialization.connectedWithStorageSystem || this.maticDeviceStatus.initialization.retryToConnectWithStorageSystem)) || !this.maticDeviceStatus.initialization.isStorageSystemConnectionNeeded)
                && ((this.maticDeviceStatus.initialization.isPharmaSoftwareConnectionNeeded && (this.maticDeviceStatus.initialization.connectedWithPharmacySoftware || this.maticDeviceStatus.initialization.retryToConnectWithPharmacySoftware)) || !this.maticDeviceStatus.initialization.isPharmaSoftwareConnectionNeeded)
            ) {
                this.maticDeviceStatus.initialization.done = true;
                return { status: true, msg: 'done', errorMsg: [] }
            } else {
                this.maticDeviceStatus.initialization.error = true;
                const errors: string[] = [];

                if(!this.maticDeviceStatus.initialization.deviceIdIsGiven){
                    errors.push('DeviceIdNotGiven');
                }
                if(!this.maticDeviceStatus.initialization.localcommConnected){
                    errors.push('LocalcommNotConnected');
                }
                //if(!this.maticDeviceStatus.initialization.startConnectionWithStorageSystem){
                if((this.maticDeviceStatus.initialization.isStorageSystemConnectionNeeded && this.maticDeviceStatus.initialization.failedToConnectWithStorageSystem)){
                    errors.push('NoConnectionStartedWitRobotStorageSystem');
                }
                if((this.maticDeviceStatus.initialization.isPharmaSoftwareConnectionNeeded && this.maticDeviceStatus.initialization.startConnectionWithPharmacySoftware)){
                    errors.push('NoConnectionStartedWithPharmacySoftware');
                }

                return { status: false, msg: 'failed', errorMsg: errors }
            }

        } else {
            //await this.DeviceDiagnose();
            return { status: this.maticDeviceStatus.initialization.done, msg: this.maticDeviceStatus.initialization.done ? 'done' : 'busy', errorMsg: [] }
        }
    }

    private async resetInitialization() {

        if (this.maticDeviceStatus.wwks2.storageSystem.isStorageSystemConnectionNeeded && this.maticDeviceStatus.wwks2.storageSystem.connectionStatus) {
            //close connection
        }
        if (this.maticDeviceStatus.wwks2.pharmacySoftware.isPharmaSoftwareConnectionNeeded && this.maticDeviceStatus.wwks2.pharmacySoftware.connectionStatus) {
            //close connection
        }

        //this.maticDeviceStatus.initialization = localcommDomein.deepClone(MATICINITIALIZATION);
        this.maticDeviceStatus = localcommDomein.deepClone(DEVICESTATUS);

        this.checkSettings();

        //check if mtvision or matic
        //if(this.maticDeviceStatus.)
        if(this.settings.deviceType && this.settings.deviceType === 'matic'){
            return this.Initialization();
        }else if(this.settings.deviceType && this.settings.deviceType === 'vision'){
            return this.InitalizationVision();
        }
        return false;
    }

    public async initLocalComm() {
        //await this.Sleep(5000); //when localcomm connection is just established > localcomm send some message to us and sometimes it takes some seconds for receiving this.
        this.startTimeoutForWaitUntilLocalcommIsConnected();
        this.maticDeviceStatus.initialization.localcommConnected = await this.waitUntilLocalcommIsConnected();

        //timeout > restart software
        const deviceInfoResult = await this.localCommApi.SendGetInfoAndReceive();
        if (typeof deviceInfoResult !== 'boolean') {
            this.maticDeviceStatus.device = deviceInfoResult;
        }

        this.localCommApi.SetOnBarcodeMessageCallback((barcode => {
            this.maticDeviceStatus.barcodeScanner.lastValue = barcode;
            this.maticDeviceStatus.barcodeScanner.lastValueTimestamp = Date.now();
            this.DeviceDiagnose();
            this.TriggerBarcodeIsReaded();
        }), 'maticDeviceBarcode');


        this.localCommApi.SetOnPLCMessageCallback((_plcHandle, plcStatus) => {
            //console.log(plcHandle);
            this.maticDeviceStatus.plc.status = plcStatus;
            this.checkPlcStatus();
            //check device status
        }, 'maticDevicePlcMessage');

        this.localCommApi.SetOnPLCEmergencyCallback((emergencyValue, plcStatus) => {
            this.maticDeviceStatus.plc.emergencyActivated = emergencyValue;
            this.maticDeviceStatus.plc.status = plcStatus;
            this.checkPlcStatus();
            //check device status
        }, 'maticDeviceEmergency');

        this.localCommApi.SetOnPLCHasBeenReleasedCallback((_mode, plcStatus) => {
            //console.log(mode);
            this.maticDeviceStatus.plc.status = plcStatus;
            this.checkPlcStatus();

            if (this.maticDeviceStatus.reInitializeDeviceWhenPLCReleased && !this.maticDeviceStatus.maticDeviceIsInUse && (this.maticDeviceStatus.initialization.done || this.maticDeviceStatus.initialization.error)) {
                if (this.reInitializationTimeout) {
                    clearTimeout(this.reInitializationTimeout);
                }
                this.reInitializationTimeout = setTimeout(() => {
                    this.resetInitialization();
                }, 2000);
            }
        }, 'maticDeviceReleased');

        /*this.localCommApi.SetOnConnectionCloseCallback( () => {

            this.DeviceDiagnose();

        });*/

        this.maticDeviceStatus.plc.status = await this.localCommApi.GetPlcStatus();
        this.checkPlcStatus();

        return this.maticDeviceStatus.initialization.localcommConnected;
    }

    private async initLocalCommVision() {
        await this.Sleep(5000); //when localcomm connection is just established > localcomm send some message to us and sometimes it takes some seconds for receiving this.
        this.startTimeoutForWaitUntilLocalcommIsConnected();
        this.maticDeviceStatus.initialization.localcommConnected = await this.waitUntilLocalcommIsConnected();

        //timeout > restart software
        const deviceInfoResult = await this.localCommApi.SendGetInfoAndReceive();
        if (typeof deviceInfoResult !== 'boolean') {
            this.maticDeviceStatus.device = deviceInfoResult;
        }

        if(this.settings.barcodeScannerAvailable){
            this.localCommApi.SetOnBarcodeMessageCallback((barcode => {
                console.log('debug barcode readed callback', barcode)
                this.maticDeviceStatus.barcodeScanner.lastValue = barcode;
                this.maticDeviceStatus.barcodeScanner.lastValueTimestamp = Date.now();
                this.DeviceDiagnose();
                this.TriggerBarcodeIsReaded();
            }), 'visionDeviceBarcode');
        }

        return this.maticDeviceStatus.initialization.localcommConnected;
    }

    //diagnoses

    public SetOnDeviceDiagnoseCallback(callback: (maticDeviceStatus: DeviceStatus) => void) {
        this.onDeviceDiagnose = callback;
    }
    
    private async DeviceDiagnose(): Promise<DeviceStatus> {

        if (this.onMaticDeviceDiagnoseTimeout) {
            clearTimeout(this.onMaticDeviceDiagnoseTimeout)
        }

        //errorcode = XXYY; XX = error type; YY = sub error type

        this.maticDeviceStatus.errorCodes = [];

        //Initialization of device = XX = 10

        if (this.maticDeviceStatus.initialization.error) {
            this.maticDeviceStatus.errorCodes.push(1001);
        }
        if (!this.maticDeviceStatus.initialization.localcommConnected) {
            this.maticDeviceStatus.errorCodes.push(1002);
        }
        if (this.maticDeviceStatus.deviceType === 'matic' && this.maticDeviceStatus.initialization.rejectProcessIsFailed) {
            this.maticDeviceStatus.errorCodes.push(1003);
        }
        if (this.maticDeviceStatus.initialization.isPharmaSoftwareConnectionNeeded && !this.maticDeviceStatus.initialization.startConnectionWithPharmacySoftware) {
            this.maticDeviceStatus.errorCodes.push(1004);
        }
        if (this.maticDeviceStatus.initialization.isStorageSystemConnectionNeeded && !this.maticDeviceStatus.initialization.startConnectionWithStorageSystem && this.maticDeviceStatus.initialization.isPharmaSoftwareConnectionNeeded) {
            this.maticDeviceStatus.errorCodes.push(1005);
        }
        if (this.maticDeviceStatus.deviceType === 'matic' && !this.maticDeviceStatus.initialization.plcModeReceivedByLocalcomm){
            this.maticDeviceStatus.errorCodes.push(1006);
        }
        if (this.maticDeviceStatus.deviceType === 'matic' && !this.maticDeviceStatus.initialization.plcStateReceivedByLocalcomm){
            this.maticDeviceStatus.errorCodes.push(1007);
        }
        if (this.maticDeviceStatus.deviceType === 'matic' && !this.maticDeviceStatus.initialization.plcEmergencyStopBtnReceivedByLocalcomm){
            this.maticDeviceStatus.errorCodes.push(1008);
        }
        if (this.maticDeviceStatus.deviceType === 'matic' && !this.maticDeviceStatus.initialization.plcEmergencyPilzBtnReceivedByLocalcomm){
            this.maticDeviceStatus.errorCodes.push(1009);
        }
        if (this.maticDeviceStatus.deviceType === 'matic' && !this.maticDeviceStatus.initialization.plcEmergencyStopCircuitReceivedByLocalcomm){
            this.maticDeviceStatus.errorCodes.push(1010);
        }

        //plc status
        //XXX = 200
        if(this.maticDeviceStatus.deviceType === 'matic' && !this.maticDeviceStatus.plc.released){
            if(this.maticDeviceStatus.plc.releaseStatus === 'notReadyForRelease'){
                this.maticDeviceStatus.errorCodes.push(2001); //PLC NOT READY
            }else if(this.maticDeviceStatus.plc.releaseStatus === 'readyToRelease'){
                this.maticDeviceStatus.errorCodes.push(2002); //PLC NOT READY
            }
        }
        if(this.maticDeviceStatus.deviceType === 'matic' && this.maticDeviceStatus.plc.emergencyActivated){
            if(this.maticDeviceStatus.plc.emergencyStatus === 'activated'){
                this.maticDeviceStatus.errorCodes.push(2003); //PLC NOT READY; emergency break is activated
            }else if(this.maticDeviceStatus.plc.emergencyStatus === 'readyToRelease'){
                this.maticDeviceStatus.errorCodes.push(2004); //PLC Release button must be pressed for releasing
            }
        }

        //XX = 20 PLC; YY > 50 = PLC messages;
        if(this.maticDeviceStatus.deviceType === 'matic' && typeof this.maticDeviceStatus.plc.status !== 'boolean'){
            if(this.maticDeviceStatus.plc.status.errorPLCMessageStatus.emergencyCircuitInterrupted){
                this.maticDeviceStatus.errorCodes.push(2050); //fatal error > manual intervention needed
            }
            if(this.maticDeviceStatus.plc.status.errorPLCMessageStatus.unlockEmergencyButton){
                this.maticDeviceStatus.errorCodes.push(2051); //fatal error > manual intervention needed
            }
        }

        //Check The failedPlcActionLogs
        if(this.maticDeviceStatus.deviceType === 'matic'){
            this.maticDeviceStatus.plc.failedEventlogs.forEach( _eventLog => {
                //logEvent.command
            });
            //remove the failedPlcActionLogs
            this.maticDeviceStatus.plc.failedEventlogIds.forEach( eventLogId => {
                this.localCommApi.deletePlcProcess(eventLogId);
            });
        }
        

        //robot status
        // X = 300
        if(this.maticDeviceStatus.wwks2.storageSystem.isStorageSystemConnectionNeeded){
            if(!this.maticDeviceStatus.wwks2.storageSystem.connectionStatus){
                this.maticDeviceStatus.errorCodes.push(3001); //Connection with StorageSystem is broken
            }
            if(!this.IsMaticStorageSystemReady()){
                this.maticDeviceStatus.errorCodes.push(3002); //StorageSystem is not ready for delivery
            }
        }
        

        //ps status
        //X = 400
        if(this.maticDeviceStatus.wwks2.pharmacySoftware.isPharmaSoftwareConnectionNeeded){
            if(!this.maticDeviceStatus.wwks2.pharmacySoftware.connectionStatus){
                this.maticDeviceStatus.errorCodes.push(4001); //Connection with StorageSystem is broken
            }
        }

        //delivery status
        //X = 500
        //products stuck in de transportchannel
        if(this.maticDeviceStatus.deviceType === 'matic' && this.maticDeviceStatus.delivery.transportChannelBetweenStorageSystemAndDeviceIsBlocked){
            this.maticDeviceStatus.errorCodes.push(5003); //propably a product is stuck in the transportchannel
        }

        //Internetconnection
        //X = 600
        if(!this.maticDeviceStatus.internetConnection){
            this.maticDeviceStatus.errorCodes.push(6001); //propably a product is stuck in the transportchannel
        }

        if(this.maticDeviceStatus.deviceType === 'matic' && (this.maticDeviceStatus.initialization.error)){
            //only when initialization failed
            if(typeof this.maticDeviceStatus.plc.status !== 'boolean'){
                if(
                    this.localCommApi.IsLocalCommConnected()
                    && !this.maticDeviceStatus.maticDeviceIsInUse
                    && 
                    (   
                        (!this.maticDeviceStatus.initialization.plcModeReceivedByLocalcomm && this.maticDeviceStatus.plc.status.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC.status.mode)
                        || (!this.maticDeviceStatus.initialization.plcStateReceivedByLocalcomm && this.maticDeviceStatus.plc.status.statusEventsReceivedSinceLocalcommConnectionWasEstablished.MTMATIC.status.state)
                        || (!this.maticDeviceStatus.initialization.plcEmergencyPilzBtnReceivedByLocalcomm && this.maticDeviceStatus.plc.status.statusEventsReceivedSinceLocalcommConnectionWasEstablished.emergency.hmiPilzStatus)
                        || (!this.maticDeviceStatus.initialization.plcEmergencyStopBtnReceivedByLocalcomm && this.maticDeviceStatus.plc.status.statusEventsReceivedSinceLocalcommConnectionWasEstablished.emergency.hmiEStopBtn1)
                        || (!this.maticDeviceStatus.initialization.plcEmergencyStopCircuitReceivedByLocalcomm && this.maticDeviceStatus.plc.status.statusEventsReceivedSinceLocalcommConnectionWasEstablished.emergency.stopCircuitClosed)
                        || (this.maticDeviceStatus.initialization.rejectProcessIsFailed && this.maticDeviceStatus.plc.status.MTMATIC.status.mode === 'auto' && this.maticDeviceStatus.plc.status.MTMATIC.status.state === 'idle')
                    )
                ){
                    this.maticDeviceStatus.errorCodes.push(1008); //reinitialization in 10s
                    setTimeout( () => {
                        this.resetInitialization();
                    },10000);
                }
            }
        }

        this.onMaticDeviceDiagnoseTimeout = setTimeout(() => {
            if (typeof this.onDeviceDiagnose !== 'undefined') {
                this.onDeviceDiagnose(localcommDomein.deepClone(this.maticDeviceStatus));
            }
        },1000);

        //no errorcodes === maticdevice OK
        return localcommDomein.deepClone(this.maticDeviceStatus);
    }

    private StartAutoSaveToDiagnosesToLochting(){
        clearTimeout(this.autoSaveDiagnosesToLochtingTimeout);
        if(this.settings.diagnoses && typeof this.settings.diagnoses.autoSaveToLochting !== 'undefined' && this.settings.diagnoses.autoSaveToLochting){
            this.AutoSaveDiagnosesToLochtingInterval();
        }
    }

    private StopAutoSaveToDiagnosesToLochting(){
        clearTimeout(this.autoSaveDiagnosesToLochtingTimeout);
    }

    private async AutoSaveDiagnosesToLochtingInterval(){

        let timeoutTimer = 300000;
        if(this.settings.diagnoses && typeof this.settings.diagnoses.autoSaveToLochtingInterval !== 'undefined' && this.settings.diagnoses.autoSaveToLochtingInterval !== 0){
            timeoutTimer = this.settings.diagnoses.autoSaveToLochtingInterval
        }

        if(this.autoSaveDiagnosesToLochtingTimeout){
            clearTimeout(this.autoSaveDiagnosesToLochtingTimeout);
        }

        this.autoSaveDiagnosesToLochtingTimeout = setTimeout(() => {
            this.AutoSaveDiagnosesToLochtingInterval();
        }, timeoutTimer);

    }

    private async checkPlcStatus() {

        if (this.checkMaticDeviceDiagnoseAfterPLCStatusIdleTimeout) {
            clearTimeout(this.checkMaticDeviceDiagnoseAfterPLCStatusIdleTimeout);
        }

        if (typeof this.maticDeviceStatus.plc.status !== 'boolean') {
            this.maticDeviceStatus.plc.released = this.maticDeviceStatus.plc.status.status.released;
            this.maticDeviceStatus.plc.emergencyActivated = this.maticDeviceStatus.plc.status.status.emergency.activated;
            this.maticDeviceStatus.plc.emergencyStatus = this.maticDeviceStatus.plc.status.status.emergency.status;
            this.maticDeviceStatus.plc.plcVersion = this.maticDeviceStatus.plc.status.status.prgVersion;
            this.maticDeviceStatus.plc.releaseStatus = this.maticDeviceStatus.plc.status.status.releaseStatus;
            if (this.maticDeviceStatus.plc.status.status.machineType === 0) {
                this.maticDeviceStatus.plc.maticType = 'MATIC';
            } else if (this.maticDeviceStatus.plc.status.status.machineType === 1) {
                this.maticDeviceStatus.plc.maticType = 'MATICLITE';
            }

            //start Convoyer belt in deliverybox forward when boxsensor detect something
            if (this.maticDeviceStatus.plc.status.status.boxDetect.sensor && this.maticDeviceStatus.delivery.cvForwardWhenBoxSensorHasDetection && !this.maticDeviceStatus.delivery.cvForwardWhenBoxSensorHasDetectionBusy) {
                //this.StartWithCVForwardWhenBoxSensorHasDetected();
            }

            if (this.shoppingCart.deliveryStatus.status === 'busy') {

                this.shoppingCart.deliveryStatus.plc.plcMessages = this.maticDeviceStatus.plc.status.messages;
                this.shoppingCart.deliveryStatus.plc.plcMessagesCodes = this.maticDeviceStatus.plc.status.plcMessagesCodes;
                this.shoppingCart.deliveryStatus.plc.eventLogs.push({
                    timestamp: Date.now(),
                    details: JSON.stringify(this.maticDeviceStatus.plc.status.MTMATIC)
                });

                /*let newPlcMessagesCodes: (string | number)[] = [];
                this.maticDeviceStatus.plc.status.messages.forEach(message => {
                    newPlcMessagesCodes.push(message.code);
                    if (this.shoppingCart.deliveryStatus.plc.plcMessagesCodes.indexOf(message.code) === -1) {
                        this.shoppingCart.deliveryStatus.plc.plcMessages.push(message);
                    }
                });
                this.shoppingCart.deliveryStatus.plc.plcMessagesCodes = newPlcMessagesCodes;
                this.shoppingCart.deliveryStatus.plc.eventLogs.push({
                    timestamp: Date.now(),
                    details: JSON.stringify(this.maticDeviceStatus.plc.status.MTMATIC)
                });*/
            }

        }

        //sometimes localcomm sends a lot of plc statuses in short time, to prevent that DeviceDiagnose check to many times > we trigger only if after 1 second no new plc statuses is received
        this.checkMaticDeviceDiagnoseAfterPLCStatusIdleTimeout = setTimeout(() => {
            this.DeviceDiagnose();
        }, 1000);

        return true;
    }

}