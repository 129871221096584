import { Domain } from 'api';

import { availableProductApi } from '@/api';

import { BaseProductSearchProvider, BaseProductSearchProviderItem } from './BaseProductSearchProvider';

export class AvailableProductSearchProvider extends BaseProductSearchProvider<Domain.AvailableProductsPage> {
    public async search(query: string): Promise<BaseProductSearchProviderItem[]> {
        if (!this.searchCache[query]) {
            this.searchCache[query] = availableProductApi.GetAvailableProducts(
                { page: 1, size: 10 },
                { field: 'name', direction: 'ascending' },
                this.locale,
                query,
            );
            this.searchCache[query].then(cachedProducts => {
                cachedProducts.items.forEach(product => {
                    if (!this.byValueCache[product.productId]) {
                        this.byValueCache[product.productId] = Promise.resolve(product);
                    }
                });
            });
        }
        const page = await this.searchCache[query];
        return page.items.map(this.mapItem);
    }

    protected getDetailsByValue(value: string) {
        return availableProductApi.GetAvailableProductDetails(value, this.locale);
    }
}
