import { LocalizedValue } from './Localisation';
import { Pagination } from './Pagination';
import { ProductConservation } from './ProductDetails';
import { Sorting } from './Sorting';
export const BranchCartRuleTypes = [
    'maxQuantityPerItem',
    'maxQuantityForProduct',
    'maxQuantityForBrand',
    'maxQuantityForCategory',
    'maxQuantityForOrganisation',
    'maxQuantityForActiveIngredient',
    'maxQuantityForProductWithConservation',
    'maxQuantityForScientificCategory',
] as const;
export const CartRuleAppliesTo = ['everyone', 'identifiedCustomer', 'unidentifiedCustomer'] as const;

export type BranchCartRuleType = (typeof BranchCartRuleTypes)[number];
export type CartRuleAppliesToType = (typeof CartRuleAppliesTo)[number];

interface BaseCartRule {
    maxQuantity: number | null;
    deviceIds: string[];
    timePeriodInDays?: number | null;
}

export type BranchRuleTypeItem = {
    orderCartRuleId: string;
    cartRuleType: BranchCartRuleType;
    configuration: BranchCartRule['configuration'];
    activeStatus: 'active' | 'inactive';
    priority: number;
    appliesTo: CartRuleAppliesToType;
};

export type BranchRuleTypePayload = {
    orderCartRules: BranchRuleTypeItem[];
    branchId: string;
    companyId: string;
};
export type BranchCartRuleConfigMap = {
    maxQuantityPerItem: BaseCartRule;
    maxQuantityForProduct: BaseCartRule & { productId: string };
    maxQuantityForBrand: BaseCartRule & { brandId: string };
    maxQuantityForCategory: BaseCartRule & { categoryId: string };
    maxQuantityForScientificCategory: BaseCartRule & { scientificCategoryId: string };
    maxQuantityForOrganisation: BaseCartRule & { organisationId: string };
    maxQuantityForActiveIngredient: BaseCartRule & { activeIngredientId: string };
    maxQuantityForProductWithConservation: BaseCartRule & { conservation: string };
};
export interface BranchCartRuleItem extends BranchRuleTypeItem {
    createdOn: string;
    updatedOn: string;
    metadata: {
        localizedLabel?: LocalizedValue | null;
        conservation?: ProductConservation;
    };
}

export type BranchCartRule =
    | {
          cartRuleType: 'maxQuantityPerItem';
          configuration: BaseCartRule;
      }
    | {
          cartRuleType: 'maxQuantityForProduct';
          configuration: BranchCartRuleConfigMap['maxQuantityForProduct'];
      }
    | {
          cartRuleType: 'maxQuantityForBrand';
          configuration: BranchCartRuleConfigMap['maxQuantityForBrand'];
      }
    | {
          cartRuleType: 'maxQuantityForCategory';
          configuration: BranchCartRuleConfigMap['maxQuantityForCategory'];
      }
    | {
          cartRuleType: 'maxQuantityForScientificCategory';
          configuration: BranchCartRuleConfigMap['maxQuantityForScientificCategory'];
      }
    | {
          cartRuleType: 'maxQuantityForOrganisation';
          configuration: BranchCartRuleConfigMap['maxQuantityForOrganisation'];
      }
    | {
          cartRuleType: 'maxQuantityForActiveIngredient';
          configuration: BranchCartRuleConfigMap['maxQuantityForActiveIngredient'];
      }
    | {
          cartRuleType: 'maxQuantityForProductWithConservation';
          configuration: BranchCartRuleConfigMap['maxQuantityForProductWithConservation'];
      };
export interface BranchCartRulePage {
    items: BranchCartRuleItem[];
    total: number;
    pagination: Pagination;
    sorting?: Sorting<keyof BranchCartRuleItem> | null;
    search?: string | null;
}

export type OrderCartValidationErrorResponse = {
    cartRuleType: BranchCartRuleType;
    context: {
        label: LocalizedValue;
        maxQuantity: number | null;
    };
};
