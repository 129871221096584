import { Pagination, Sorting, BranchCartRulePage, BranchRuleTypePayload } from '@/Domain';

import Endpoint from './Endpoint';
export default class BranchCartRulesEndpoint extends Endpoint {
    public async UpdateOrderCartRules(payload: BranchRuleTypePayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateOrderCartRules',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Order\\OrderCartRule\\UpdateOrderCartRules\\UpdateOrderCartRules',
                payload: payload,
            },
        });
    }

    public async GetBranchCartRules(
        branchId: string,
        pagination: Pagination,
        sorting: Sorting,
        filters: {
            deviceIds?: string;
            activeStatus?: 'active' | 'inactive';
        },
    ): Promise<BranchCartRulePage> {
        const response = await this.client.get({
            url: `/branch/${branchId}/cart-rule-overview`,

            params: {
                pagination,
                sorting,
                filters: {
                    deviceId: filters.deviceIds,
                    activeStatus: filters.activeStatus,
                },
            },
        });
        this.guardResponseSchema('#/definitions/BranchCartRulePage', response);

        return response;
    }
}
