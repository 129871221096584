import { combineReducers } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction } from '@/action';
import { branchApi, userApi, deviceApi } from '@/api';
import { DeviceProps, defaultSorting as devicesDefaultSorting, pageSize as devicesPageSize } from '@/Device/overviewState';
import { makeActions, makeReducerActions, makeSelectors } from '@/makeDetailsState';
import * as makeDetailsState from '@/makeDetailsState';
import * as makeOverviewState from '@/makeOverviewState';
import { URLParams, URLQuery } from '@/routing';
import { UserProps, defaultSorting as usersDefaultSorting, pageSize as usersPageSize } from '@/User/overviewState';

export const usersSelectors = makeOverviewState.makeSelectors<Domain.UserDetails, UserProps>({
    getState: rootState => rootState.branch.details.users,
});

export const usersReducerActions = makeOverviewState.makeReducerActions<Domain.UserDetails, UserProps>({
    reducerPrefix: '@branch/details/users/overview',
});

export const usersActions = makeOverviewState.makeActions<Domain.UserDetails, UserProps>({
    dataTableSaveKey: 'branchDetailsUsersOverview-v1',
    loadApi: options =>
        userApi.GetUsers({ type: 'branch', ownerId: options.urlParams.branchId }, options.pagination, options.sorting, undefined, {
            branchId: options.urlParams.branchId,
        }),
    defaultSorting: usersDefaultSorting,
    pageSize: usersPageSize,
    reducerActions: usersReducerActions,
    selectors: usersSelectors,
});

export const usersOverviewReducer = makeOverviewState.makeReducer<Domain.UserDetails, UserProps>({
    defaultSorting: usersDefaultSorting,
    pageSize: usersPageSize,
    reducerActions: usersReducerActions,
});

export const devicesSelectors = makeOverviewState.makeSelectors<Domain.Device, DeviceProps>({
    getState: rootState => rootState.branch.details.devices,
});

export const devicesReducerActions = makeOverviewState.makeReducerActions<Domain.Device, DeviceProps>({
    reducerPrefix: '@branch/details//devices/overview',
});

export const devicesActions = makeOverviewState.makeActions<Domain.Device, DeviceProps>({
    dataTableSaveKey: 'branchDetailsDevicesOverview-v1',
    loadApi: options =>
        deviceApi.GetDevices({ type: 'branch', ownerId: options.urlParams.branchId }, options.pagination, options.sorting, '', {
            branchId: options.urlParams.branchId,
        }),
    defaultSorting: devicesDefaultSorting,
    pageSize: devicesPageSize,
    reducerActions: devicesReducerActions,
    selectors: devicesSelectors,
});

export const devicesOverviewReducer = makeOverviewState.makeReducer<Domain.Device, DeviceProps>({
    defaultSorting: devicesDefaultSorting,
    pageSize: devicesPageSize,
    reducerActions: devicesReducerActions,
});

export const selectors = makeSelectors<Domain.Branch>({
    getState: rootState => rootState.branch.details.base,
});

export const reducerActions = makeReducerActions<Domain.Branch>({
    reducerPrefix: '@branch/details',
});

export const baseActions = makeActions<Domain.Branch>({
    loadApi: options => branchApi.GetBranchDetails(options.urlParams.branchId),
    reducerActions,
    selectors,
});

export const actions = {
    ...baseActions,
    load:
        (options: { urlQuery: URLQuery; urlParams: URLParams }): ThunkAction =>
        async (dispatch, getState) => {
            const state = getState();
            const loadedBranch = selectors.maybeSelectDetails(state);

            if (loadedBranch && loadedBranch.branchId !== options.urlParams.branchId) {
                dispatch(usersActions.setPaginationPage(1));
                dispatch(devicesActions.setPaginationPage(1));
            }

            const loads: Promise<any>[] = [dispatch(baseActions.load(options))];

            switch (options.urlParams.tab) {
                case 'users':
                    loads.push(dispatch(usersActions.load(options)));
                    break;

                case 'devices':
                    loads.push(dispatch(devicesActions.load(options)));
                    break;

                default:
            }

            await Promise.all(loads);
        },
};

const baseReducer = makeDetailsState.makeReducer<Domain.Branch>({
    reducerActions,
});

export const detailsReducer = combineReducers({
    base: baseReducer,
    users: usersOverviewReducer,
    devices: devicesOverviewReducer,
});
