import * as React from 'react';

import { Route, isBranchManager, BranchCartRules } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/vending-machines/cart-rules',
        main: () => import('./UpdateBranchCartRules'),
        isAuthorized: isBranchManager,
        preloadData: BranchCartRules.overviewState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
];

export default routes;
