import * as React from 'react';

import { Icon } from '@/icon';

import styles from './DatePicker.scss';

export const PrevNextMonth = React.memo((props: { onChange: (offset: -1 | 1) => void; disabled?: boolean }) => {
    return (
        <div className={styles.DatePickerPrevNext}>
            <button
                className={styles.DatePickerPrev}
                onClick={event => {
                    event.preventDefault();
                    if (!props.disabled) {
                        props.onChange(-1);
                    }
                }}
                tabIndex={0}
                data-test-id="date-picker-prev-month"
            >
                <Icon type="action_left" />
            </button>
            <button
                className={styles.DatePickerNext}
                onClick={event => {
                    event.preventDefault();
                    if (!props.disabled) {
                        props.onChange(+1);
                    }
                }}
                tabIndex={0}
                data-test-id="date-picker-next-month"
            >
                <Icon type="action_right" />
            </button>
        </div>
    );
});
