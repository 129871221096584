import * as React from 'react';

import { Domain } from 'api';
import { config } from 'config';

import styles from './CmsPageBlock.scss';

export const TableItem = ({ content }: { content: Domain.CmsItemBlockTable }) => {
    return (
        <table className={styles.CmsPageTable}>
            <tbody>
                {content.rows.map((row, rowIndex) => {
                    if (rowIndex >= content.numRows) {
                        return null;
                    }

                    return (
                        <tr key={rowIndex}>
                            {row.map((col, colIndex) => {
                                if (colIndex >= content.numCols) {
                                    return null;
                                }

                                return (
                                    <td
                                        key={colIndex}
                                        style={{
                                            width: 100 / content.numCols + '%',
                                        }}
                                    >
                                        <HtmlItem content={col.content} />
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export const ImageItem = ({ content }: { content: Domain.CmsItemBlockMediaItem }) => {
    return (
        <img
            src={`${config.apiBaseUrl}/media-item/${content.mediaItemId}/preview`}
            alt=""
            className={styles.CmsPageImage}
        />
    );
};

export const HtmlItem = ({ content }: { content: string }) => {
    return (
        <div
            dangerouslySetInnerHTML={{ __html: content }}
            className={styles.CmsPageRichText}
        />
    );
};

export function Blocks<
    T extends {
        blockId: string;
    },
>({ blocks, blockRenderer }: { blocks: T[]; blockRenderer: (content: T) => React.ReactNode }) {
    return (
        <div className={styles.CmsContentWrapper}>
            {blocks.map(block => {
                return (
                    <div
                        key={block.blockId}
                        className={styles.CmsPageBlock}
                    >
                        {blockRenderer(block)}
                    </div>
                );
            })}
        </div>
    );
}

export default function CmsBlocksRenderer({ blocks }: { blocks: Domain.CmsItemBlock[] }) {
    return (
        <Blocks
            blocks={blocks}
            blockRenderer={block =>
                block.type === 'html' ? (
                    <HtmlItem content={block.content} />
                ) : block.type === 'image' && block.content ? (
                    <ImageItem content={block.content} />
                ) : block.type === 'table' ? (
                    <TableItem content={block.content} />
                ) : null
            }
        />
    );
}
