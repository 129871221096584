/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment';
// @ts-ignore
import cs from 'moment/locale/cs';
// @ts-ignore
import de from 'moment/locale/de';
// @ts-ignore
import es from 'moment/locale/es';
// @ts-ignore
import fr from 'moment/locale/fr';
// @ts-ignore
import nl from 'moment/locale/nl';
// @ts-ignore
import pt from 'moment/locale/pt';

import { Domain, Infrastructure } from 'api';

import { ThunkAction } from '@/action';
import { translationApi } from '@/api';
import { selectLoggedInUserLocales } from '@/Authentication';
import { getLanguageCodeFromLocale } from '@/getLanguageCodeFromLocale';
import { ALL_LOCALES } from '@/Localisation/availableCountriesState';
import { URLQuery } from '@/routing';

import { setCurrentLocale } from './browserStorage';
import { selectCurrentLocale, selectLoadedTranslationsLocale } from './selectors';
import { setLocale, setTranslations, setLoadedTranslationsLocale } from './state';
import { Translations } from './types';
export const InitMomentLocale = (): ThunkAction => async (_1, getState) => {
    const state = getState();
    const locale = selectCurrentLocale(state);
    moment.locale(getLanguageCodeFromLocale(locale));
};

export const SetLocale =
    (newLocale: Domain.Locale): ThunkAction =>
    async dispatch => {
        Infrastructure.Container.setConstant('locale', newLocale);
        await dispatch(setLocale(newLocale));
        await setCurrentLocale(newLocale);
        await dispatch(InitMomentLocale());
        await dispatch(LoadTranslations());
    };

export const CheckLoggedInUserUILocale = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const locales = selectLoggedInUserLocales(state);
    const currentLocale = selectCurrentLocale(state);
    if (locales.indexOf(currentLocale) === -1 && locales.length > 0) {
        dispatch(SetLocale(locales[0]));
    } else {
        Infrastructure.Container.setConstant('locale', currentLocale);
    }
};

export const LoadTranslations = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const locale = selectCurrentLocale(state);
    const loadedTranslationsLocale = selectLoadedTranslationsLocale(state);

    if (loadedTranslationsLocale !== undefined && locale === loadedTranslationsLocale) {
        return;
    }

    const publicTranslations = await translationApi.getTranslationByLocale(locale, 'platform');
    const translations: Translations = {};

    publicTranslations.forEach(item => {
        const context = item.context || '';
        if (!translations[context]) {
            translations[context] = {};
        }

        if (item.translation !== null) {
            translations[context][item.entry] = item.translation;
        }
    });

    await dispatch(setLoadedTranslationsLocale(locale));
    await dispatch(setTranslations(translations));
};

export function isValidLocale(locale: string): locale is Domain.Locale {
    return ALL_LOCALES.indexOf(locale as Domain.Locale) > -1;
}

export const initLocale =
    (options: { urlQuery: URLQuery }): ThunkAction =>
    async dispatch => {
        const lang = options.urlQuery.get('lang');

        if (lang && isValidLocale(lang)) {
            await dispatch(SetLocale(lang));
        }
    };
