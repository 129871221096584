import { Domain } from 'api';

import { customProductApi } from '@/api';
import { BaseProductSearchProvider, BaseProductSearchProviderItem } from '@/AvailableProduct/BaseProductSearchProvider';

export class CustomProductSearchProvider extends BaseProductSearchProvider<Domain.CustomProductsPage> {
    public async search(query: string): Promise<BaseProductSearchProviderItem[]> {
        if (!this.searchCache[query] && this.ownership?.branchId) {
            this.searchCache[query] = customProductApi.GetCustomProducts(
                {
                    type: 'branch',
                    ownerId: this.ownership.branchId,
                },
                { page: 1, size: 10 },
                this.locale,
                '',
                query,
            );
            this.searchCache[query].then(cachedProducts => {
                cachedProducts.items.forEach(product => {
                    if (!this.byValueCache[product.productId]) {
                        this.byValueCache[product.productId] = Promise.resolve(product);
                    }
                });
            });
        }
        const page = await this.searchCache[query];
        return page.items.map(this.mapItem);
    }

    protected getDetailsByValue(value: string) {
        return customProductApi.GetCustomProductDetails(value, this.locale);
    }
}
