import { ThermoValuesResponseMessage, LockerStatusResponseMessage } from './Domain';
import LocalComm from './Infrastructure/LocalComm/LocalComm';
import {
    ICollect,
    ILocker,
    ILockStatus,
    ILockerType,
    IModule,
    IModuleStatus,
    LockerEvent,
    LockerEventEmitter }
    from 'meditech-device-interface';

export class Collect implements ICollect {
    LockerEvent: LockerEventEmitter;

    private async onThermoValuesReceivedHandler(
        responseMessage: ThermoValuesResponseMessage) {

        for (const module of responseMessage.modules) {
            const moduleStatus: IModuleStatus = {
                ModuleNumber: module.nr,
                Temperature: module.temperature_celcius,
                Humidity: module.humidity,
                FanSpeed: module.fanspeed_pct,
                HeatingActive: 0 != module.heating_active,
                CoolingActive: 0 != module.cooling_active
            };

            this.LockerEvent.emit(LockerEvent.ModuleStateChange, moduleStatus);
        }
    }

    private async onLockerStatusReceivedHandler(
        responseMessage: LockerStatusResponseMessage) {

        for (const lock of responseMessage.lockers) {
            const lockStatus: ILockStatus = {
                LockNumber: lock.lockernr,
                ModuleNumber: lock.modulenr,
                Status: lock.locked
            }

            this.LockerEvent.emit(LockerEvent.LockStateChanged, lockStatus);
        }
    }

    private _moduleGridConfiguration: Array<IModule>;
    private readonly _localCom: LocalComm;
    constructor(localCom: LocalComm) {
        this.LockerEvent = new LockerEventEmitter();
        this._localCom = localCom;

        this._localCom.ThermoValuesReceived.on(
            async (localComClient, message) => {
                localComClient;
                await this.onThermoValuesReceivedHandler(message);
            }
        );

        this._localCom.LockerStatusReceived.on(
            async (localComClient, message) => {
                localComClient;
                await this.onLockerStatusReceivedHandler(message);
            }
        )
    }

    async OpenLock(
        moduleNumber: number,
        lockerNumber: number):
        Promise<{ success: boolean; lockStatus: ILockStatus | undefined }> {

        if (await this.EnsureModuleGridConfiguration()) {
            const lock =
                this.GetLock(moduleNumber, lockerNumber);

            if (lock) {
                await this._localCom.OpenLocker(moduleNumber, lock.LockerNumber);
                await new Promise(res => setTimeout(res, 2000));
                const lockStatusResponse = await this.GetLockStatus(moduleNumber, lock.LockerNumber);

                if (lockStatusResponse.success && lockStatusResponse.lockStatus) {
                    this.LockerEvent.emit(LockerEvent.LockStateChanged, lockStatusResponse.lockStatus);
                    return Promise.resolve({ success: true, lockStatus: lockStatusResponse.lockStatus });
                }
            }
        }

        return Promise.resolve({ success: false, lockStatus: undefined });
    }

    async GetLockStatus(
        moduleNumber: number,
        lockerNumber: number):
        Promise<{ success: boolean; lockStatus: ILockStatus | undefined }> {

        if (await this.EnsureModuleGridConfiguration()) {
            const lock =
                this.GetLock(moduleNumber, lockerNumber);

            if (lock) {
                const lockerStatusResponse = await this._localCom.GetLockerStatus(
                    // temporary change to test if asking for all statuses always is as fast
                    // moduleNumber,
                    // lock.LockerNumber
                );

                if (lockerStatusResponse && lockerStatusResponse.lockers.length > 0) {
                    for (const locker of lockerStatusResponse.lockers) {
                        if (locker.modulenr === moduleNumber && locker.lockernr === lockerNumber) {
                            const lockStatus: ILockStatus = {
                                LockNumber: locker.lockernr,
                                ModuleNumber: locker.modulenr,
                                Status: locker.locked
                            };

                            return Promise.resolve({ success: true, lockStatus: lockStatus });
                        }
                    }
                }
            }
        }

        return Promise.resolve({ success: false, lockStatus: undefined });
    }

    async GetLockStatuses(): Promise<{ success: boolean; lockStatuses: Array<ILockStatus> }> {
        const lockStatuses = new Array<ILockStatus>();
        const lockerStatusResponse = await this._localCom.GetLockerStatus();

        if (lockerStatusResponse && lockerStatusResponse.lockers.length > 1) {
            for (const locker of lockerStatusResponse.lockers) {
                const lockStatus: ILockStatus = {
                    LockNumber: locker.lockernr,
                    ModuleNumber: locker.modulenr,
                    Status: locker.locked
                };

                lockStatuses.push(lockStatus);
            }

            return Promise.resolve({ success: true, lockStatuses: lockStatuses });
        }

        return Promise.resolve({ success: false, lockStatuses: lockStatuses });
    }

    async GetModuleGridConfiguration():
        Promise<{ success: boolean, moduleGrid: Array<IModule> | undefined }> {

        const moduleGrid = Array<IModule>();
        const lockerConfigurationResponse =
            await this._localCom.GetLockerConfiguration();

        if (lockerConfigurationResponse) {
            lockerConfigurationResponse.modules.forEach(m => {
                const module: IModule = {
                    ModuleNumber: m.nr,
                    ModuleType: m.type,
                    Lockers: [],
                    GridPositionX: m.front_x_left,
                    GridPositionY: m.bottom_y
                }

                if (m.monitor) {
                    const screen: ILocker = {
                        LockerNumber: m.lockers.length + 1,
                        Type: ILockerType.SCREEN,
                        GridPositionX: m.monitor.front_x_left,
                        GridPositionY: m.monitor.front_y_bottom,
                        DimensionX: m.monitor.front_width,
                        DimensionY: m.monitor.front_height,
                        DimensionZ: 250
                    };

                    module.Lockers.push(screen);
                }

                if (m.payterminal) {
                    const payment_terminal: ILocker = {
                        LockerNumber: m.lockers.length + 2,
                        Type: ILockerType.PAYMENT_TERMINAL,
                        GridPositionX: m.payterminal.front_x_left,
                        GridPositionY: m.payterminal.front_y_bottom,
                        DimensionX: m.payterminal.front_width,
                        DimensionY: m.payterminal.front_height,
                        DimensionZ: 250
                    };

                    module.Lockers.push(payment_terminal);
                }

                m.lockers.forEach(l => {
                   const locker: ILocker = {
                       LockerNumber: l.lockernr,
                       Type: ILockerType.COLLECT,
                       GridPositionX: l.front_x_left,
                       GridPositionY: l.front_y_bottom,
                       DimensionX: l.front_width,
                       DimensionY: l.front_height,
                       DimensionZ: 250,
                       QRCode: l.qr_code || undefined
                   };

                   module.Lockers.push(locker);
                });

                moduleGrid.push(module);
            });

            return Promise.resolve({ success: true, moduleGrid: moduleGrid });
        }

        return Promise.resolve({ success: false, moduleGrid: moduleGrid });
    }

    private async EnsureModuleGridConfiguration():
        Promise<boolean> {

        if (!this._moduleGridConfiguration || 0 === this._moduleGridConfiguration.length) {
            const getModuleGridConfigurationResponse =
                await this.GetModuleGridConfiguration();

            if (getModuleGridConfigurationResponse.success) {
                this._moduleGridConfiguration = getModuleGridConfigurationResponse.moduleGrid!;
                return Promise.resolve(true);
            }

            return Promise.resolve(false);
        }

        return Promise.resolve(true);
    }

    private GetModule(
        moduleNumber: number):
        IModule | undefined {

        const moduleGridModule =
            this._moduleGridConfiguration.find(m => moduleNumber === m.ModuleNumber);

        if (moduleGridModule) {
            return moduleGridModule;
        }

        return undefined
    }

    private GetLock(
        moduleNumber: number,
        lockNumber: number):
        ILocker | undefined {

        const moduleGridModule =
            this.GetModule(moduleNumber);

        if (moduleGridModule) {
            const moduleGridLock =
                moduleGridModule.Lockers.find(ml => lockNumber === ml.LockerNumber);

            if (moduleGridLock) {
                return moduleGridLock;
            }
        }

        return undefined;
    }
}