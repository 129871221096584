import { ConfiguredIntegrationId } from './Integration';

export interface LockerAddress {
    moduleId: string;
    lockerId: string;
}

export interface VendingMachineDeliveryArticle {
    key: string;
    imageUrl?: string;
    name: string;
    quantity: number;
}

export type VendingMachineDeliverFrom = {
    articles: VendingMachineDeliveryArticle[];
    multipleDeliveries: boolean;
    otherDeliveriesFollow: boolean;
    deliveriesAreNeededOnOtherDevices: boolean;
} & (
    | {
          location: 'robot';
      }
    | {
          location: 'lockers';
          lockers: LockerAddress[];
      }
);

export interface StockToDeliver {
    robot: {
        barcode: string;
        quantity: number;
    }[];
    lockers: (LockerAddress & {
        barcode: string;
    })[];
    inStoreSelfCheckout: {
        barcode: string;
        quantity: number;
    }[];
}

export const VENDING_MACHINE_DELIVERY_STATUS = [
    'waitingForDeliveryToBeRequested',
    'deliveryRequested',
    'deliveryRequestFailed',
    'delivered',
    'deliveryFailed',
    'pickedUp',
    'pickUpFailed',
] as const;
export type VendingMachineDeliveryStatus = (typeof VENDING_MACHINE_DELIVERY_STATUS)[number];

export const VENDING_MACHINE_DELIVERY_TYPE = ['vendingMachine', 'locker'] as const;
export type VendingMachineDeliveryType = (typeof VENDING_MACHINE_DELIVERY_TYPE)[number];

export interface VendingMachineDelivery {
    deliveryId: string;
    deliverFrom: ConfiguredIntegrationId;
    deliveryType: VendingMachineDeliveryType;
    deliveryStatus: VendingMachineDeliveryStatus;
    externalDeliveryId?: string | null;
    destinationId?: number | null;
    deliveryData?:
        | {
              [key: string]: any;
          }
        | []
        | null;
    createdAt: string;
    updatedAt?: string | null;
    pickupId?: string | null;
    orderId?: string | null;
    deliveredOn?: string | null;
}
