import { Domain } from 'api';

import { ThunkAction } from '@/action';
import { slideshowApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';
import { URLParams, URLQuery } from '@/routing';

import { loadSharedCompanyList } from '../Company/SharedCompaniesState';
type SlideshowProps = keyof Domain.Slideshow;

export const defaultSorting: Domain.Sorting<SlideshowProps> = {
    field: 'name',
    direction: 'ascending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.Slideshow, SlideshowProps>({
    getState: rootState => rootState.slideshow.overview,
});

export const reducerActions = makeReducerActions<Domain.Slideshow, SlideshowProps>({
    reducerPrefix: '@slideshow/overview',
});

export const baseActions = makeActions<
    Domain.Slideshow,
    SlideshowProps,
    {
        companyId?: Domain.Company['companyId'];
        branchId?: Domain.Branch['branchId'];
        includeShared?: string;
        ownedByCompany?: string;
    }
>({
    dataTableSaveKey: 'slideshowsOverview-v5',
    loadApi: options =>
        slideshowApi.GetSlideshows(options.ownership, options.pagination, options.sorting, options.search, options.filters, options.locale),
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/presentations/slideshows',
    reducerActions,
    selectors,
});
export const actions = {
    ...baseActions,
    load:
        (options: { urlParams: URLParams; urlQuery: URLQuery }): ThunkAction =>
        async dispatch => {
            await Promise.all([dispatch(baseActions.load(options)), dispatch(loadSharedCompanyList('slideshow'))]);
        },
};
export const overviewReducer = makeReducer<Domain.Slideshow, SlideshowProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
