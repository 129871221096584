import { createAction, createReducer, Selector } from '@reduxjs/toolkit';

import { Infrastructure, Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { authenticationApi, branchApi } from '@/api';
import { infoItemsState } from '@/CmsItem';
import { logOverviewState } from '@/NightHatch';
import { URLQuery, URLParams } from '@/routing';
import { RootState } from '@/store';

import { selectGlobalSelectedDevice } from './globalSelectionState';

export type ConnectionStatus = 'disconnected' | 'connected' | 'connecting' | 'failed' | 'disconnecting';

export interface State {
    tunnelingJwt?: Infrastructure.JWT.JsonWebToken;
    connectionStatus: ConnectionStatus;
    nightHatch: Domain.NightHatchState;
    branch?: Domain.BranchDetails;
}

const initialNightHatch: Domain.NightHatchState = {
    cart: {
        items: [],
        total: 0,
    },
    sessionStep: null,
    sessionSubstep: null,

    // cart: {
    //     items: [
    //         {
    //             itemId: 'CART_ITEM-86b9f42d-d03b-4bbe-bc87-829d5f44e3bd',
    //             product: {
    //                 pid: 'product-2229b263-0fb3-408d-845d-6bd65f7cdce4',
    //                 name: { en_BE: 'Travel MINTS (blue)', fr_BE: 'Travel MINTS (blue)', nl_BE: 'Travel MINTS (blue)' },
    //                 price: 145,
    //                 cids: [
    //                     'category-cb897e0f-1c8a-42fd-b0df-7839f3c2b1d7|category-2440d8a1-09f5-4581-a0bc-616cb2eedcd0|category-bf80747d-28d5-4680-8684-cda9e5dbfcc4',
    //                 ],
    //                 bids: ['brand-3018b2f2-17ad-41ca-8256-066f77cb7b03'],
    //                 pcodes: { EanCode: ['5060114952645'] },
    //                 img: {
    //                     en_BE: '/media-item/mediaItem-df954fc8-e724-47f4-b7be-801cece43d57/preview/450x450',
    //                     fr_BE: '/media-item/mediaItem-df954fc8-e724-47f4-b7be-801cece43d57/preview/450x450',
    //                     nl_BE: '/media-item/mediaItem-df954fc8-e724-47f4-b7be-801cece43d57/preview/450x450',
    //                 },
    //                 robotStock: 3,
    //                 lockerStock: 0,
    //             },
    //             unitPrice: 145,
    //             quantity: 1,
    //             quantityDeliveredFromRobot: 0,
    //             quantityDeliveredFromLockers: 0,
    //         },
    //         {
    //             itemId: 'CART_ITEM-48f5250b-0f2d-41c9-b7c2-63336ba3155b',
    //             product: {
    //                 pid: 'product-ee69aab0-059c-42ab-b4a0-ce82ab4cb994',
    //                 name: {
    //                     en_BE: 'W5 Universal Ass Wipes Apple',
    //                     fr_BE: 'W5 Universal Ass Wipes Apple',
    //                     nl_BE: 'W5 Universal Ass Wipes Apple',
    //                 },
    //                 price: 2999,
    //                 vatr: 21,
    //                 cids: ['category-9a2ab75a-cfaa-4f37-b960-80d0c811f56a|category-9abee0f9-a201-45a1-ac36-75326467634c'],
    //                 bids: ['brand-3187a1e1-6830-4a12-89ca-ae5219be26bd'],
    //                 pcodes: { EanCode: ['4056489304258'] },
    //                 img: {
    //                     en_BE: '/media-item/mediaItem-70bcb101-829d-4cb7-a895-f5f5a61be60f/preview/450x450',
    //                     fr_BE: '/media-item/mediaItem-70bcb101-829d-4cb7-a895-f5f5a61be60f/preview/450x450',
    //                     nl_BE: '/media-item/mediaItem-70bcb101-829d-4cb7-a895-f5f5a61be60f/preview/450x450',
    //                 },
    //                 robotStock: 3,
    //                 lockerStock: 0,
    //             },
    //             unitPrice: 2999,
    //             quantity: 1,
    //             quantityDeliveredFromRobot: 0,
    //             quantityDeliveredFromLockers: 0,
    //         },
    //         {
    //             itemId: 'CART_ITEM-a475a8f8-40d8-441b-88cf-2dfcfb095b87',
    //             product: {
    //                 pid: 'product-9ceb950b-6ddc-468a-b493-467afa29e044',
    //                 name: { en_BE: 'OTIPAX', fr_BE: 'OTIPAX', nl_BE: 'OTIPAX' },
    //                 price: 995,
    //                 vatr: 12,
    //                 cids: ['category-51ba960e-e05a-4320-a092-8450f0f6921f|category-cd2e04f0-1c2d-4746-a90f-b4c91932dd1d'],
    //                 bids: ['brand-0a0b3200-fd70-4cf5-bbb0-1890a4c9af14'],
    //                 pcodes: { EanCode: ['3583313279068'] },
    //                 img: {
    //                     en_BE: '/media-item/mediaItem-6cd18bd1-ac3b-4ac2-9b3d-d8e90447f997/preview/450x450',
    //                     fr_BE: '/media-item/mediaItem-6cd18bd1-ac3b-4ac2-9b3d-d8e90447f997/preview/450x450',
    //                     nl_BE: '/media-item/mediaItem-6cd18bd1-ac3b-4ac2-9b3d-d8e90447f997/preview/450x450',
    //                 },
    //                 robotStock: 0,
    //                 lockerStock: 0,
    //             },
    //             unitPrice: 995,
    //             quantity: 1,
    //             quantityDeliveredFromRobot: 0,
    //             quantityDeliveredFromLockers: 0,
    //         },
    //         {
    //             itemId: 'CART_ITEM-a09d8d40-376d-48ee-9f03-44d169823916',
    //             product: {
    //                 pid: 'product-2c3e0f19-753e-409a-839e-9d0f4b996c65',
    //                 name: { en_BE: 'ThiamaCare 10mg/ml', fr_BE: 'ThiamaCare 10mg/ml', nl_BE: 'ThiamaCare 10mg/ml' },
    //                 price: 50000,
    //                 vatr: 6,
    //                 cids: ['category-e301fcdc-1376-4cbb-a804-820944f0685a|category-292f1efa-bb86-4e51-9764-3d31244f71c4'],
    //                 bids: ['brand-3187a1e1-6830-4a12-89ca-ae5219be26bd'],
    //                 pcodes: { EanCode: ['5055037402711'] },
    //                 img: {
    //                     en_BE: '/media-item/mediaItem-19853ebc-6ffb-4857-b898-99a320bfbd44/preview/450x450',
    //                     fr_BE: '/media-item/mediaItem-19853ebc-6ffb-4857-b898-99a320bfbd44/preview/450x450',
    //                     nl_BE: '/media-item/mediaItem-19853ebc-6ffb-4857-b898-99a320bfbd44/preview/450x450',
    //                 },
    //                 robotStock: 3,
    //                 lockerStock: 0,
    //             },
    //             unitPrice: 50000,
    //             quantity: 1,
    //             quantityDeliveredFromRobot: 0,
    //             quantityDeliveredFromLockers: 0,
    //         },
    //         {
    //             itemId: 'CART_ITEM-dc845fdf-0651-4369-b30b-2bab47ed9b87',
    //             product: {
    //                 pid: 'product-0af1ff5c-2604-4cd5-a77b-6927513c9129',
    //                 name: { nl_BE: 'Klorane Capil. Olie Mango 50ml', fr_BE: 'Klorane Capil. Huile Mangue 50ml' },
    //                 cids: [],
    //                 bids: ['brand-1e645829-8f8d-47c7-bcf1-29e3f0ef349e'],
    //                 pcodes: { CnkCode: ['4119111'] },
    //                 img: {},
    //                 robotStock: 3,
    //                 lockerStock: 0,
    //             },
    //             unitPrice: 0,
    //             quantity: 1,
    //             quantityDeliveredFromRobot: 0,
    //             quantityDeliveredFromLockers: 0,
    //         },
    //     ],
    //     total: 54139,
    // },
    requestedFromCustomer: [],
};

const initialState: State = {
    connectionStatus: 'disconnected',
    nightHatch: initialNightHatch,
};

export const reducerActions = {
    setTunnelingJwt: createAction(
        '@vendingMachine/nightHatch/setTunnelingJwt',
        withPayloadType<Infrastructure.JWT.JsonWebToken | undefined>(),
    ),
    setConnectionStatus: createAction('@vendingMachine/nightHatch/setConnectionStatus', withPayloadType<ConnectionStatus>()),
    setNightHatch: createAction('@vendingMachine/nightHatch/setNightHatch', withPayloadType<Domain.NightHatchState>()),
    resetNightHatch: createAction('@vendingMachine/nightHatch/resetNightHatch'),
    setBranch: createAction('@vendingMachine/nightHatch/setBranch', withPayloadType<Domain.BranchDetails>()),
};

export const nightHatchReducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.setTunnelingJwt, (state, action) => {
            state.tunnelingJwt = action.payload;
        })
        .addCase(reducerActions.setConnectionStatus, (state, action) => {
            state.connectionStatus = action.payload;
        })
        .addCase(reducerActions.setNightHatch, (state, action) => {
            state.nightHatch = action.payload;
        })
        .addCase(reducerActions.resetNightHatch, state => {
            state.nightHatch = initialNightHatch;
        })
        .addCase(reducerActions.setBranch, (state, action) => {
            state.branch = action.payload;
        }),
);

export const selectTunnelingJwt: Selector<RootState, Infrastructure.JWT.JsonWebToken> = state => {
    const token = state.device.nightHatch.tunnelingJwt;

    if (!token) {
        throw new Error('Tunneling JWT not loaded');
    }

    return token;
};

export const selectConnectionStatus: Selector<RootState, ConnectionStatus> = state => state.device.nightHatch.connectionStatus;
export const selectNightHatch: Selector<RootState, Domain.NightHatchState> = state => state.device.nightHatch.nightHatch;
export const maybeSelectBranch: Selector<RootState, Domain.BranchDetails | undefined> = state => state.device.nightHatch.branch;

export const loadDeviceSignalingTunnelToken =
    (deviceId: string): ThunkAction =>
    async dispatch => {
        const token = await authenticationApi.GetDeviceSignalingTunnelToken(deviceId);
        dispatch(reducerActions.setTunnelingJwt(token));
    };

export const loadDevice = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const device = selectGlobalSelectedDevice(state);

    if (!device) {
        throw new Error('No selected device');
    }

    await dispatch(loadDeviceSignalingTunnelToken(device.deviceId));
};

export const loadBranch = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const device = selectGlobalSelectedDevice(state);

    if (!device) {
        return;
    }

    await dispatch(reducerActions.setBranch(await branchApi.GetBranchDetails(device.branchId)));
};

export const loadInfoCmsItems = (): ThunkAction => async dispatch => {
    await dispatch(
        infoItemsState.preloadCMSItems([
            'vendingMachineCatalogueIsEnabledInfo',
            'vendingMachineCallPharmacistIsEnabledInfo',
            'vendingMachinePharmacyOnDutyIsEnabledInfo',
            'vendingMachineReservationsAreEnabledInfo',
        ]),
    );
};

export const load =
    (options: { urlQuery: URLQuery; urlParams: URLParams }): ThunkAction =>
    async dispatch => {
        await Promise.all([
            dispatch(loadDevice()),
            dispatch(loadBranch()),
            dispatch(logOverviewState.actions.load(options)),
            dispatch(loadInfoCmsItems()),
        ]);
    };
export const reload = (): ThunkAction => async dispatch => {
    await dispatch(logOverviewState.actions.setPaginationPage(1));
    await Promise.all([dispatch(loadDevice()), dispatch(loadBranch()), dispatch(logOverviewState.actions.loadCurrentPage())]);
};

export const HandleDisconnect = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const connectionStatus = selectConnectionStatus(state);

    if (connectionStatus === 'disconnecting') {
        await dispatch(reducerActions.setConnectionStatus('disconnected'));
    } else {
        await dispatch(reducerActions.setConnectionStatus('failed'));
    }
};
