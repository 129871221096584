import * as React from 'react';
import { useSelector } from 'react-redux';

import { Domain } from 'api';
import { useLocalize, I18n } from 'data-store';

import { localizeWithFallback } from '@/LocalizedValue';

import { Blocks, TableItem, ImageItem, HtmlItem } from './CmsBlocksRenderer';

const LocalizedTableItem = ({
    block,
    locale,
}: {
    block: {
        blockId: string;
        type: 'table';
        content: Domain.LocalizedValue<Domain.CmsItemBlockTable>;
    };
    locale: Domain.Locale;
}) => {
    const localize = useLocalize();
    const content = localizeWithFallback({
        value: block.content as unknown as Domain.LocalizedValue,
        localize,
        toLocale: locale,
        fallback: true,
        addFallbackLangPrefix: false,
        preferMainValueStructure: true,
    }) as unknown as Domain.CmsItemBlockTable;

    return <TableItem content={content} />;
};

export default function LocalizedCmsBlocksRenderer({ blocks }: { blocks: Domain.LocalizedCmsItemBlock[] }) {
    const localize = useLocalize();
    const locale = useSelector(I18n.selectCurrentLocale);
    return (
        <Blocks
            blocks={blocks}
            blockRenderer={block =>
                block.type === 'html' ? (
                    <HtmlItem
                        content={
                            localizeWithFallback({
                                value: block.content,
                                localize,
                                toLocale: locale,
                                fallback: true,
                                addFallbackLangPrefix: false,
                                plaintext: true,
                            }) as string
                        }
                    />
                ) : block.type === 'image' && block.content ? (
                    <ImageItem content={block.content} />
                ) : block.type === 'table' ? (
                    <LocalizedTableItem
                        block={block}
                        locale={locale}
                    />
                ) : null
            }
        />
    );
}
