import * as React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Domain, Infrastructure } from 'api';
import { useThunkDispatch, selectIsLoggedInAsBranchManager, Device, exportApi } from 'data-store';
export default function NotificationLink(props: {
    notification: Domain.Notification;
    className?: string;
    onClick?: () => Promise<void> | void;
    children?: React.ReactNode;
}) {
    const dispatch = useThunkDispatch();
    const navigate = useNavigate();
    const isLoggedInAsBranchManager = useSelector(selectIsLoggedInAsBranchManager);

    let link: string | undefined;
    let beforeRedirectAction: (() => Promise<void>) | undefined;
    let isDownload = false;

    if (props.notification.notificationType === 'orderWasCreated') {
        link = `/webshops/orders/${props.notification.parameters['%_SALES_CHANNEL_ID_%']}/${props.notification.parameters['%_ORDER_ID_%']}`;
        if (props.notification.parameters['%_SALES_CHANNEL_ID_%'].startsWith('device-')) {
            link = `/vending-machines/orders/${props.notification.parameters['%_SALES_CHANNEL_ID_%']}/${props.notification.parameters['%_ORDER_ID_%']}`;
        }
    } else if (props.notification.notificationType === 'orderWasBackOrdered') {
        link = `/webshops/orders/${props.notification.parameters['%_SALES_CHANNEL_ID_%']}/${props.notification.parameters['%_ORDER_ID_%']}`;
    } else if (
        props.notification.notificationType === 'configuredIntegrationWasActivated' ||
        props.notification.notificationType === 'configuredIntegrationWasDeactivated' ||
        props.notification.notificationType === 'configuredIntegrationWasUpdated'
    ) {
        link = `/integrations/${props.notification.parameters['%_INTEGRATION_ID_%']}`;
    } else if (props.notification.notificationType === 'scheduleWasUpdated') {
        link = `/presentations/schedules/${props.notification.parameters['%_SCHEDULE_ID_%']}`;
    } else if (props.notification.notificationType === 'branchSlideshowWasUpdated') {
        link = `/presentations/slideshows/${props.notification.parameters['%_SLIDESHOW_ID_%']}`;
    } else if (props.notification.notificationType === 'companySlideshowWasUpdated') {
        if (!isLoggedInAsBranchManager) {
            link = `/presentations/slideshows/${props.notification.parameters['%_SLIDESHOW_ID_%']}`;
        } else {
            link = `/presentations/slideshows?q=${Infrastructure.Api.encodeAndEscapeURIComponent(
                props.notification.parameters['%_SLIDESHOW_NAME_%'],
            )}&f=%7B"includeShared"%3A"true"%7D`;
        }
    } else if (props.notification.notificationType === 'branchCustomPageContentWasUpdated') {
        link = `/presentations/custom-pages/${props.notification.parameters['%_CUSTOM_PAGE_ID_%']}`;
    } else if (props.notification.notificationType === 'companyCustomPageContentWasUpdated') {
        if (!isLoggedInAsBranchManager) {
            link = `/presentations/custom-pages/${props.notification.parameters['%_CUSTOM_PAGE_ID_%']}`;
        } else {
            link = `/presentations/custom-pages?q=${Infrastructure.Api.encodeAndEscapeURIComponent(
                props.notification.parameters['%_CUSTOM_PAGE_ID_%'],
            )}&f=%7B"includeShared"%3A"true"%7D`;
        }
    } else if (props.notification.notificationType === 'customPageWasShared') {
        link = '/presentations/custom-pages?f=%7B"includeShared"%3A"true"%7D&s=sharedOn&d=descending';
    } else if (props.notification.notificationType === 'branchProductWallContentWasUpdated') {
        link = `/presentations/product-walls/${props.notification.parameters['%_PRODUCT_WALL_ID_%']}`;
    } else if (props.notification.notificationType === 'companyProductWallContentWasUpdated') {
        if (!isLoggedInAsBranchManager) {
            link = `/presentations/product-walls/${props.notification.parameters['%_PRODUCT_WALL_ID_%']}`;
        } else {
            link = `/presentations/product-walls?q=${Infrastructure.Api.encodeAndEscapeURIComponent(
                props.notification.parameters['%_PRODUCT_WALL_ID_%'],
            )}&f=%7B"includeShared"%3A"true"%7D`;
        }
    } else if (
        props.notification.notificationType === 'productsOutOfStock' ||
        props.notification.notificationType === 'productsBackInStock' ||
        props.notification.notificationType === 'importedPriceWasUpdated'
    ) {
        link = '/products/available-products/';
    } else if (
        props.notification.notificationType === 'webshopWasUpdated' ||
        props.notification.notificationType === 'webshopConfigWasUpdated'
    ) {
        link = `/webshops/overview/${props.notification.parameters['%_WEBSHOP_ID_%']}`;
    } else if (props.notification.notificationType === 'productSelectionStatusWasUpdated') {
        link = '/products/selection/inStoreProductSelection';
    } else if (props.notification.notificationType === 'productWallWasShared') {
        link = '/presentations/product-walls?f=%7B"includeShared"%3A"true"%7D&s=sharedOn&d=descending';
    } else if (props.notification.notificationType === 'slideshowWasShared') {
        link = '/presentations/slideshows?f=%7B"includeShared"%3A"true"%7D&s=sharedOn&d=descending';
    } else if (props.notification.notificationType === 'orderBackOrderStatusWasUpdated') {
        link = `/webshops/orders/${props.notification.parameters['%_SALES_CHANNEL_ID_%']}/${props.notification.parameters['%_ORDER_ID_%']}`;
    } else if (props.notification.notificationType === 'vendingMachineHomeScreenProductWasRemoved') {
        link = '/vending-machines/home-screen-products?highlightedProductIds=' + props.notification.parameters['%_PRODUCT_IDS_%'].join(',');
        const deviceId = props.notification.parameters['%_DEVICE_ID_%'];

        beforeRedirectAction = async () => {
            await dispatch(Device.globalSelectionState.SetGlobalSelectedDeviceId(deviceId));
        };
    } else if (props.notification.notificationType === 'lockerLeftUnlocked') {
        link = `/customers/devices/${props.notification.parameters['%_DEVICE_ID_%']}`;
    } else if (props.notification.notificationType === 'shipmentServiceWebhookDataWasUpdated') {
        link = `/webshops/shipments/${props.notification.parameters['%_SALES_CHANNEL_ID_%']}/${props.notification.parameters['%_SHIPMENT_ID_%']}`;
    } else if (props.notification.notificationType === 'invoiceWasNumbered') {
        link = '/invoices';
    } else if (props.notification.notificationType === 'libraryArticleWasCreated') {
        link = `/webshops/news-article-library/${props.notification.parameters['%_ARTICLE_ID_%']}`;
    } else if (props.notification.notificationType === 'reservationAddToRobotFailed') {
        const reservationId = props.notification.parameters['%_RESERVATION_ID_%'] || props.notification.parameters['%_PICKUP_ID_%'];
        link = `/reservations/${reservationId}`;
    } else if (props.notification.notificationType === 'exportWasCompleted') {
        link = exportApi.GetExportDownloadURL(props.notification.parameters['%_EXPORT_ID_%']);
        isDownload = true;
    } else if (props.notification.notificationType === 'prescriptionWasUploaded') {
        link = `/webshops/prescriptions/${props.notification.parameters['%_PRESCRIPTION_ID_%']}`;
    } else if (props.notification.notificationType === 'userWasSubscribedToNewsletter') {
        link = '/webshops/newsletter-subscriptions';
    } else if (props.notification.notificationType === 'mediaWasShared') {
        link = '/media?s=sharedOn&d=descending';
    } else if (props.notification.notificationType === 'productExportWasCompleted') {
        link = '/products/product-export';
    } else if (props.notification.notificationType === 'productStockSubscriptionWasAdded') {
        link = `/products/available-products/${props.notification.parameters['%_PRODUCT_ID_%']}`;
    } else if (
        props.notification.notificationType === 'appointmentWasCanceled' ||
        props.notification.notificationType === 'appointmentWasCreated'
    ) {
        link = `/webshops/appointments/details/${props.notification.parameters['%_APPOINTMENT_ID_%']}`;
    } else if (props.notification.notificationType === 'lockerHasProductsThatCannotBeSold') {
        link = `/reservations/${props.notification.parameters['%_PICKUP_ID_%']}`;
    }

    return (
        <a
            className={`${props.className} ${!link ? 'no-link' : ''}`}
            href={!isDownload ? '#' : link}
            target={isDownload ? '_blank' : '_self'}
            onClick={async e => {
                if (isDownload) {
                    if (props.onClick) {
                        await props.onClick();
                    }

                    if (beforeRedirectAction) {
                        await beforeRedirectAction();
                    }

                    return;
                }

                e.preventDefault();

                if (!link) {
                    return;
                }

                if (props.onClick) {
                    await props.onClick();
                }

                if (beforeRedirectAction) {
                    await beforeRedirectAction();
                }

                if (link !== undefined) {
                    navigate(link);
                }
            }}
        >
            {props.children}
        </a>
    );
}
