import { Address } from './Address';
import { LocalizedValue, Country } from './Localisation';
import { PriceWithLegacyCurrency, Price } from './Price';

export const WebshopShippingMethodTypes = [
    'clickAndCollect',
    'pickup',
    'locker',
    'localDelivery',
    'serviceDelivery',
    'doorDashDelivery',
] as const;
export type WebshopShippingMethodType = (typeof WebshopShippingMethodTypes)[number];

export type WebshopShippingMethodActiveStatus = 'active' | 'inactive';

export type WebshopPickupShippingMethodLocationBase = {
    name: string;
};

export type WebshopPickupShippingMethodLocationWithAddress = WebshopPickupShippingMethodLocationBase & {
    address?: Address;
};

export type WebshopPickupShippingMethodLocationWithBranch = WebshopPickupShippingMethodLocationBase & {
    branchId: string;
};

export type WebshopPickupShippingMethodLocation =
    | WebshopPickupShippingMethodLocationWithAddress
    | WebshopPickupShippingMethodLocationWithBranch;

export function isWebshopPickupShippingMethodLocationWithAddress(
    method: WebshopPickupShippingMethodLocation,
): method is WebshopPickupShippingMethodLocationWithAddress {
    return method && method.hasOwnProperty('address') && !!(method as WebshopPickupShippingMethodLocationWithAddress).address;
}

export function isWebshopPickupShippingMethodLocationWithBranch(
    method: WebshopPickupShippingMethodLocation,
): method is WebshopPickupShippingMethodLocationWithBranch {
    return method && method.hasOwnProperty('branchId') && !!(method as WebshopPickupShippingMethodLocationWithBranch).branchId;
}

export interface WebshopPickupShippingMethod {
    name: LocalizedValue;
    locations?: WebshopPickupShippingMethodLocation[] | null;
    activeStatus: WebshopShippingMethodActiveStatus;
}

export type UpdateWebshopPickupShippingMethodPayload = {
    webshopId: string;
} & Omit<WebshopPickupShippingMethod, 'activeStatus'>;

export interface UpdateWebshopPickupShippingMethodActiveStatusPayload {
    webshopId: string;
    activeStatus: WebshopShippingMethodActiveStatus;
}

export interface WebshopLockerShippingMethod {
    name: LocalizedValue;
    fixedShippingCost: PriceWithLegacyCurrency | Price;
    freeShippingStartingFrom?: PriceWithLegacyCurrency | Price | null;
    activeStatus: WebshopShippingMethodActiveStatus;
}

export type UpdateWebshopLockerShippingMethodPayload = {
    webshopId: string;
    fixedShippingCost: number;
    freeShippingStartingFrom?: number | null;
} & Omit<WebshopLockerShippingMethod, 'activeStatus' | 'fixedShippingCost' | 'freeShippingStartingFrom'>;

export interface UpdateWebshopLockerShippingMethodActiveStatusPayload {
    webshopId: string;
    activeStatus: WebshopShippingMethodActiveStatus;
}

export interface WebshopLocalDeliveryShippingMethod {
    name: LocalizedValue;
    fixedShippingCost?: PriceWithLegacyCurrency | Price | null;
    freeShippingStartingFrom?: PriceWithLegacyCurrency | Price | null;
    zipCodes?: string[] | null;
    activeStatus: WebshopShippingMethodActiveStatus;
}

export type UpdateWebshopLocalDeliveryShippingMethodPayload = {
    webshopId: string;
    fixedShippingCost: number;
    freeShippingStartingFrom?: number | null;
} & Omit<WebshopLocalDeliveryShippingMethod, 'activeStatus' | 'fixedShippingCost' | 'freeShippingStartingFrom'>;

export interface UpdateWebshopLocalDeliveryShippingMethodActiveStatusPayload {
    webshopId: string;
    activeStatus: WebshopShippingMethodActiveStatus;
}

export type WebshopServiceDeliveryShippingCost = {
    fixedShippingCost: Price;
    freeShippingStartingFrom?: Price | null;
    allowsShipping: boolean;
    shippingCountry: Country;
};
export interface WebshopServiceDeliveryShippingMethod {
    name: LocalizedValue;
    activeStatus: WebshopShippingMethodActiveStatus;
    shippingCosts: WebshopServiceDeliveryShippingCost[];
}

export type UpdateServiceDeliveryShippingConfigPayload = {
    webshopId: string;
    shippingCosts: {
        fixedShippingCost: number;
        freeShippingStartingFrom?: number | null;
        allowsShipping: boolean;
        shippingCountry: Country;
    }[];
} & Omit<WebshopServiceDeliveryShippingMethod, 'activeStatus' | 'fixedShippingCost' | 'freeShippingStartingFrom' | 'shippingCosts'>;

export interface UpdateWebshopServiceDeliveryShippingMethodActiveStatusPayload {
    webshopId: string;
    activeStatus: WebshopShippingMethodActiveStatus;
}

export type WebshopShippingMethodDetails =
    | WebshopPickupShippingMethod
    | WebshopLockerShippingMethod
    | WebshopLocalDeliveryShippingMethod
    | WebshopServiceDeliveryShippingMethod;
export type WebshopShippingMethodDetailsList = (
    | ({
          type: 'pickup';
      } & WebshopPickupShippingMethod)
    | ({
          type: 'locker';
      } & WebshopLockerShippingMethod)
    | ({
          type: 'localDelivery';
      } & WebshopLocalDeliveryShippingMethod)
    | ({
          type: 'serviceDelivery';
      } & WebshopServiceDeliveryShippingMethod)
)[];
