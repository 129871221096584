import * as React from 'react';
import { useSelector } from 'react-redux';

import { Domain } from 'api';
import { Authentication, getLanguageCodeFromLocale } from 'data-store';
import { Dropdown, Card, Menu, Button, CircleOutlineIcon } from 'ui-components';

import { getHeaderLanguageSwitcherMenuItems } from '@/Infrastructure/languageSwitcherUtils';

export default function LanguageSwitcher(props: {
    selectedLocale: Domain.Locale;
    onPick: (locale: Domain.Locale) => void;
    className?: string;
    headerRenderer?: () => React.ReactNode;
    anchorLeft?: boolean;
}) {
    const dropdown = React.createRef<Dropdown>();

    const availableLocales = useSelector(Authentication.selectLoggedInUserLocales);
    const menuItems = getHeaderLanguageSwitcherMenuItems(availableLocales, props.onPick, dropdown);

    return (
        <Dropdown
            ref={dropdown}
            className={props.className}
            anchorRight={!props.anchorLeft}
            headerRenderer={
                props.headerRenderer
                    ? props.headerRenderer
                    : () => (
                          <Button
                              variant="secondary"
                              variantSize="xs"
                              startIcon={
                                  <CircleOutlineIcon>{getLanguageCodeFromLocale(props.selectedLocale).toUpperCase()}</CircleOutlineIcon>
                              }
                              data-test-id="header-language-switcher"
                          />
                      )
            }
            body={
                <Card
                    hSpacing="none"
                    vSpacing="none"
                    elevated={true}
                    className="mt-7"
                >
                    <Menu
                        selectedItems={[props.selectedLocale]}
                        items={menuItems}
                        leftSubMenus={!props.anchorLeft}
                    />
                </Card>
            }
        />
    );
}
