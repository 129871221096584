import moment from 'moment';

import { Domain } from 'api';

export function validateOpeningHoursIntervals(intervals: Domain.OpeningHoursDay, timeFormat: string, index: number | null = null) {
    if (intervals.length > 0) {
        if (index === null) {
            for (const interval of intervals) {
                const hasError = validateOpeningHoursInterval(interval, timeFormat);

                if (hasError) {
                    return true;
                }
            }
        } else {
            const hasError = validateOpeningHoursInterval(intervals[index], timeFormat);

            if (hasError) {
                return true;
            }
        }

        if (index !== null && index > 0) {
            if (!checkIntervalsAreTimeConsecutive(intervals, timeFormat, index)) {
                return true;
            }
        } else if (index === null) {
            if (validateOpeningHoursIntervalHaveOverlaps(intervals, timeFormat)) {
                return true;
            }
        }
    }

    return false;
}

export function validateOpeningHoursInterval(interval: Domain.OpeningHoursInterval, timeFormat: string) {
    return !interval.from || !interval.till || moment(interval.from, timeFormat).isAfter(moment(interval.till, timeFormat));
}

export function validateOpeningHoursIntervalHaveOverlaps(intervals: Domain.OpeningHoursInterval[], timeFormat: string) {
    for (let intervalIndex = 1; intervalIndex < intervals.length; intervalIndex++) {
        if (!checkIntervalsAreTimeConsecutive(intervals, timeFormat, intervalIndex)) {
            return true;
        }
    }

    return false;
}

export function checkIntervalsAreTimeConsecutive(intervals: Domain.OpeningHoursDay, timeFormat: string, index: number) {
    if (
        intervals[index - 1].till &&
        intervals[index].from &&
        moment(intervals[index - 1].till, timeFormat).isAfter(moment(intervals[index].from, timeFormat))
    ) {
        return false;
    }

    return true;
}
