import {
    TimeFrameComparison,
    FraankAPIDataPointList,
    FraankAPIDataPoint,
    FraankSalesChannelData,
    FraankSalesChannel,
    FraankAPITop,
    FrankMainDataAggregation,
} from '@/Domain';

import Endpoint from './Endpoint';

const apiDateFormat = 'YYYY-MM-DD';

type Granularity = 'hourly' | 'monthly' | 'yearly' | 'weekdayHourly';

export default class FraankEndpoint extends Endpoint {
    public async GetSalesChannels(): Promise<FraankSalesChannel[]> {
        const data = await this.client.get({
            url: '/fraank/sales-channel/overview',
            params: {
                pagination: {
                    page: 1,
                    size: 1000,
                },
            },
        });

        this.guardResponseSchema('#/definitions/FraankSalesChannelsPage', data);

        return data.items;
    }

    public async GetSalesChannelData(
        timeFrame: TimeFrameComparison,
        granularity: 'day' | 'month' | 'year',
        mainDataAggregation: FrankMainDataAggregation,
        filters?: {
            salesChannelIds?: string;
            tags?: string;
        },
        compareToFilters?: {
            salesChannelIds?: string;
            tags?: string;
        },
    ): Promise<FraankSalesChannelData> {
        const GRANULARITY_MAP = {
            day: 'daily' as Granularity,
            month: 'monthly' as Granularity,
            year: 'yearly' as Granularity,
            'weekday-hour': 'weekdayHourly' as Granularity,
        };

        let metricsData: FraankAPIDataPointList = [];
        let popularHoursData: FraankAPIDataPointList = [];
        let topData: FraankAPITop = {
            products: [],
            brands: [],
            organisations: [],
            categories: [],
            searches: [],
        };

        let compareToMetricsData: FraankAPIDataPointList | undefined = undefined;
        let compareToPopularHoursData: FraankAPIDataPointList | undefined = undefined;
        let compareToTopData: FraankAPITop | undefined = undefined;

        if (
            filters &&
            (filters.salesChannelIds === undefined || filters.salesChannelIds === '') &&
            (filters.tags === undefined || filters.tags === '')
        ) {
            return {
                metricsData,
                popularHoursData,
                topData,
                compareToMetricsData,
                compareToPopularHoursData,
                compareToTopData,
            };
        }

        try {
            const promises: Promise<any>[] = [];

            promises.push(
                this.client.get({
                    url: `/fraank/metrics/${timeFrame.from.format(apiDateFormat)}/${timeFrame.to.format(apiDateFormat)}/${GRANULARITY_MAP[granularity]}/${mainDataAggregation}`,
                    params: { filters },
                }),
            );

            promises.push(
                this.client.get({
                    url: `/fraank/metrics/${timeFrame.from.format(apiDateFormat)}/${timeFrame.to.format(apiDateFormat)}/weekdayHourly/${mainDataAggregation}`,
                    params: { filters },
                }),
            );

            promises.push(
                this.client.get({
                    url: `/fraank/top/${timeFrame.from.format(apiDateFormat)}/${timeFrame.to.format(apiDateFormat)}`,
                    params: { filters, pagination: { page: 1, size: 100 } },
                }),
            );

            if (timeFrame.with === 'self') {
                promises.push(
                    this.client.get({
                        url: `/fraank/metrics/${timeFrame.compareFrom.format(apiDateFormat)}/${timeFrame.compareTo.format(apiDateFormat)}/${GRANULARITY_MAP[granularity]}/average`,
                        params: { filters },
                    }),
                );

                promises.push(
                    this.client.get({
                        url: `/fraank/metrics/${timeFrame.compareFrom.format(apiDateFormat)}/${timeFrame.compareTo.format(apiDateFormat)}/weekdayHourly/average`,
                        params: { filters },
                    }),
                );

                promises.push(
                    this.client.get({
                        url: `/fraank/top/${timeFrame.compareFrom.format(apiDateFormat)}/${timeFrame.compareTo.format(apiDateFormat)}`,
                        params: { filters, pagination: { page: 1, size: 100 } },
                    }),
                );
            } else if (timeFrame.with !== 'nothing') {
                promises.push(
                    this.client.get({
                        url: `/fraank/metrics/${timeFrame.from.format(apiDateFormat)}/${timeFrame.to.format(apiDateFormat)}/${GRANULARITY_MAP[granularity]}/average`,
                        params: {
                            filters: compareToFilters,
                        },
                    }),
                );

                promises.push(
                    this.client.get({
                        url: `/fraank/metrics/${timeFrame.from.format(apiDateFormat)}/${timeFrame.to.format(apiDateFormat)}/weekdayHourly/average`,
                        params: {
                            filters: compareToFilters,
                        },
                    }),
                );

                promises.push(
                    this.client.get({
                        url: `/fraank/top/${timeFrame.from.format(apiDateFormat)}/${timeFrame.to.format(apiDateFormat)}`,
                        params: {
                            filters: compareToFilters,
                            pagination: { page: 1, size: 100 },
                        },
                    }),
                );
            }

            const data = await Promise.all(promises);

            metricsData = data[0];
            popularHoursData = data[1];
            topData = data[2];

            if (timeFrame.with !== 'nothing') {
                compareToMetricsData = data[3];
                compareToPopularHoursData = data[4];
                compareToTopData = data[5];
            }
        } catch (e) {
            // ignore
        }

        this.guardResponseSchema('#/definitions/FraankAPIDataPointList', metricsData);
        this.guardResponseSchema('#/definitions/FraankAPIDataPointList', popularHoursData);
        this.guardResponseSchema('#/definitions/FraankAPITop', topData);

        metricsData.sort(this.sortDataFn);

        const weekDaysToISO = (data: FraankAPIDataPointList): FraankAPIDataPointList => {
            return data.map(item => {
                if (item.weekDay !== undefined && item.weekDay !== null) {
                    return {
                        ...item,
                        weekDay: item.weekDay === 0 ? 7 : item.weekDay,
                    };
                }
                return item;
            });
        };

        popularHoursData = weekDaysToISO(popularHoursData);

        if (compareToMetricsData) {
            this.guardResponseSchema('#/definitions/FraankAPIDataPointList', compareToMetricsData);
            compareToMetricsData.sort(this.sortDataFn);
        }

        if (compareToPopularHoursData) {
            this.guardResponseSchema('#/definitions/FraankAPIDataPointList', compareToPopularHoursData);
            compareToPopularHoursData = weekDaysToISO(compareToPopularHoursData);
        }

        if (compareToTopData) {
            this.guardResponseSchema('#/definitions/FraankAPITop', compareToTopData);
        }

        return {
            metricsData,
            popularHoursData,
            topData,
            compareToMetricsData,
            compareToPopularHoursData,
            compareToTopData,
        };
    }

    private sortDataFn = (a: FraankAPIDataPoint, b: FraankAPIDataPoint) => {
        const ax = (a.year || 0) * 10000 + (a.month || 0) * 100 + (a.day || 0) * 10 + (a.hour || 0);
        const bx = (b.year || 0) * 10000 + (b.month || 0) * 100 + (b.day || 0) * 10 + (b.hour || 0);

        return ax - bx;
    };
}
