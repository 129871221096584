import { Domain } from 'api';

import { ThunkAction } from '@/action';
import { productFeedbackApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';
import { URLParams, URLQuery } from '@/routing';
import { RootState } from '@/store';

export type CreatedProductFeedbackItemProps = keyof Domain.ProductFeedbackItem | 'productCodes';

export const defaultSorting: Domain.Sorting<CreatedProductFeedbackItemProps> = {
    field: 'createdAt',
    direction: 'descending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.ProductFeedbackItem, CreatedProductFeedbackItemProps>({
    getState: rootState => rootState.productFeedback.overview,
});

export const reducerActions = makeReducerActions<Domain.ProductFeedbackItem, CreatedProductFeedbackItemProps>({
    reducerPrefix: '@productFeedback/overview',
});
export const maybeSelectCurrentPage = (rootState: RootState) => {
    let currentPage;
    try {
        currentPage = selectors.maybeSelectCurrentPage(rootState);
    } catch (e) {
        console.log(e);
    }
    return currentPage;
};
export const baseActions = makeActions<Domain.ProductFeedbackItem, CreatedProductFeedbackItemProps>({
    dataTableSaveKey: 'productFeedbackItemsOverview-v5',
    loadApi: options => {
        if (options.ownership.type === 'company') {
            return productFeedbackApi.GetCompanyProductFeedbackItems(options.pagination, options.filters);
        }

        if (options.ownership.type === 'branch') {
            return productFeedbackApi.GetBranchProductFeedbackItems(options.pagination, options.filters);
        }

        return productFeedbackApi.GetProductFeedbackItems(options.pagination, options.filters);
    },
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/products/product-feedback',
    reducerActions,
    selectors,
});

export const actions = {
    ...baseActions,
    load:
        (options: { urlParams: URLParams; urlQuery: URLQuery }): ThunkAction =>
        async dispatch => {
            try {
                await Promise.all([dispatch(baseActions.load(options))]);
            } catch (e) {
                console.log(e);
            }
        },
};

export const overviewReducer = makeReducer<Domain.ProductFeedbackItem, CreatedProductFeedbackItemProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
