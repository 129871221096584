import { createAction, createReducer, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';
import { browserStorage } from 'utils';

import { ThunkAction, withPayloadType } from '@/action';
import { deviceApi } from '@/api';
import { maybeSelectLoggedInUser, selectLoggedInUserOwnership, selectLoggedInUserOwnershipIds } from '@/Authentication';
import { RootState } from '@/store';

const VENDING_MACHINE_DEVICE_GLOBAL_SELECTION_SAVE_KEY = 'vendingMachineDeviceGlobalSelection-v2';

export interface State {
    device?: Domain.DeviceDetails;
    allDevices: Domain.Device[];
}

const initialState: State = {
    allDevices: [],
};

const reducerActions = {
    setAllDevices: createAction('@device/globalSelection/setAllDevices', withPayloadType<Domain.Device[]>()),
    setDevice: createAction('@device/globalSelection/setDevice', withPayloadType<Domain.DeviceDetails | undefined>()),
};

export const globalSelectionReducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.setAllDevices, (state, action) => {
            state.allDevices = action.payload;
        })
        .addCase(reducerActions.setDevice, (state, action) => {
            state.device = action.payload;
        }),
);

export const selectHasVendingMachineDevices: Selector<RootState, boolean> = state => {
    return state.device.globalSelection.allDevices.filter(device => device.type === 'vending-machine').length > 0;
};

export const selectHasVisionDevices: Selector<RootState, boolean> = state => {
    return (
        state.device.globalSelection.allDevices.filter(device => device.type === 'tv-screen' || device.type === 'interactive-tv-screen')
            .length > 0
    );
};

export const selectHasCollectDevices: Selector<RootState, boolean> = state => {
    return (
        state.device.globalSelection.allDevices.filter(device => device.type === 'vending-machine' && device.connectedToLockers).length > 0
    );
};

export const selectHasCollectDevicesWithBacksideStocking: Selector<RootState, boolean> = state => {
    return (
        state.device.globalSelection.allDevices.filter(
            device => device.type === 'vending-machine' && device.connectedToLockers && device.backsideStockingIsEnabled,
        ).length > 0
    );
};

export const selectAllDevices: Selector<RootState, Domain.Device[]> = state => {
    return state.device.globalSelection.allDevices;
};

export const selectGlobalSelectedDevice: Selector<RootState, Domain.DeviceDetails | undefined> = state => {
    return state.device.globalSelection.device;
};

export const selectGlobalSelectedDeviceId: Selector<RootState, Domain.Device['deviceId'] | undefined> = state => {
    return state.device.globalSelection.device ? state.device.globalSelection.device.deviceId : undefined;
};

export const InitGlobalSelectedDeviceId = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();

    const userIsLoggedIn = maybeSelectLoggedInUser(state);
    if (!userIsLoggedIn) {
        return;
    }

    let allDevices = selectAllDevices(state);
    if (allDevices.length === 0) {
        const ownership = selectLoggedInUserOwnership(state);
        const results = await deviceApi.GetDevices(
            ownership,
            {
                page: 1,
                size: 30,
            },
            {
                field: 'name',
                direction: 'ascending',
            },
        );

        allDevices = results.items;
        dispatch(reducerActions.setAllDevices(allDevices));
    }

    const alreadyLoaded = selectGlobalSelectedDevice(state);
    if (alreadyLoaded) {
        return;
    }

    const ownershipIds = selectLoggedInUserOwnershipIds(state);
    const vendingMachineStateSaveKey =
        VENDING_MACHINE_DEVICE_GLOBAL_SELECTION_SAVE_KEY + '-' + ownershipIds.companyId + '-' + ownershipIds.branchId;
    const vendingMachineSavedDeviceId = browserStorage.getItem(vendingMachineStateSaveKey);

    let loadedFromExistingVendingMachineDeviceId = false;
    if (vendingMachineSavedDeviceId && vendingMachineSavedDeviceId.value) {
        try {
            const device = await deviceApi.GetDeviceDetails(vendingMachineSavedDeviceId.value);
            dispatch(reducerActions.setDevice(device));
            loadedFromExistingVendingMachineDeviceId = true;
        } catch (e) {
            browserStorage.removeItem(vendingMachineStateSaveKey);
        }
    }

    if (!loadedFromExistingVendingMachineDeviceId) {
        if (allDevices.length > 0) {
            const vendingMachineDevices = allDevices.filter(device => device.type === 'vending-machine');

            if (vendingMachineDevices.length > 0) {
                const vendingMachineDevice = vendingMachineDevices[0];
                browserStorage.setItem(vendingMachineStateSaveKey, vendingMachineDevice.deviceId);

                dispatch(reducerActions.setDevice(await deviceApi.GetDeviceDetails(vendingMachineDevice.deviceId)));
            }
        }
    }
};

export const ReloadGlobalSelectedDevice = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const deviceId = selectGlobalSelectedDeviceId(state);

    if (deviceId) {
        const device = await deviceApi.GetDeviceDetails(deviceId);
        dispatch(reducerActions.setDevice(device));
    }
};

export const SelectMTCollectDevice = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const collect = state.device.globalSelection.allDevices.find(device => device.type === 'vending-machine' && device.connectedToLockers);

    if (!collect) {
        throw new Error('No MT.Collect Device available');
    }

    const device = await deviceApi.GetDeviceDetails(collect.deviceId);
    dispatch(reducerActions.setDevice(device));
};

export const ClearGlobalSelectedDevice = (): ThunkAction => async dispatch => {
    await dispatch(reducerActions.setDevice(undefined));
    await dispatch(reducerActions.setAllDevices([]));
};

export const SetGlobalSelectedDeviceId =
    (deviceId: Domain.Device['deviceId']): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const userIsLoggedIn = maybeSelectLoggedInUser(state);
        if (!userIsLoggedIn) {
            return;
        }

        const ownershipIds = selectLoggedInUserOwnershipIds(state);
        const stateSaveKey = VENDING_MACHINE_DEVICE_GLOBAL_SELECTION_SAVE_KEY + '-' + ownershipIds.companyId + '-' + ownershipIds.branchId;

        browserStorage.setItem(stateSaveKey, deviceId);
        const device = await deviceApi.GetDeviceDetails(deviceId);
        dispatch(reducerActions.setDevice(device));
    };
