import * as React from 'react';

import styles from './Modal.scss';

export default function ModalBody({
    style,
    overflowScroll,
    children,
}: {
    style?: React.CSSProperties;
    overflowScroll?: boolean;
    children?: React.ReactNode;
}) {
    return (
        <div
            className={styles.ModalBody}
            style={{
                ...style,
                overflow: overflowScroll ? 'auto' : 'visible',
            }}
        >
            {children}
        </div>
    );
}
