import { Domain } from 'api';
import { ISearchProvider } from 'utils';

import { productSelectionApi } from '@/api';

export interface BaseProductSearchProviderItem {
    value: string;
    label: React.ReactNode;
    productCodes: Domain.ProductCodes;
    localizedNames: Domain.LocalizedValue;
    productType: Domain.SelectedProductType;
}

type ProductMapper = (item: Domain.AvailableProduct | Domain.CustomProduct, locale: Domain.Locale) => BaseProductSearchProviderItem;
interface Ownership {
    branchId?: string;
    companyId?: string;
}

export abstract class BaseProductSearchProvider<TSearchPage> implements ISearchProvider<BaseProductSearchProviderItem> {
    protected ownership: Ownership | undefined;
    protected locale: Domain.Locale;
    private productMapper: ProductMapper;

    public searchCache: {
        [key: string]: Promise<TSearchPage>;
    } = {};
    protected byValueCache: {
        [key: string]: Promise<Domain.AvailableProduct | Domain.CustomProduct>;
    } = {};
    protected byValuesCache: {
        [key: string]: Promise<Domain.BulkProductDetailsList>;
    } = {};

    constructor(
        productMapper: (item: Domain.AvailableProduct | Domain.CustomProduct, locale: Domain.Locale) => BaseProductSearchProviderItem,
    ) {
        this.productMapper = productMapper;
    }

    resetCache() {
        this.searchCache = {};
        this.byValueCache = {};
        this.byValuesCache = {};
    }

    reset() {
        this.resetCache();
    }

    resetByValueCacheFor(value: string) {
        delete this.byValueCache[value];
    }

    public setOwnership(ownership: Ownership) {
        if (JSON.stringify(this.ownership) === JSON.stringify(ownership)) {
            return;
        }
        this.ownership = ownership;
        this.resetCache();
    }

    public setLocale(locale: Domain.Locale) {
        if (this.locale === locale) {
            return;
        }
        this.locale = locale;
        this.resetCache();
    }

    public setProductMapper(
        productMapper: (item: Domain.AvailableProduct | Domain.CustomProduct, locale: Domain.Locale) => BaseProductSearchProviderItem,
    ) {
        this.productMapper = productMapper;
    }

    async loadMoreResults() {
        return [];
    }

    getHasMoreResults() {
        return false;
    }

    async byValue(value: string) {
        const item = await this.byValueUnmapped(value);
        if (item) {
            return this.mapItem(item);
        }
    }

    async byValueUnmapped(value: string) {
        if (!value) {
            return;
        }
        if (!this.byValueCache[value]) {
            this.byValueCache[value] = this.getDetailsByValue(value);
        }
        return await this.byValueCache[value];
    }

    async byValues(values: string[]) {
        if (!this.ownership || values.length === 0) {
            return [];
        }
        const cacheKey = values.join(',');
        if (!this.byValuesCache[cacheKey]) {
            this.byValuesCache[cacheKey] = this.batchedBulkGetProductDetails(values);
            this.byValuesCache[cacheKey].then(cachedProducts => {
                cachedProducts.forEach(product => {
                    if (!this.byValueCache[product.productId]) {
                        this.byValueCache[product.productId] = Promise.resolve(product);
                    }
                });
            });
        }
        const results = await this.byValuesCache[cacheKey];
        return results.map(this.mapItem);
    }

    protected mapItem = (item: Domain.AvailableProduct | Domain.CustomProduct): BaseProductSearchProviderItem => {
        return this.productMapper(item, this.locale);
    };

    private async batchedBulkGetProductDetails(productIds: string[]) {
        if (!this.ownership || !this.ownership.branchId || productIds.length === 0) {
            return Promise.resolve([]);
        }

        const promises: ReturnType<typeof productSelectionApi.BulkGetProductDetails>[] = [];
        const chunkSize = 150;
        for (let i = 0; i < productIds.length; i += chunkSize) {
            const chunk = productIds.slice(i, i + chunkSize);
            promises.push(productSelectionApi.BulkGetProductDetails(this.ownership.branchId, chunk));
        }

        const results = await Promise.all(promises);
        let groupedResults: Domain.BulkProductDetailsList = [];
        for (const chunk of results) {
            groupedResults = [...groupedResults, ...chunk];
        }

        return groupedResults;
    }

    protected abstract getDetailsByValue(value: string): Promise<Domain.AvailableProduct | Domain.CustomProduct>;
    public abstract search(query: string): Promise<BaseProductSearchProviderItem[]>;
}
