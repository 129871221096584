import * as React from 'react';
import { useSelector } from 'react-redux';

import { Domain } from 'api';
import { I18n, CmsItem } from 'data-store';
import { Icon } from 'ui-components';

import InfoCmsItemModal from './InfoCmsItemModal';

import styles from './InfoCmsItem.scss';

export default function InfoCmsItem({ type, locale }: { type: Domain.CmsItemType; locale?: Domain.Locale }) {
    const uiLocale = useSelector(I18n.selectCurrentLocale);
    const cmsItem = useSelector(CmsItem.infoItemsState.maybeSelectCmsItem(type, locale || uiLocale));
    const [isOpened, setIsOpened] = React.useState(false);

    if (!cmsItem) {
        return null;
    }

    return (
        <>
            <a
                href=""
                onClick={e => {
                    e.preventDefault();
                    setIsOpened(true);
                }}
                className={styles.InfoCmsItemAction}
            >
                <Icon
                    iconSize="s"
                    type="message_info"
                />
            </a>

            {isOpened ? (
                <InfoCmsItemModal
                    cmsItem={cmsItem}
                    onClose={() => setIsOpened(false)}
                />
            ) : null}
        </>
    );
}
