import { createAction, createReducer, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { scientificCategoryApi } from '@/api';
import { RootState } from '@/store';

export interface State {
    conditions?: Domain.OrderReviewConditions;
    scientificCategoryTrees?: Domain.ScientificCategory;
}

const initialState: State = {};

const reducerActions = {
    setScientificCategoryTrees: createAction(
        '@BranchCartRules/scientificCategories/setScientificCategoryTrees',
        withPayloadType<Domain.ScientificCategory>(),
    ),
};

export const selectScientificCategoryTrees: Selector<RootState, Domain.ScientificCategory | undefined> = state =>
    state.branchCartRules.scientificCategory.scientificCategoryTrees;

export const scientificCategoriesReducer = createReducer(initialState, builder =>
    builder.addCase(reducerActions.setScientificCategoryTrees, (state, action) => {
        state.scientificCategoryTrees = action.payload;
    }),
);

export const loadScientificCategoryTrees =
    (country: Domain.Country): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const scientificCategoriesSources = state.localisation.availableCountries.countries[country]
            ? state.localisation.availableCountries.countries[country].scientificCategories
            : undefined;
        if (scientificCategoriesSources) {
            const categories = await Promise.all(
                scientificCategoriesSources.map(source => {
                    return scientificCategoryApi.GetScientificCategoriesTree(source);
                }),
            );
            const concatenatedCategories = categories.reduce((acc, val) => ({
                ...acc,
                subCategories: acc.subCategories.concat(val.subCategories),
            }));
            await dispatch(reducerActions.setScientificCategoryTrees(concatenatedCategories));
        }
    };
