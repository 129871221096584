import {
    Pagination,
    Sorting,
    CompaniesPage,
    CompanyDetails,
    CreateCompanyPayload,
    UpdateCompanyPayload,
    AssignCompanyToResellerPayload,
    UpdateCompanyLandingPageConfigurationPayload,
    SharedCompanyContentType,
    SharedCompaniesByTypeResponse,
} from '@/Domain';

import Endpoint from './Endpoint';

export default class CompanyEndpoint extends Endpoint {
    public async GetCompanies(
        pagination: Pagination,
        sorting: Sorting,
        search?: string,
        filters?: {
            country?: string;
            city?: string;
            name?: string;
            resellerId?: string;
            type?: string;
            canUseSharingGroups?: boolean;
        },
    ): Promise<CompaniesPage> {
        const companies = await this.client.get({
            url: '/company/overview',
            params: {
                pagination,
                sorting,
                search,
                filters,
            },
        });

        this.guardResponseSchema('#/definitions/CompaniesPage', companies);

        return companies;
    }

    public async GetCompanyDetails(companyId: string): Promise<CompanyDetails> {
        const company = await this.client.get({
            url: '/company/' + companyId + '/details',
        });

        this.guardResponseSchema('#/definitions/Company', company);

        return company;
    }

    public async CreateCompany(payload: CreateCompanyPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/CreateCompany',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Company\\CreateCompany\\CreateCompany',
                payload,
            },
        });
    }

    public async UpdateCompany(payload: UpdateCompanyPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateCompany',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Company\\UpdateCompany\\UpdateCompany',
                payload,
            },
        });
    }

    public async RemoveCompany(companyId: string): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/RemoveCompany',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Company\\RemoveCompany\\RemoveCompany',
                payload: {
                    companyId,
                },
            },
        });
    }

    public GetCompaniesExportURL(exportType: 'csv' | 'xlsx'): string {
        return this.client.buildApiURL(`/company/export/${exportType}`, true);
    }

    public async AssignCompanyToReseller(payload: AssignCompanyToResellerPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/AssignCompanyToReseller',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Company\\AssignCompanyToReseller\\AssignCompanyToReseller',
                payload,
            },
        });
    }

    public async UpdateCompanyLandingPageConfiguration(payload: UpdateCompanyLandingPageConfigurationPayload): Promise<void> {
        await this.client.post({
            url: '/command/dispatch/UpdateLandingPageConfiguration',
            body: {
                commandName: 'App\\Domain\\WriteModel\\Company\\UpdateLandingPageConfiguration\\UpdateLandingPageConfiguration',
                payload,
            },
        });
    }
    public async GetSharedCompaniesByType(contentType: SharedCompanyContentType): Promise<SharedCompaniesByTypeResponse> {
        const response = await this.client.get({
            url: `/company/shared-content-company-ids/${contentType}`,
        });

        this.guardResponseSchema('#/definitions/SharedCompaniesByTypeResponse', response);

        return response;
    }
}
