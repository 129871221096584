import React from 'react';

import { TypographicStyleProps, TypographicLevel } from './types';

import './Typography.scss';

type SupportedComponentTypes = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div' | 'p' | 'label' | 'span' | 'a' | 'dl' | 'dd' | 'pre';

type AllowedHTMLAttributes<T> = Omit<React.HTMLAttributes<T>, 'level' | 'size'>;
type AllowedComponentTypeAndRestAttributesCombinations =
    | ({ as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' } & AllowedHTMLAttributes<HTMLElementTagNameMap['h1']>)
    | ({ as: 'div' } & AllowedHTMLAttributes<HTMLElementTagNameMap['div']>)
    | ({ as: 'p' } & AllowedHTMLAttributes<HTMLElementTagNameMap['p']>)
    | ({ as?: 'span' } & AllowedHTMLAttributes<HTMLElementTagNameMap['span']>)
    | ({ as?: 'label' } & AllowedHTMLAttributes<HTMLElementTagNameMap['label']>)
    | ({ as?: 'dl' } & AllowedHTMLAttributes<HTMLElementTagNameMap['dl']>)
    | ({ as?: 'dd' } & AllowedHTMLAttributes<HTMLElementTagNameMap['dd']>)
    | ({ as?: 'pre' } & AllowedHTMLAttributes<HTMLElementTagNameMap['pre']>)
    | ({ as: 'a' } & Omit<React.AnchorHTMLAttributes<HTMLElementTagNameMap['a']>, 'level' | 'size'>);

const LEVEL_MAPPING: Partial<Record<TypographicLevel, SupportedComponentTypes>> = {
    'display-1': 'h1',
    'display-2': 'h2',
    'display-3': 'h3',
    'display-4': 'h4',
    'heading-1': 'h1',
    'heading-2': 'h2',
    'heading-3': 'h3',
    'heading-4': 'h4',
    'heading-5': 'h5',
    'heading-6': 'h6',
    body: 'div',
};

type TypographyProps = {
    level?: TypographicLevel;
    sx?: TypographicStyleProps;
    className?: string;
} & AllowedComponentTypeAndRestAttributesCombinations;

export default function Typography({ level = 'body', sx = {}, className = '', style, children, as, ...rest }: TypographyProps) {
    const component: SupportedComponentTypes = as || LEVEL_MAPPING[level] || 'span';
    const weightClass = sx.weight ? `weight-${sx.weight}` : '';
    const alignClass = sx.align ? `align-${sx.align}` : 'align-left';
    const emphasizeClass = sx.emphasize ? 'emphasize' : '';
    const trimClass = sx.trim ? 'trim' : '';
    const computedStyle = {
        ...style,
        ...(sx.color ? { color: `var(${sx.color})` } : {}),
        ...(sx.transform ? { textTransform: sx.transform } : {}),
        ...(sx.disableSelection ? { userSelect: 'none' as const } : {}),
    };

    return React.createElement<TypographyProps>(
        component,
        {
            className: `typography ${level} ${weightClass} ${alignClass} ${emphasizeClass} ${trimClass} ${className}`,
            style: computedStyle,
            ...rest,
        },
        children,
    );
}
