import { createAction, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { stockSourceApi, stockReplenishApi } from '@/api';
import { selectLoggedInCompanyOrBranchManagerOwnershipIds } from '@/Authentication';
import * as categoriesTreeState from '@/AvailableProduct/categoriesTreeState';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';
import { URLParams, URLQuery } from '@/routing';
import { RootState } from '@/store';

type StockReplenishProps = keyof Domain.StockReplenishDetails | 'stockSourceId' | 'stockSource';

export const defaultSorting: Domain.Sorting<StockReplenishProps> = {
    field: 'name',
    direction: 'descending',
};

export const pageSize = 20;
export const setStockSources = createAction('@StockReplenish/overview/setStockSources', withPayloadType<Domain.StockSource[]>());
export const selectStockSources: Selector<RootState, Domain.StockSource[] | undefined> = state =>
    state.stockReplenish.overview.stockSources;

export const selectors = makeSelectors<Domain.StockReplenishDetails, StockReplenishProps>({
    getState: rootState => rootState.stockReplenish.overview,
});

export const reducerActions = makeReducerActions<Domain.StockReplenishDetails, StockReplenishProps>({
    reducerPrefix: '@StockReplenish/overview',
});

export const baseActions = makeActions<Domain.StockReplenishDetails, StockReplenishProps>({
    dataTableSaveKey: 'stockReplenishOverview-v1',
    loadApi: options => {
        return stockReplenishApi.GetStockReplenish(
            'robot',
            options.pagination,
            options.sorting,
            options.locale,
            options.search,
            options.filters,
        );
    },
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/vending-machines/stock-replenish',
    reducerActions,
    selectors,
});
export const loadStockSources =
    (companyId: string, branchId?: string): ThunkAction =>
    async dispatch => {
        const stockSources = await stockSourceApi.GetStockSources(companyId, branchId);
        if (stockSources) {
            await dispatch(setStockSources(stockSources));
        }
    };

export const actions = {
    ...baseActions,
    load:
        (options: { urlParams: URLParams; urlQuery: URLQuery }): ThunkAction =>
        async (dispatch, getState) => {
            const state = getState();

            const { branchId, companyId } = selectLoggedInCompanyOrBranchManagerOwnershipIds(state);
            await Promise.all([
                dispatch(baseActions.load(options)),
                dispatch(categoriesTreeState.actions.load(options)),
                dispatch(loadStockSources(companyId, branchId)),
            ]);
        },
};
export const overviewReducer = makeReducer<
    Domain.StockReplenishDetails,
    StockReplenishProps,
    {
        stockSources?: Domain.StockSource[];
    }
>({
    defaultSorting,
    pageSize,
    reducerActions,
    extraCases: builder => {
        builder.addCase(setStockSources, (state, action) => {
            state.stockSources = action.payload;
        });
    },
});
