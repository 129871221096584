import { createAction, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { deviceOrderApi, branchApi } from '@/api';
import { selectIsLoggedInAsCompanyOrBranchManager } from '@/Authentication';
import { loadConfiguredIntegrationsCountByType, loadConfiguredIntegrationsByType } from '@/Integration/configuredIntegrationsTypeState';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeDetailsState';
import { URLParams, URLQuery } from '@/routing';
import { RootState } from '@/store';

export const setBranch = createAction('@deviceOrder/details/setBranch', withPayloadType<Domain.Branch>());
export const selectBranch: Selector<RootState, Domain.Branch | undefined> = state => state.deviceOrder.details.branch;

export const selectors = makeSelectors<Domain.BaseOrderDetails>({
    getState: rootState => rootState.deviceOrder.details,
});

export const reducerActions = makeReducerActions<Domain.BaseOrderDetails>({
    reducerPrefix: '@deviceOrder/details',
});

export const baseActions = makeActions<Domain.BaseOrderDetails>({
    loadApi: options => deviceOrderApi.GetDeviceOrderDetails(options.urlParams.deviceId, options.urlParams.orderId),
    reducerActions,
    selectors,
});

export const detailsReducer = makeReducer<
    Domain.BaseOrderDetails,
    {
        branch?: Domain.Branch;
    }
>({
    reducerActions,
    extraCases: builder =>
        builder.addCase(setBranch, (state, action) => {
            state.branch = action.payload;
        }),
});

export const actions = {
    ...baseActions,
    load:
        (options: { urlParams: URLParams; urlQuery: URLQuery }): ThunkAction =>
        async (dispatch, getState) => {
            const order = await dispatch(baseActions.load(options));
            if (order) {
                const branch = await branchApi.GetBranchDetails(order.branchId);
                dispatch(setBranch(branch));

                const state = getState();

                if (selectIsLoggedInAsCompanyOrBranchManager(state)) {
                    await dispatch(
                        loadConfiguredIntegrationsByType('deliverPickups', {
                            type: 'branch',
                            ownerId: order.branchId,
                        }),
                    );
                }
            }
            await dispatch(loadConfiguredIntegrationsCountByType());
        },
};
