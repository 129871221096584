import { createAction, createReducer, Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { branchApi } from '@/api';
import { maybeSelectLoggedInUser, selectLoggedInUserOwnershipIds } from '@/Authentication';
import { RootState } from '@/store';

export interface State {
    branch?: Domain.BranchDetails;
}

const initialState: State = {};

const reducerActions = {
    setBranch: createAction('@branch/globalSelection/setDevice', withPayloadType<Domain.BranchDetails | undefined>()),
};

export const globalSelectionReducer = createReducer(initialState, builder =>
    builder.addCase(reducerActions.setBranch, (state, action) => {
        state.branch = action.payload;
    }),
);

export const selectGlobalStateBranch: Selector<RootState, Domain.BranchDetails | undefined> = state => {
    return state.branch.globalSelection.branch;
};
export const selectHasIdentifiedCustomersEnabled: Selector<RootState, boolean> = state => {
    return state.branch.globalSelection.branch?.configuration?.enableIdentifiedCustomers || false;
};

export const InitGlobalBranchDetails =
    (forceReload?: boolean): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();

        const userIsLoggedIn = maybeSelectLoggedInUser(state);
        if (!userIsLoggedIn) {
            return;
        }
        const ownershipIds = selectLoggedInUserOwnershipIds(state);
        if (!ownershipIds.branchId) {
            return;
        }
        const alreadyLoaded = selectGlobalStateBranch(state);
        if (alreadyLoaded && !forceReload) {
            return;
        }

        const branch = await branchApi.GetBranchDetails(ownershipIds.branchId);
        dispatch(reducerActions.setBranch(branch));
    };
