import moment from 'moment';

import { Infrastructure } from 'api';
import { DeviceLog } from 'logger';

import { ThunkAction } from '@/action';
import { authenticationApi, deviceApi } from '@/api';
import { InitGlobalBranchDetails } from '@/Branch/globalSelectionState';
import { loadDashboard } from '@/Dashboard';
import { InitGlobalSelectedDeviceId, ClearGlobalSelectedDevice } from '@/Device/globalSelectionState';
import { selectCurrentLocale, CheckLoggedInUserUILocale } from '@/I18n';
import { meditechDeviceApi } from '@/meditechDeviceApi';
import { PollUserUnreadNotificationsCount } from '@/PlatformNotification/actions';
import { InitGlobalSelectedWebshopId, ClearGlobalSelectedWebshop } from '@/Webshop/globalSelectionState';

import {
    clearJsonWebToken,
    setJsonWebToken,
    getValidLocalJsonWebToken,
    getValidOrExpiredLocalJsonWebToken,
    popAllImpersonatorJsonWebTokens,
    popImpersonatorJsonWebToken,
    pushImpersonatorJsonWebToken,
    clearDeviceSignalingJsonWebToken,
    setDeviceSignalingJsonWebToken,
    getValidLocalImpersonatorJsonWebToken,
    getValidLocalDeviceSignalingJsonWebToken,
    getImpersonatingDeviceWithMacAddress,
    clearDeviceFootprint,
} from './browserStorage';
import InvalidMacAddressError from './InvalidMacAddressError';
import { selectLoggedInUser } from './selectors';
import * as apiStateActions from './state';
import { AuthenticatedUser } from './types';

export const InitGlobalDetails = (): ThunkAction => async dispatch => {
    await Promise.all([
        dispatch(InitGlobalSelectedWebshopId()),
        dispatch(InitGlobalSelectedDeviceId()),
        dispatch(InitGlobalBranchDetails()),
    ]);
};
export const SignInFromSavedToken = (): ThunkAction => async dispatch => {
    const impersonateToken = getValidLocalImpersonatorJsonWebToken();

    if (impersonateToken) {
        await dispatch(SetApiToken(impersonateToken.token, impersonateToken.impersonatorToken.claims));
        return;
    }

    const token = getValidOrExpiredLocalJsonWebToken();

    if (token) {
        if (token.expiration > moment.utc().valueOf() / 1000) {
            await dispatch(SetApiToken(token));
            return;
        } else if (token.refreshToken && token.getRefreshTokenExpiryClaim() > moment.utc().valueOf() / 1000) {
            try {
                Infrastructure.Container.setConstant('apiToken', token.value);
                const freshToken = await authenticationApi.RefreshToken(token.refreshToken);
                try {
                    Infrastructure.Container.getConstant('apiToken');
                } catch (e) {
                    console.info('apiToken constant was not initialized');
                    Infrastructure.Container.setConstant('apiToken', null);
                }
                clearJsonWebToken();
                popAllImpersonatorJsonWebTokens();
                setJsonWebToken(freshToken);
                await dispatch(SetApiToken(freshToken));
                return;
            } catch (e) {
                console.error(e);
                // refresh failed
                clearJsonWebToken();
            }
        }
    }

    Infrastructure.Container.setConstant('apiToken', null);
};

export const SignIn =
    (emailAddress: string, password: string, remember = false): ThunkAction =>
    async dispatch => {
        const token = await authenticationApi.GetToken({
            emailAddress,
            password,
        });

        clearJsonWebToken();
        popAllImpersonatorJsonWebTokens();
        setJsonWebToken(token, !remember);

        dispatch(SetApiToken(token, undefined, remember));
        await dispatch(ClearGlobalSelectedWebshop());
        await dispatch(ClearGlobalSelectedDevice());
        await Promise.all([
            dispatch(InitGlobalBranchDetails()),
            dispatch(loadDashboard()),
            dispatch(PollUserUnreadNotificationsCount(true)),
        ]);
    };

export const SignOut = (): ThunkAction => async dispatch => {
    await dispatch(ClearGlobalSelectedWebshop());
    await dispatch(ClearGlobalSelectedDevice());
    await dispatch(apiStateActions.setLoggedOut());
    clearJsonWebToken();
    popAllImpersonatorJsonWebTokens();
    Infrastructure.Container.setConstant('apiToken', null);
};

let apiTokenExpirationCheckInterval: ReturnType<typeof setInterval>;

export const SetApiToken =
    (token: Infrastructure.JWT.JsonWebToken, impersonator?: AuthenticatedUser, remember = false): ThunkAction =>
    async dispatch => {
        Infrastructure.Container.setConstant('apiToken', token.value);
        Infrastructure.Container.setConstant('branchTimezone', token.claims.branchTimezone || null);

        await dispatch(
            apiStateActions.setLoggedInAs({
                ...token.claims,
                impersonator,
            }),
        );

        const refreshToken = async () => {
            if (!impersonator && token.refreshToken && token.getRefreshTokenExpiryClaim() > moment.utc().valueOf() / 1000) {
                const freshToken = await authenticationApi.RefreshToken(token.refreshToken);
                clearJsonWebToken();
                popAllImpersonatorJsonWebTokens();
                setJsonWebToken(freshToken, !remember);
                await dispatch(SetApiToken(freshToken));
            } else {
                clearJsonWebToken();
                popAllImpersonatorJsonWebTokens();
                Infrastructure.Container.setConstant('apiToken', null);
                Infrastructure.Container.setConstant('branchTimezone', null);

                clearInterval(apiTokenExpirationCheckInterval);
                window.location.reload();
            }
        };

        clearInterval(apiTokenExpirationCheckInterval);
        apiTokenExpirationCheckInterval = setInterval(() => {
            if (token.expiration <= moment.utc().valueOf() / 1000) {
                refreshToken();
            }
        }, 10000);

        await dispatch(CheckLoggedInUserUILocale());
    };

export const SetDeviceSignalingToken =
    (token: Infrastructure.JWT.JsonWebToken): ThunkAction =>
    async () => {
        Infrastructure.Container.setConstant('deviceSignalingToken', token.value);
    };

export const SetIdentifiedCustomerToken =
    (token: string, claims: Infrastructure.JWT.IdentifiedCustomerClaims): ThunkAction =>
    async () => {
        Infrastructure.Container.setConstant('identifiedCustomerToken', token);
        Infrastructure.Container.setConstant('identifiedCustomerClaims', claims);
    };

export const ClearIdentifiedCustomerToken = (): ThunkAction => async () => {
    Infrastructure.Container.setConstant('identifiedCustomerToken', null);
    Infrastructure.Container.setConstant('identifiedCustomerClaims', null);
};

async function tokenFetcherByUserId(userId: string): Promise<Infrastructure.JWT.JsonWebToken> {
    return authenticationApi.Impersonate(userId);
}
async function tokenFetcherByBranchId(userId: string): Promise<Infrastructure.JWT.JsonWebToken> {
    return authenticationApi.ImpersonateByBranchId(userId);
}

export const ImpersonateByBranchId =
    (branchId: string): ThunkAction =>
    async dispatch => {
        await dispatch(Impersonate(branchId, tokenFetcherByBranchId));
    };

export const Impersonate =
    (id: string, tokenFetcher: (id: string) => Promise<Infrastructure.JWT.JsonWebToken> = tokenFetcherByUserId): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const impersonator = selectLoggedInUser(state);
        const impersonatorToken = getValidLocalJsonWebToken();

        if (!impersonatorToken) {
            throw new Error('Cannot impersonate while not signed in');
        }

        const token = await tokenFetcher(id);

        pushImpersonatorJsonWebToken(token, impersonatorToken);
        await dispatch(SetApiToken(token, impersonator));
        await dispatch(ClearGlobalSelectedWebshop());
        await dispatch(ClearGlobalSelectedDevice());
        await Promise.all([
            dispatch(InitGlobalBranchDetails()),
            dispatch(loadDashboard()),
            dispatch(PollUserUnreadNotificationsCount(true)),
        ]);
    };

export const EndImpersonation = (): ThunkAction => async dispatch => {
    popImpersonatorJsonWebToken();
    await dispatch(SignInFromSavedToken());
    await dispatch(ClearGlobalSelectedWebshop());
    await dispatch(ClearGlobalSelectedDevice());

    await Promise.all([dispatch(InitGlobalBranchDetails()), dispatch(loadDashboard()), dispatch(PollUserUnreadNotificationsCount(true))]);
};

export const RequestNewPassword =
    (emailAddress: string): ThunkAction =>
    async (_1, getState) => {
        const state = getState();
        const locale = selectCurrentLocale(state);

        await authenticationApi.RequestNewPassword({
            emailAddress,
            locale,
        });
    };

export const SetNewPassword =
    (passwordResetRequestId: string, password: string): ThunkAction =>
    async () => {
        await authenticationApi.SetNewPassword({
            passwordResetRequestId,
            password,
        });
    };

export const SignInWithNewDeviceToken =
    (macAddress: string | null, clearOldToken = true): ThunkAction =>
    async dispatch => {
        const impersonatingMacAddress = getImpersonatingDeviceWithMacAddress();

        if (impersonatingMacAddress) {
            if (clearOldToken) {
                Infrastructure.Container.setConstant('apiToken', null);
            }
            DeviceLog.setDeviceMac(impersonatingMacAddress);

            const token = await authenticationApi.GetDeviceToken({
                macAddress: impersonatingMacAddress,
            });

            clearJsonWebToken();
            setJsonWebToken(token, true);

            dispatch(SetApiToken(token));
        } else if (macAddress) {
            if (clearOldToken) {
                Infrastructure.Container.setConstant('apiToken', null);
            }
            DeviceLog.setDeviceMac(macAddress);

            const token = await authenticationApi.GetDeviceToken({
                macAddress,
            });

            clearJsonWebToken();
            setJsonWebToken(token, true);

            dispatch(SetApiToken(token));
        } else if (!meditechDeviceApi.GetLocalcomIsDisabled()) {
            const deviceInformation = await meditechDeviceApi.GetDeviceInformation();
            DeviceLog.localcommConnected(deviceInformation);
            if (deviceInformation.DeviceId) {
                if (deviceInformation.DeviceId === 'FF-FF-FF-FF-FF-FF') {
                    throw new InvalidMacAddressError('FF-FF-FF-FF-FF-FF');
                }

                DeviceLog.setDeviceMac(deviceInformation.DeviceId);
                try {
                    Infrastructure.Container.getConstant('apiToken');
                } catch (e) {
                    console.info('apiToken constant was not initialized');
                    Infrastructure.Container.setConstant('apiToken', null);
                }

                const token = await authenticationApi.GetDeviceToken({
                    macAddress: deviceInformation.DeviceId,
                });

                clearJsonWebToken();
                setJsonWebToken(token, true);

                dispatch(SetApiToken(token));
            } else {
                DeviceLog.clearDeviceMac();
            }
        }
    };

export const SignInWithURLBrowsershotToken =
    (authToken: string): ThunkAction =>
    async dispatch => {
        Infrastructure.Container.setConstant('apiToken', null);

        const token = await authenticationApi.ParseToken(authToken);

        clearJsonWebToken();
        setJsonWebToken(token);

        dispatch(SetApiToken(token));
    };

export const SignInWithDeviceToken =
    (macAddress: string | null): ThunkAction<Promise<boolean>> =>
    async dispatch => {
        const token = getValidLocalJsonWebToken();

        if (token) {
            if (macAddress && token.claims.role === 'displayDevice' && token.claims.macAddress.toLowerCase() !== macAddress.toLowerCase()) {
                clearJsonWebToken();
                clearDeviceFootprint();
            } else {
                dispatch(SetApiToken(token));

                return true;
            }
        }

        await dispatch(SignInWithNewDeviceToken(macAddress));

        return true;
    };

export const SignInWithBrowsershotToken =
    (authToken: string): ThunkAction<Promise<boolean>> =>
    async dispatch => {
        const token = getValidLocalJsonWebToken();

        if (token) {
            dispatch(SetApiToken(token));

            return true;
        }

        await dispatch(SignInWithURLBrowsershotToken(authToken));

        return true;
    };

export const SignInWithNewDeviceSignalingToken =
    (deviceId: string): ThunkAction =>
    async dispatch => {
        Infrastructure.Container.setConstant('deviceSignalingToken', null);

        const token = await deviceApi.GetSignalingToken(deviceId);

        clearDeviceSignalingJsonWebToken();
        setDeviceSignalingJsonWebToken(token);

        dispatch(SetDeviceSignalingToken(token));
    };

export const SignInWithDeviceSignalingToken =
    (deviceId: string, forceNewToken = false): ThunkAction<Promise<boolean>> =>
    async dispatch => {
        if (!forceNewToken) {
            const token = getValidLocalDeviceSignalingJsonWebToken(deviceId);

            if (token) {
                dispatch(SetDeviceSignalingToken(token));

                return true;
            }
        }

        await dispatch(SignInWithNewDeviceSignalingToken(deviceId));

        return true;
    };

export const IdentifyVendingMachineCustomer =
    (
        secret: string,
    ): ThunkAction<
        Promise<
            { success: true; claims: Infrastructure.JWT.IdentifiedCustomerClaims } | { success: false; status?: number; error?: string }
        >
    > =>
    async dispatch => {
        try {
            const token = await authenticationApi.GetIdentifiedVendingMachineCustomerToken(secret);
            dispatch(SetIdentifiedCustomerToken(token.value, token.claims));
            return { success: true, claims: token.claims };
        } catch (e) {
            console.error(e);
            if (typeof e === 'string') {
                return {
                    success: false,
                    status: 500,
                    error: e,
                };
            }
            return {
                success: false,
                status: e.status || 500,
                error: e.message || 'Unknown error occurred',
            };
        }
    };

export const ChangePin = async ({ oldPinCode, newPinCode }: { oldPinCode: string; newPinCode: string }): Promise<void> => {
    await authenticationApi.SetIdentifiedCustomerPinCode({
        newPinCode,
        oldPinCode,
    });
};
export const ConfirmVendingMachineCustomerIdentity =
    ({ secret, pinCode }: { secret?: string; pinCode?: string }): ThunkAction<Promise<boolean>> =>
    async dispatch => {
        try {
            const existingClaims = Infrastructure.Container.getConstant('identifiedCustomerClaims');
            if (existingClaims) {
                const token = await authenticationApi.VerifyAndGetIdentifiedVendingMachineCustomerToken({ secret, pinCode });

                console.log('x', token.claims, existingClaims);

                if (token.claims.sub === existingClaims.sub) {
                    dispatch(SetIdentifiedCustomerToken(token.value, token.claims));
                    return true;
                }
            }
        } catch (e) {
            console.error(e);
        }

        return false;
    };

export const ClearIdentifiedVendingMachineCustomer = (): ThunkAction<Promise<void>> => async dispatch => {
    dispatch(ClearIdentifiedCustomerToken());
};
