import {
    ICollect,
    ILocker,
    ILockStatus,
    ILockStatusType,
    ILockerType,
    IModule,
    LockerEvent,
    LockerEventEmitter,
    IModuleStatus
}
from 'meditech-device-interface';


let LOCK_STATUSES: Array<ILockStatus> = new Array<ILockStatus>();

export class CollectMock implements ICollect {
    readonly LockerEvent: LockerEventEmitter;

    private _moduleGridConfiguration: Array<IModule> = new Array<IModule>();
    private _moduleStatuses: Array<IModuleStatus> = new Array<IModuleStatus>();
    private readonly _moduleGrid: Array<IModule>;

    constructor() {
        this.LockerEvent = new LockerEventEmitter();

        const moduleA = this.makeModuleTypeM1(0, 0, 0);
        const moduleB = this.makeModuleTypeD1(2200, 0, 1);
        const moduleC = this.makeModuleTypeD2(4500, 0, 2);
        const moduleD = this.makeModuleTypeD3(6800, 0, 3);
        const moduleE = this.makeModuleTypeD3(9500, 0, 4);

        moduleA; moduleB; moduleC; moduleD; moduleE;
        

        const moduleGrid = new Array<IModule>();
        moduleGrid.push(moduleA);
        moduleGrid.push(moduleB);
        moduleGrid.push(moduleC);
        // moduleGrid.push(moduleD);
        // moduleGrid.push(moduleE);

        this._moduleGrid = moduleGrid;

        this.simulateModuleStatuses();
    }

    async OpenLock(
        moduleNumber: number,
        lockerNumber: number):
        Promise<{ success: boolean, lockStatus: ILockStatus | undefined }> {

        if (0 === this._moduleGridConfiguration.length) {
            this._moduleGridConfiguration =
                (await this.GetModuleGridConfiguration()).moduleGrid!;
        }

        const lockStatus =
        LOCK_STATUSES.find(ls => moduleNumber === ls.ModuleNumber && lockerNumber === ls.LockNumber);

        if (lockStatus) {
            lockStatus.Status = ILockStatusType.DISENGAGED;
        }

        const getLockStatusResponse =
            await this.GetLockStatus(moduleNumber, lockerNumber);

        if (getLockStatusResponse.success && getLockStatusResponse.lockStatus) {
            this.LockerEvent.emit(LockerEvent.LockStateChanged, getLockStatusResponse.lockStatus);
            return Promise.resolve({success: true, lockStatus: getLockStatusResponse.lockStatus});
        }

        return Promise.resolve({ success: false, lockStatus: undefined });
    }

    async SimulateCloseAllLocks() {
        const allOpen = LOCK_STATUSES.filter(item => item.Status === ILockStatusType.DISENGAGED);

        for (const locker of allOpen) {
            LOCK_STATUSES = LOCK_STATUSES.map(item => {
                if (item.LockNumber === locker.LockNumber && item.ModuleNumber === locker.ModuleNumber) {
                    return {
                        ...item,
                        Status: ILockStatusType.ENGAGED
                    };
                }
                return item;
            });

            const getLockStatusResponse = await this.GetLockStatus(locker.ModuleNumber, locker.LockNumber);

            if (getLockStatusResponse.success && getLockStatusResponse.lockStatus) {
                this.LockerEvent.emit(LockerEvent.LockStateChanged, getLockStatusResponse.lockStatus);
            }
        }
    }

    async GetLockStatus(moduleNumber: number, lockerNumber: number): Promise<{ success: boolean, lockStatus: ILockStatus | undefined }> {

        if (0 === this._moduleGridConfiguration.length) {
            this._moduleGridConfiguration = (await this.GetModuleGridConfiguration()).moduleGrid!;
        }

        const module = this._moduleGridConfiguration.find(m => moduleNumber === m.ModuleNumber);

        if (module) {
            const moduleLock = module.Lockers.find(l => lockerNumber === l.LockerNumber);

            if (moduleLock) {
                const lockStatus = LOCK_STATUSES.find(ls => module.ModuleNumber === ls.ModuleNumber && moduleLock.LockerNumber === ls.LockNumber);

                if (lockStatus) {
                    return Promise.resolve({ success: true, lockStatus });
                }
            }
        }

        return Promise.resolve({ success: false, lockStatus: undefined });
    }

    async GetLockStatuses(): Promise<{ success: boolean; lockStatuses: Array<ILockStatus> }> {
        return Promise.resolve({ success: true, lockStatuses: LOCK_STATUSES })
    }

    async GetModuleGridConfiguration(): Promise<{ success: boolean, moduleGrid: Array<IModule> | undefined }> {
        return Promise.resolve({ success: true, moduleGrid: this._moduleGrid });
    }

    private simulateModuleStatuses() {
        for (const moduleStatus of this._moduleStatuses) {
            if (moduleStatus.CoolingActive) {
                if (Math.random() > 0.5) {
                    moduleStatus.Temperature -= 0.1;
                    moduleStatus.Humidity -= 0.1;
                }
            }
            else if (moduleStatus.HeatingActive) {
                if (Math.random() > 0.5) {
                    moduleStatus.Temperature += 0.1;
                }

                if (Math.random() > 0.6) {
                    moduleStatus.Humidity += 0.1;
                }
                else if (Math.random() < 0.4) {
                    moduleStatus.Humidity -= 0.1;
                }
            }

            if (Math.random() > 0.8) {
                if (Math.random() > 0.5) {
                    moduleStatus.Humidity += 0.1;
                }
                else {
                    moduleStatus.Humidity -= 0.1;
                }
            }

            moduleStatus.CoolingActive = false;
            moduleStatus.HeatingActive = false;

            if (
                moduleStatus.Temperature > 23
                || moduleStatus.Humidity > 65
            ) {
                moduleStatus.CoolingActive = true;
                moduleStatus.HeatingActive = false;
            }
            else if (
                moduleStatus.Temperature < 23
            ) {
                moduleStatus.CoolingActive = false;
                moduleStatus.HeatingActive = true;
            }

            if (moduleStatus.CoolingActive || moduleStatus.HeatingActive) {
                moduleStatus.FanSpeed = (Math.abs(moduleStatus.Temperature - 23) / 23) * 100;
            }
            else {
                moduleStatus.FanSpeed = 0;
            }

            this.LockerEvent.emit(LockerEvent.ModuleStateChange, moduleStatus);
        }
    }

    private addLocker(toList: ILocker[], LOCK_STATUSES: ILockStatus[], ModuleNumber: number, options: {
        LockerNumber: number,
        GridPositionX: number,
        GridPositionY: number,
        DimensionX: number,
        DimensionY: number,
        DimensionZ: number
    }, Status?: number): void {
        toList.push({ ...options, Type: ILockerType.COLLECT, QRCode: `FakeDevice-${ModuleNumber}-${options.LockerNumber}` });
        LOCK_STATUSES.push({ ModuleNumber, LockNumber: options.LockerNumber, Status: Status !== undefined ? Status : ILockStatusType.ENGAGED });
    }

    private makeModuleTypeM1(GridPositionX: number, GridPositionY: number, ModuleNumber: number) {
        const module: IModule = { GridPositionX, GridPositionY, Lockers: new Array<ILocker>(), ModuleNumber, ModuleType: 'M1' }
        this._moduleStatuses.push({ CoolingActive: false, FanSpeed: 100, HeatingActive: true, Humidity: 40, ModuleNumber, Temperature: 27 });


        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 1, GridPositionX: 1505, GridPositionY: 20, DimensionX: 475, DimensionY: 475, DimensionZ: 475 }, ILockStatusType.UNKNOWN);
      
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 2, GridPositionX: 1010, GridPositionY: 20, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 3, GridPositionX: 1505, GridPositionY: 515, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 4, GridPositionX: 1010, GridPositionY: 515, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });

        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 5, GridPositionX: 1670, GridPositionY: 1010, DimensionX: 310, DimensionY: 640, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 6, GridPositionX: 1010, GridPositionY: 1010, DimensionX: 640, DimensionY: 640, DimensionZ: 475 });

        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 7, GridPositionX: 515, GridPositionY: 1010, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 8, GridPositionX: 20, GridPositionY: 1010, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 9, GridPositionX: 515, GridPositionY: 1505, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 10, GridPositionX: 20, GridPositionY: 1505, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });

        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 11, GridPositionX: 1505, GridPositionY: 1670, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 12, GridPositionX: 1010, GridPositionY: 1670, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 13, GridPositionX: 1505, GridPositionY: 2165, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 14, GridPositionX: 1010, GridPositionY: 2165, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });

        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 15, GridPositionX: 680, GridPositionY: 2000, DimensionX: 310, DimensionY: 640, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 16, GridPositionX: 20, GridPositionY: 2000, DimensionX: 640, DimensionY: 640, DimensionZ: 475 });

        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 17, GridPositionX: 20, GridPositionY: 2660, DimensionX: 310, DimensionY: 640, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 18, GridPositionX: 350, GridPositionY: 2660, DimensionX: 640, DimensionY: 640, DimensionZ: 475 });

        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 19, GridPositionX: 1010, GridPositionY: 2660, DimensionX: 310, DimensionY: 310, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 20, GridPositionX: 1010, GridPositionY: 2990, DimensionX: 310, DimensionY: 310, DimensionZ: 475 });

        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 21, GridPositionX: 1670, GridPositionY: 3320, DimensionX: 310, DimensionY: 310, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 22, GridPositionX: 1340, GridPositionY: 3320, DimensionX: 310, DimensionY: 310, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 23, GridPositionX: 1670, GridPositionY: 3650, DimensionX: 310, DimensionY: 310, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 24, GridPositionX: 1340, GridPositionY: 3650, DimensionX: 310, DimensionY: 310, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 25, GridPositionX: 1670, GridPositionY: 3980, DimensionX: 310, DimensionY: 310, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 26, GridPositionX: 1340, GridPositionY: 3980, DimensionX: 310, DimensionY: 310, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 27, GridPositionX: 1670, GridPositionY: 4310, DimensionX: 310, DimensionY: 310, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 28, GridPositionX: 1340, GridPositionY: 4310, DimensionX: 310, DimensionY: 310, DimensionZ: 475 });

        module.Lockers.push({ LockerNumber: 29, GridPositionX: 20, GridPositionY: 20, DimensionX: 475 * 2 + 20, DimensionY: 475 * 2 + 20, DimensionZ: 475, Type: ILockerType.RETURN });
        LOCK_STATUSES.push({ ModuleNumber, LockNumber: 29, Status: ILockStatusType.UNKNOWN });

        module.Lockers.push({ LockerNumber: 30, GridPositionX: 1010 + 310 + 20, GridPositionY: 2660, DimensionX: 640, DimensionY: 640, DimensionZ: 475, Type: ILockerType.PAYMENT_TERMINAL });
        LOCK_STATUSES.push({ ModuleNumber, LockNumber: 30, Status: ILockStatusType.UNKNOWN });

        module.Lockers.push({ LockerNumber: 31, GridPositionX: 20, GridPositionY: 3320, DimensionX: 310 + 640 + 310 + 20 + 20, DimensionY: 310 + 640 + 310 + 20 + 20, DimensionZ: 475, Type: ILockerType.SCREEN });
        LOCK_STATUSES.push({ ModuleNumber, LockNumber: 31, Status: ILockStatusType.UNKNOWN });

        return module;
    }

    private makeModuleTypeD1(GridPositionX: number, GridPositionY: number, ModuleNumber: number) {
        const module: IModule = { GridPositionX, GridPositionY, Lockers: new Array<ILocker>(), ModuleNumber, ModuleType: 'D1' }
        this._moduleStatuses.push({ CoolingActive: true, FanSpeed: 200, HeatingActive: false, Humidity: 20, ModuleNumber, Temperature: 15 });

        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 1, GridPositionX: 20, GridPositionY: 20, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 2, GridPositionX: 515, GridPositionY: 20, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 3, GridPositionX: 1010, GridPositionY: 20, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 4, GridPositionX: 1505, GridPositionY: 20, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 5, GridPositionX: 20, GridPositionY: 515, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 6, GridPositionX: 515, GridPositionY: 515, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 7, GridPositionX: 1010, GridPositionY: 515, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 8, GridPositionX: 1505, GridPositionY: 515, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 9, GridPositionX: 20, GridPositionY: 1010, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 10, GridPositionX: 515, GridPositionY: 1010, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 11, GridPositionX: 1010, GridPositionY: 1010, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 12, GridPositionX: 1505, GridPositionY: 1010, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 13, GridPositionX: 20, GridPositionY: 1505, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 14, GridPositionX: 515, GridPositionY: 1505, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 15, GridPositionX: 1010, GridPositionY: 1505, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 16, GridPositionX: 1505, GridPositionY: 1505, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 17, GridPositionX: 20, GridPositionY: 2000, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 18, GridPositionX: 515, GridPositionY: 2000, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 19, GridPositionX: 1010, GridPositionY: 2000, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 20, GridPositionX: 1505, GridPositionY: 2000, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 21, GridPositionX: 20, GridPositionY: 2495, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 22, GridPositionX: 515, GridPositionY: 2495, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });        
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 23, GridPositionX: 1010, GridPositionY: 2495, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 24, GridPositionX: 1505, GridPositionY: 2495, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 25, GridPositionX: 20, GridPositionY: 2990, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 26, GridPositionX: 515, GridPositionY: 2990, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 27, GridPositionX: 1010, GridPositionY: 2990, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 28, GridPositionX: 1505, GridPositionY: 2990, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 29, GridPositionX: 20, GridPositionY: 3485, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 30, GridPositionX: 515, GridPositionY: 3485, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 31, GridPositionX: 1010, GridPositionY: 3485, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 32, GridPositionX: 1505, GridPositionY: 3485, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 33, GridPositionX: 1670, GridPositionY: 3980, DimensionX: 310, DimensionY: 310, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 34, GridPositionX: 1340, GridPositionY: 3980, DimensionX: 310, DimensionY: 310, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 35, GridPositionX: 1670, GridPositionY: 4310, DimensionX: 310, DimensionY: 310, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 36, GridPositionX: 1340, GridPositionY: 4310, DimensionX: 310, DimensionY: 310, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 37, GridPositionX: 1010, GridPositionY: 3980, DimensionX: 310, DimensionY: 310, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 38, GridPositionX: 680, GridPositionY: 3980, DimensionX: 310, DimensionY: 310, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 39, GridPositionX: 1010, GridPositionY: 4310, DimensionX: 310, DimensionY: 310, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 40, GridPositionX: 680, GridPositionY: 4310, DimensionX: 310, DimensionY: 310, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 41, GridPositionX: 350, GridPositionY: 3980, DimensionX: 310, DimensionY: 310, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 42, GridPositionX: 20, GridPositionY: 3980, DimensionX: 310, DimensionY: 310, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 43, GridPositionX: 350, GridPositionY: 4310, DimensionX: 310, DimensionY: 310, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 44, GridPositionX: 20, GridPositionY: 4310, DimensionX: 310, DimensionY: 310, DimensionZ: 475 });
        return module;
    }

    private makeModuleTypeD2(GridPositionX: number, GridPositionY: number, ModuleNumber: number) {
        const module: IModule = { GridPositionX, GridPositionY, Lockers: new Array<ILocker>(), ModuleNumber, ModuleType: 'D2' }
        this._moduleStatuses.push({ CoolingActive: false, FanSpeed: 300, HeatingActive: true, Humidity: 30, ModuleNumber, Temperature: 21 });

        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 1, GridPositionX: 20, GridPositionY: 20, DimensionX: 310, DimensionY: 310, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 2, GridPositionX: 350, GridPositionY: 20, DimensionX: 310, DimensionY: 310, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 3, GridPositionX: 680, GridPositionY: 20, DimensionX: 310, DimensionY: 310, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 4, GridPositionX: 1010, GridPositionY: 20, DimensionX: 310, DimensionY: 310, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 5, GridPositionX: 1340, GridPositionY: 20, DimensionX: 310, DimensionY: 310, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 6, GridPositionX: 1670, GridPositionY: 20, DimensionX: 310, DimensionY: 310, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 7, GridPositionX: 20, GridPositionY: 350, DimensionX: 310, DimensionY: 310, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 8, GridPositionX: 350, GridPositionY: 350, DimensionX: 310, DimensionY: 310, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 9, GridPositionX: 680, GridPositionY: 350, DimensionX: 310, DimensionY: 310, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 10, GridPositionX: 1010, GridPositionY: 350, DimensionX: 310, DimensionY: 310, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 11, GridPositionX: 1340, GridPositionY: 350, DimensionX: 310, DimensionY: 310, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 12, GridPositionX: 1670, GridPositionY: 350, DimensionX: 310, DimensionY: 310, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 13, GridPositionX: 20, GridPositionY: 680, DimensionX: 970, DimensionY: 970, DimensionZ: 475 });

        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 14, GridPositionX: 1010, GridPositionY: 680, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 15, GridPositionX: 1505, GridPositionY: 680, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 16, GridPositionX: 1010, GridPositionY: 1175, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 17, GridPositionX: 1505, GridPositionY: 1175, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });

        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 18, GridPositionX: 20, GridPositionY: 1670, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 19, GridPositionX: 515, GridPositionY: 1670, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 20, GridPositionX: 20, GridPositionY: 2165, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 21, GridPositionX: 515, GridPositionY: 2165, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });

        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 22, GridPositionX: 1010, GridPositionY: 1670, DimensionX: 970, DimensionY: 970, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 23, GridPositionX: 20, GridPositionY: 2660, DimensionX: 970, DimensionY: 970, DimensionZ: 475 });

        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 24, GridPositionX: 1010, GridPositionY: 2660, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 25, GridPositionX: 1505, GridPositionY: 2660, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 26, GridPositionX: 1010, GridPositionY: 3155, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 27, GridPositionX: 1505, GridPositionY: 3155, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });

        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 28, GridPositionX: 20, GridPositionY: 3650, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 29, GridPositionX: 515, GridPositionY: 3650, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 30, GridPositionX: 20, GridPositionY: 4145, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });
        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 31, GridPositionX: 515, GridPositionY: 4145, DimensionX: 475, DimensionY: 475, DimensionZ: 475 });

        this.addLocker(module.Lockers, LOCK_STATUSES, ModuleNumber, { LockerNumber: 32, GridPositionX: 1010, GridPositionY: 3650, DimensionX: 970, DimensionY: 970, DimensionZ: 475 });

        return module;
    }

    private makeModuleTypeD3(GridPositionX: number, GridPositionY: number, ModuleNumber: number) {
        const module: IModule = { GridPositionX, GridPositionY, Lockers: new Array<ILocker>(), ModuleNumber, ModuleType: 'D3' }
        this._moduleStatuses.push({ CoolingActive: false, FanSpeed: 300, HeatingActive: true, Humidity: 30, ModuleNumber, Temperature: 21 });

        module.Lockers.push({ LockerNumber: 1, GridPositionX: 20, GridPositionY: 20, DimensionX: 1960, DimensionY: 4600, DimensionZ: 475, Type: ILockerType.COLLECT });
        LOCK_STATUSES.push({ ModuleNumber, LockNumber: 1, Status: ILockStatusType.ENGAGED });

        return module;
    }
}
