import * as React from 'react';

import { Route, isSuperUser, isCompanyManager, isBranchManager } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/webshops/content',
        main: () => import('./ContentLandingPage'),
        isAuthorized: [isSuperUser, isCompanyManager, isBranchManager],
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/layout-and-design',
        main: () => import('./LayoutAndDesignLandingPage'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/configuration',
        main: () => import('./ConfigurationLandingPage'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/appointments',
        main: () => import('./AppointmentsLandingPage'),
        isAuthorized: [isBranchManager],
        preloading: () => <FullPage hideMainSearch={true} />,
    },
];

export default routes;
