import { Domain } from 'api';
import { HTMLEditorValue } from 'utils';

type LocalizedTableValue = Domain.LocalizedValue<{
    numRows: number;
    numCols: number;
    rows: Array<Array<{ content: HTMLEditorValue }>>;
}>;

export function mapLocalizedRichText(value: Domain.LocalizedValue, locales: Domain.Locale[]) {
    const localizedValue: Domain.LocalizedValue<HTMLEditorValue> = {};

    for (const locale of locales) {
        localizedValue[locale] = value[locale] ? HTMLEditorValue.createFromString(value[locale] as string) : HTMLEditorValue.createEmpty();
    }

    return localizedValue;
}

export function unwrapLocalizedRichText(localizedValue: Domain.LocalizedValue<HTMLEditorValue>, cleanHTML = false): Domain.LocalizedValue {
    const unwrappedValue: Domain.LocalizedValue = {};

    for (const language in localizedValue) {
        if (!localizedValue.hasOwnProperty(language)) {
            continue;
        }

        const value = localizedValue[language as Domain.Locale];
        if (value) {
            unwrappedValue[language as Domain.Locale] = HTMLEditorValue.toString(value);
            if (cleanHTML && unwrappedValue[language as Domain.Locale] === '<p style=""><br></p>') {
                delete unwrappedValue[language as Domain.Locale];
            }
        }
    }

    return unwrappedValue;
}

export function defaultLocalizedRichText(locales: Domain.Locale[]) {
    const localizedValue: Domain.LocalizedValue<HTMLEditorValue> = {};

    for (const locale of locales) {
        localizedValue[locale] = HTMLEditorValue.createEmpty();
    }

    return localizedValue;
}

export function defaultLocalizedRichTextTable(locales: Domain.Locale[]) {
    const localizedValue: LocalizedTableValue = {};

    for (const locale of locales) {
        localizedValue[locale] = {
            numRows: 1,
            numCols: 2,
            rows: [[{ content: HTMLEditorValue.createEmpty() as any }, { content: HTMLEditorValue.createEmpty() as any }]],
        };
    }

    return localizedValue;
}

export const stripHtml = (html: string) => {
    const element = document.createElement('div');
    element.innerHTML = html;
    return element.textContent || element.innerText || '';
};

export const localeValidator = (errorMessage: string, locales: Domain.Locale[]) => (value: Domain.LocalizedValue) => {
    for (const locale of locales) {
        if (!value[locale] || !value[locale].trim()) {
            return errorMessage;
        }
    }
};

export const localeHTMLValidator = (errorMessage: string, locales: Domain.Locale[]) => (value: Domain.LocalizedValue<HTMLEditorValue>) => {
    for (const locale of locales) {
        const localeValue = value[locale];
        if (!localeValue || !stripHtml(HTMLEditorValue.toString(localeValue))) {
            return errorMessage;
        }
    }
};

export function cleanUpUnwantedLocales(value: Domain.LocalizedValue, locales: Domain.Locale[]) {
    const localizedValue: Domain.LocalizedValue = {};

    for (const locale of locales) {
        localizedValue[locale] = value[locale];
    }

    return localizedValue;
}

export function addWantedLocalesToRichText(value: Domain.LocalizedValue<HTMLEditorValue>, locales: Domain.Locale[]) {
    const localizedValue: Domain.LocalizedValue<HTMLEditorValue> = value;

    for (const locale of locales) {
        if (value.hasOwnProperty(locale)) {
            localizedValue[locale] = value[locale];
        } else {
            localizedValue[locale] = HTMLEditorValue.createEmpty();
        }
    }

    return localizedValue;
}
export function addWantedLocalesToRichTextTable(value: LocalizedTableValue, locales: Domain.Locale[]) {
    const localizedValue: LocalizedTableValue = value;

    for (const locale of locales) {
        if (value.hasOwnProperty(locale)) {
            localizedValue[locale] = value[locale];
        } else {
            localizedValue[locale] = {
                numRows: 1,
                numCols: 2,
                rows: [[{ content: HTMLEditorValue.createEmpty() as any }, { content: HTMLEditorValue.createEmpty() as any }]],
            };
        }
    }

    return localizedValue;
}

export function addWantedLocales(value: Domain.LocalizedValue, locales: Domain.Locale[]) {
    const localizedValue: Domain.LocalizedValue = value;

    for (const locale of locales) {
        if (value.hasOwnProperty(locale)) {
            localizedValue[locale] = value[locale];
        } else {
            localizedValue[locale] = '';
        }
    }

    return localizedValue;
}
