import { Branch } from './Branch';
import { Company } from './Company';
import { LocalizedValue } from './Localisation';
import { Pagination } from './Pagination';
import { Sorting } from './Sorting';

export interface IntegrationBase {
    availableIntegrationId: string;
    name: string;
    logoUrl?: string;
    integrationTypes: IntegrationCapability[];
}

export interface IntegrationSetting {
    defaultValue: string | number | boolean | null;
    name?: LocalizedValue;
    label: string;
    description?: string | null;
    isRequired: boolean;
    type:
        | 'url'
        | 'string'
        | 'number'
        | 'boolean'
        | 'options_string'
        | 'multiple_options_string'
        | 'deviceId'
        | 'email'
        | 'deviceIds'
        | 'options_country_code';
    options:
        | []
        | {
              [key: string]: string;
          };
}

export interface IntegrationSettings {
    [key: string]: IntegrationSetting;
}

type ConfigurableFor = 'company' | 'branch';

export type Integration = IntegrationBase & {
    configuredIntegrationId: ConfiguredIntegrationId;
    companyId?: Company['companyId'] | null;
    companyName?: Company['name'] | null;
    branchId?: Branch['branchId'] | null;
    branchName?: Branch['branchName'] | null;
    config?: {
        [key: string]: any;
    };
    configuredOn?: string | null;
    activeStatus: 'active' | 'inactive';
    lastRunAt?: string | null;
    updatedOn?: string | null;
    configuredByUserId: string | null;
    configuredByUserName?: string | null;
    description?: LocalizedValue;
};

export const INTEGRATION_CAPABILITY = [
    'deliverPickups',
    'deliverProducts',
    'exportOrders',
    'importPrices',
    'importStock',
    'locker',
    'manageReservations',
    'payMatic',
    'payWebshop',
    'placeBackOrders',
    'resolveEScripts',
    'robot',
    'sendMessages',
    'sendShipments',
] as const;

export type IntegrationCapability = (typeof INTEGRATION_CAPABILITY)[number];

export type IntegrationsCountByType = {
    [key in IntegrationCapability]: number;
};

export type AvailableIntegration = IntegrationBase & {
    settings?: IntegrationSettings;
    canBeConfiguredFor: ConfigurableFor[];
    localizedDescription?: [] | LocalizedValue;
};

export type IntegrationDetails = Integration & {
    settings?: IntegrationSettings;
};

export type ConfiguredIntegration = Integration & {
    availableForCountries: string[];
};

export type ConfiguredIntegrationId = string;

export type DeviceDeliverPickupsIntegrations = {
    robot: ConfiguredIntegrationId | undefined;
    lockers: ConfiguredIntegrationId | undefined;
    all: ConfiguredIntegrationId[];
};

export interface ConfiguredIntegrationsPage {
    items: ConfiguredIntegration[];
    total: number;
    pagination: Pagination;
    sorting?: Sorting<keyof Integration> | null;
    search?: string | null;
}

export interface AvailableIntegrationsPage {
    items: AvailableIntegration[];
    total: number;
    pagination: Pagination;
    sorting?: Sorting<keyof Integration> | null;
    search?: string | null;
}

export type UpdateConfiguredIntegrationPayload = Omit<Integration, 'name' | 'companyName' | 'branchName' | 'configuredOn'>;
export type AssignAvailableIntegrationPayload = UpdateConfiguredIntegrationPayload;

export interface ProductAvailabilityIntegration {
    name: string;
    productAvailabilitySourceId: string;
}

export interface ProductAvailabilityIntegrationsPage {
    items: ProductAvailabilityIntegration[];
    total: number;
    pagination: Pagination;
    sorting?: Sorting<'activeStatus'> | null;
    search?: string | null;
}

export interface TestConfiguredIntegrationPayload {
    availableIntegrationId: AvailableIntegration['availableIntegrationId'];
    companyId: Company['companyId'];
    branchId?: Branch['branchId'] | null;
    config: {
        [key: string]: any;
    };
}

export type IntegrationPaymentMethods = {
    name: string;
    imageUrl: string;
}[];
