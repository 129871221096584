import * as Wwks2Domain from './Wwks2';
import * as PlcDomain from './MeditechDevicePlc';
import { DeviceInfo, DEVICEINFO_DEFAULT } from './LocalComm';


export interface ReadyForDeliveryStatus {
    initDeviceDone: boolean
    plc?: boolean,
    wwks2: boolean,
    storageSystem: boolean,
    transportChannelBetweenStorageSystemAndDeviceIsBlocked?: boolean,
    internetConnection: boolean,
    status: boolean
}

export interface DeviceSettings {
    deviceId?: string
    parlephoneIp?: string
    reservationType?: 'pharmacySoftware' | 'lochting',
    deviceType?: 'matic' | 'vision' | 'kiosk',
    barcodeScannerAvailable?: boolean
    disablePLC?: boolean
    delivery?:{
        transportTimeBoxSensorAndDeliveryBox?: number
        transportTimeRobotAndDevice?: number
        stockLocation?: Array<string>
        zone?: string,
        deliveryOutput?: number
        deliveryOutputIsMaticHatch?: boolean
        deliveryCameraIp?: string
        autoFixAfterDeliveryFail?: boolean
        autoSaveShoppingCart?: boolean
        autoEmptyShoppingCartAfterDelivery?: boolean
    }
    storageSystem?:{
        useRobotStorageSystem?: boolean
        sourceId?: number
        compatibilities?: string[]
        IP?: string
        typeOfMT?: boolean
        protocol?: string
    },
    pharmacySoftware?:{
        usePharmacySoftware?: boolean
        IP?: string
        type?: string
        sourceId?: number
        compatibilities?: string[]
        protocol?: string
    }
    reInitializeDeviceWhenPLCReleased?: boolean
    printer?: {
        ticketPrinterAvailable?: boolean
        ejectTicketPrinterByDelivery?: boolean
        endPointSavePrinterStatusToLochting?: string
    },
    shoppingcart?:{
        currency: string
        limitOfProducts: number
        endpointSaveToLochting: string
    }
    diagnoses?: {
        autoSaveToLochting: boolean
        autoSaveToLochtingInterval: number
        endPointSaveDiagnosesToLochting: string
    }
}

export interface ShoppingCart {
    id: string | number
    pharmacySoftwareSCId: string | number
    limitOfProductsInCart: number,
    products: ShoppingCartProduct[],
    totalAmount: number,
    totalAmountPaid: number
    totalAmountToBePaid: number
    currency: string
    status: string
    statusPS: string
    isFinishedWithPS: boolean
    paymentNeeded: boolean
    paymentStatus: PaymentStatus
    paymentIsPushedToPS: boolean
    isCheckedForPayment: boolean
    reservationId: string | number
    reservationPickupCode: string
    reservationType: ShoppingCartReservationType
    type: ShoppingCartType
    deliveryType: ShoppingCartDeliveryType
    deliveryStatus: DeliveryStatus
    updateStatusLogs: { timestamp: number, event: ShoppingCartUpdateStatus}[]
    clientInformation: ClientInfo
    logIsSaved: boolean
    ticketData: any
    confirmationTicketData: boolean
    willClientLeaveContactInfoWhenDeliveryFailed: boolean,
    confirmationIfClientWantLeaveContactInfoWhenDeliveryFailed: boolean
    confirmationClientHasTakenDeliveredProducts: boolean
    confirmationPharmacistHasDeliveredTheProducts: boolean
}

export interface ShoppingCartProduct {
    productid: string
    barcode: string
    gtin: string[]
    ean: string[]
    name?: string
    barcodeForDelivery: string
    price: number
    offerPrice: number,
    vatRate: number,
    currency: string
    orderedQuantity: number
    dispensedQuantity: number
    paidQuantity: number
    deliveryBy: ShoppingCartDeliveryType
    serialNumbers: string[]
}

export interface ShoppingCartProductModify {
    productid: string
    barcode?: string
    gtin?: string[]
    ean?: string[]
    name?: string | null
    barcodeForDelivery?: string
    price?: number
    offerPrice?: number
    currency?: string
    orderedQuantity?: number
    dispensedQuantity?: number
    paidQuantity?: number
    vatRate: number
}

export interface ShoppingCartPayment {
    status: string
    type: string
    transactionId: string
    details: any
}

export interface DeliveryStatus{
    deviceInfo: DeviceInfo
    starttime: number
    stoptime: number
    errors: string[]
    status: 'idle' | 'busy' | 'aborted' | 'completed'
    autoSaveShoppingCart: boolean
    autoEmptyShoppingCartAfterDelivery: boolean
    timestamp: number
    transactionId: number
    progressId: number
    stockLocation: Array<string>
    ticketPrintedStatus: string
    ticketViaEmail: boolean
    processLogs: {
        //step: 'cuttTicketFromPrinter' | 'checkMaticReadyForProcess' | 'init' | 'prepareShoppingCart' | 'payment' | 'robotDelivery' | 'pharmacistDelivery' | 'openDeliveryDoor' | 'waitingConfirmationByPharmacist' |'waitingConfirmationByClient' | 'closeDeliveryDoor' | 'rejectProcess' | 'takePicture' | 'done' | 'endProcess' | 'none',
        step: string
        timestamp: number
        level?: string
    }[]
    plc: {
        logs: {
            command: string
            timestamp: number
            details: PlcDomain.PlcJobStatus | boolean
        }[],
        eventLogs:{
            timestamp: number
            details: string
        }[]
        boxDetectionCounter: number
        plcMessages: PlcDomain.PlcMessages[]
        plcMessagesCodes: (string | number)[]
        processIds: (string | number)[]
    }
    wwks2: {
        logs: {
            command: string
            timestamp: number
            details: Wwks2Domain.Wwks2ProcessStatus
        }[]
        dialogIds: (string | number)[]
    }
    barcodesForStockAndDelivery: { barcodeForDelivery: string,  barcodes: string[], productIndex: number,  quantity: number, productIsAvailableBy: ShoppingCartDeliveryType, availableQuantityByStorageSystem: number, availableQuantityByPharmacist: number}[]
    barcodesForDeliveryByStorageSystem: {barcode: string, quantity: number}[]
    barcodesForDeliveryByPharmacist: {barcode: string, quantity: number}[]
    barcodesAreDelivered: {barcode: string, quantity: number}[]
    barcodesAreDeliveredByStorageSystem: {barcode: string, quantity: number}[]
    barcodesAreDeliveredByPharmacist: {barcode: string, quantity: number}[]
    totalNumberOfProductsDelivered: number
    totalNumberOfProductsDeliveredByStorageSystem: number
    totalNumberOfProductsDeliveredByPharmacist: number
    totalNumberOfProductsForByStorageSystem: number
    totalNumberOfProductsForByPharmacist: number
    totalNumberOfProductsForDelivery: number
    totalNumberOfProducts: number
    serialNumbersOfDeliveredProducts: {barcode:string, serialNumbers:string[]}[]
    transportChannelBetweenStorageSystemAndDeviceIsBlocked: boolean
    finishShoppingCartWithPharmacySoftware: boolean
}

export interface ClientInfo{
    name?: string
    email?: string
    nationalRegisterNumber?: string
    address?: string
    language?: string
    country?: string
    needAdvice?: boolean
    needEticket?: boolean
    needTicket?: boolean
}

export interface Initialization {
    deviceIdIsGiven: boolean
    localcommConnected: boolean
    plcModeReceivedByLocalcomm: boolean
    plcStateReceivedByLocalcomm: boolean
    plcEmergencyStopBtnReceivedByLocalcomm: boolean
    plcEmergencyPilzBtnReceivedByLocalcomm: boolean
    plcEmergencyStopCircuitReceivedByLocalcomm: boolean
    plcIsReady: boolean
    timeoutOnPlcReady: boolean
    timeoutOnLocalCommReady: boolean
    rejectProcessIsDone: boolean
    rejectProcessIsBusy: boolean
    rejectProcessIsFailed: boolean
    startConnectionWithStorageSystem: boolean
    retryToConnectWithStorageSystem: boolean
    connectedWithStorageSystem: boolean
    failedToConnectWithStorageSystem: boolean
    startConnectionWithPharmacySoftware: boolean
    retryToConnectWithPharmacySoftware: boolean
    connectedWithPharmacySoftware: boolean
    failedToConnectWithPharmacySoftware: boolean
    isPharmaSoftwareConnectionNeeded: boolean
    isStorageSystemConnectionNeeded: boolean
    starttime: number
    stoptime: number
    currentStep: string
    steps: {
        step: string
        timestamp: number
    }[]
    done: boolean
    busy: boolean
    error: boolean
}

export interface ParsedDatamatrix {
    gtin: string
    lot: string
    expirationDate: string
    bestBeforeDate: string
    productionDate: string
    batchNumber: string
    serialNumber: string
}

export interface DeviceStatus {
    device: DeviceInfo
    deviceId: string,
    deviceType: 'matic' | 'vision' | 'kiosk'
    initialization: Initialization
    errorCodes: number[]
    reInitializeDeviceWhenPLCReleased: boolean
    internetConnection: boolean
    wwks2:{
        storageSystem: {
            isStorageSystemConnectionNeeded: boolean
            typeOfMT: boolean
            wwks2Dialogs: Wwks2Domain.Wwks2ResultData[]
            wwks2DialogIds: (string | number)[]
            connectionStatus: boolean
            connectedTimestamp: number
            closedTimestamp: number
            statusInfo: Wwks2Domain.wwks2StatusResponse | undefined
            compatibilities: string[]
            id: number | string
            IP: string
        }
        pharmacySoftware: {
            wwks2Dialogs: Wwks2Domain.Wwks2ResultData[]
            wwks2DialogIds: (string | number)[]
            isPharmaSoftwareConnectionNeeded: boolean
            type: string
            connectionStatus: boolean
            connectedTimestamp: number
            closedTimestamp: number
            compatibilities: string[]
            id: number | string
            IP: string
        }
        device:{
            storageSystemId: number
            pharmacySoftwareId: number
            storageSystemCompatibilities: string[]
            pharmaSystemCompatibilities: string[]
            deliveryOutput: number
            stockLocation: Array<string> | Array<number>
        }
    }
    maticDeviceIsInUse: boolean
    delivery:{
        cvForwardWhenBoxSensorHasDetection: boolean
        cvForwardWhenBoxSensorHasDetectionBusy: boolean
        autoFixAfterDeliveryFail: boolean
        lastShoppingCart: ShoppingCart
        releasedForNewDelivery: boolean
        transportChannelBetweenStorageSystemAndDeviceIsBlocked: boolean
        transportTimeBoxSensorAndDeliveryBox: number
        transportTimeRobotAndDevice: number
        deliveryCameraIp: string
        autoEmptyShoppingCartAfterDelivery: boolean
    }
    barcodeScanner:{
        enabled: boolean
        status: boolean
        lastValue: string
        lastValueTimestamp: number
    },
    plc: {
        maticType: 'MATIC' | 'MATICLITE'
        plcVersion: number
        status: PlcDomain.PlcStatus | boolean
        emergencyActivated: boolean
        emergencyStatus: string
        releaseStatus: string
        released: boolean
        failedEventlogs: {
            command: string
            timestamp: number
            details: PlcDomain.PlcJobStatus | boolean
        }[]
        failedEventlogIds: (string | number)[]
    },
    shoppingCart:{
        currency: string,
        limitOfProductsInCart: number //0 = no limit
    }
    diagnoses: {
        autoSaveDiagnosesToLochting: boolean
        autoSaveDiagnosesToLochtingInterval: number
    }
}

export interface PaymentStatus {
    busy: boolean
    success: boolean
    error: boolean
    errorMsg: string
    errorType: string
    paid: boolean
    cancel: boolean
    amount: number
    currency: string
    orderId: string
    transactionId: string
    status: string
    paymentDetails: any
    paymentInfo?: ShoppingCartPayment
    paymentInfoType?: string
    starttime: number,
    stoptime: number
}

export enum ProductBarcodeType {
    GTIN = 'GTIN',
    EAN = 'EAN',
    CNK = 'CNK',
    ACL = 'ACL',
    CIP = 'CIP',
    CEFIP = 'CEFIP',
    DATAMATRIX = 'DATAMATRIX',
    UKNOWN = 'UNKNOWN'
}

export enum ShoppingCartType {
    Sale = 'sale',
    Reservation = 'reservation',
    Nighthatch = 'nighthatch',
    Surprise = 'surprise'
}

export enum ShoppingCartReservationType {
    Lochting = 'Lochting',
    PharmacySoftware = 'PharmacySoftware',
    None = 'none'
}

export enum ShoppingCartDeliveryType {
    ByStorageSystem = 'byStorageSystem',
    ByStorageSystemOrByPharmacistComplete = 'byStorageSystemOrByPharmacistComplete',
    ByStorageSystemOrByPharmacistPartial = 'byStorageSystemOrByPharmacistPartial',
    ByPharmacist = 'byPharmacist',
    CannotBeDelivered = 'cannotBeDelivered'
}

export enum ShoppingCartStatus {
    Active = 'Active',
    NotActive = 'NotActive',
    Discarded = 'Discarded',
    Aborted = 'Aborted',
    Finished = 'Finished'
}

export enum ShoppingCartPaymentStatus {
    Paid = 'Paid',
    Canceled = 'Canceled',
    Failed = 'Failed',
    Busy = 'Busy',
    PaymentNotNeeded = 'PaymentNotNeeded',
    NoShoppingCartActive = 'NoShoppingCartActive',
    NotPaid = 'NotPaid',
    ShoppingCouldNotBeUpdated = 'ShoppingCouldNotBeUpdated'
}

export enum ShoppingCartUpdateStatus {
    NoShoppingCartActive=  'NoShoppingCartActive',
    ProductNotAvailableForDelivery = 'ProductNotAvailableForDelivery',
    SyncWithPharmacySoftwareNotNeeded = 'SyncWithPharmacySoftwareNotNeeded',
    SyncWithPharmacySoftwareFailed = 'SyncWithPharmacySoftwareFailed',
    SyncedWithPharmacySoftware= 'SyncedWithPharmacySoftware',
    SyncBusyWithPharmacySoftware= 'SyncBusyWithPharmacySoftware',
    ShoppingCartIsUpdated = 'ShoppingCartIsUpdated',
    ShoppingCartAlreadyCreatedWithPharmacySoftware = 'ShoppingCartAlreadyCreatedWithPharmacySoftware',
    BusyToCreateShoppingCartWithPharmacySoftware = 'BusyToCreateShoppingCartWithPharmacySoftware',
    BusyToGetShoppingCartWithPharmacySoftware = 'BusyToGetShoppingCartWithPharmacySoftware',
    ShoppingCartIsCreatedWithPharmacySoftware = 'ShoppingCartIsCreatedWithPharmacySoftware',
    FailedToCreateOrGetShoppingCartWithPharmacySoftware = 'FailedToCreateOrGetShoppingCartWithPharmacySoftware',
    BusyToCheckWithStorageSystem = 'BusyToCheckWithStorageSystem',
    ProductIsAvailableByStorageSystem = 'ProductIsAvailableByStorageSystem',
    ProductIsPartialAvailableByStorageSystemAndByPharmacist = 'ProductIsPartialAvailableByStorageSystemAndByPharmacist',
    ProductIsAvailableByPharmacist = 'ProductIsAvailableByPharmacist',
    ProductDoesNotExist = 'ProductDoesNotExist',
    ProductIsDeletedFromShoppingCart = 'ProductIsDeletedFromShoppingCart',
    BusyWithDeleteProductFromShoppingCart = 'BusyWithDeleteProductFromShoppingCart',
    BusyWithAddProductFromShoppingCart = 'BusyWithAddProductFromShoppingCart',
    InitializationShoppingCartIsFailed = 'InitializationShoppingCartIsFailed',
    ShoppingCartWithPharmacySoftwareHaveDifferentId = 'ShoppingCartWithPharmacySoftwareHaveDifferentId',
    ShoppingCartWithPharmacySoftwareIdIsEmpty = 'ShoppingCartWithPharmacySoftwareIdIsEmpty',
    syncShoppingCartWithPharmacySoftwareHasNoUpdateStatus = 'syncShoppingCartWithPharmacySoftwareHasNoUpdateStatus',
    ShoppingCartIsCreatedOrRetrievedWithPharmacySoftware = 'ShoppingCartIsCreatedOrRetrievedWithPharmacySoftware',
    
   

    CreateShoppingCartWithPharmacySoftwareNotNeeded = 'CreateShoppingCartWithPharmacySoftwareNotNeeded',
    UpdateWithPharmacySoftwareFailed = 'UpdateWithPharmacySoftwareFailed',
    UpdatedWithPharmacySoftware = 'UpdatedWithPharmacySoftware',
    UpdateFailedWithPharmacySoftware = 'UpdateFailedWithPharmacySoftware',
    BusyToCreateShoppingCart = 'BusyToCreateShoppingCart',
    NotAllProductsInShoppingCartCanBeDelivered = 'NotAllProductsInShoppingCartCanBeDelivered',
    AllProductsInShoppingCartCanBeDelivered = 'AllProductsInShoppingCartCanBeDelivered',
    NoStockInformationOfTheShoppingCartCanBeDone = 'NoStockInformationOfTheShoppingCartCanBeDone',
    StockInformationOfTheShoppingCartIsChecked = 'StockInformationOfTheShoppingCartIsChecked',
    NoPickUpCodeIsGiven = 'NoPickUpCodeIsGiven',
    ShoppingCartIsReadyForDelivery = 'ShoppingCartIsReadyForDelivery',
    ShoppingCartIsNotReadyForDelivery = 'ShoppingCartIsNotReadyForDelivery',
    ShoppingCartIsCreated = 'ShoppingCartIsCreated',
    ShoppingCartIsDiscarded = 'ShoppingCartIsDiscarded',
    ShoppingCartIsFailedToDiscard = 'ShoppingCartIsFailedToDiscard',
    TimeoutStockInformationOfTheShoppingCart = 'TimeoutStockInformationOfTheShoppingCart',
    ErrorStockInformationOfTheShoppingCart = 'ErrorStockInformationOfTheShoppingCart',
    NotEnoughProductsForDeliveryByStorageSystem = 'NotEnoughProductsForDeliveryByStorageSystem',
    NotAllProductsAreForDeliveryByPharmacist = 'NotAllProductsAreForDeliveryByPharmacist',
    TotalNumberForDeliveryIsIncorrect = 'TotalNumberForDeliveryIsIncorrect',
    ShoppingCartIsPaid = 'ShoppingCartIsPaid',
    ProductIsNotDeletedFromShoppingCart = 'ProductIsNotDeletedFromShoppingCart',
    CheckDeliveredProductsOfTheShoppingCart = 'CheckDeliveredProductsOfTheShoppingCart',
    CheckSerialNumberOfDeliveredProducts = 'CheckSerialNumberOfDeliveredProducts',
    ProductCannotBeAddedBecayseMissingParameters = 'ProductCannotBeAddedBecayseMissingParameters',
    DeviceIsNotInitialized = 'DeviceIsNotInitialized',
    CheckOutIsDone = 'CheckOutIsDone',
    PaymentIsFailed = 'PaymentIsFailed',
    ShoppingCartHasNoProducts = 'ShoppingCartHasNoProducts',
    PaymentNotNeeded = 'PaymentNotNeeded',
    PaymentIsSuccesfull = 'PaymentIsSuccesfull',
    CheckPaymentInfo = 'CheckPaymentInfo',
    PaymentCanceled = 'PaymentCanceled',
    PaymentFailed = 'PaymentFailed',
    PaymentIsBusy = 'PaymentIsBusy',
    PaymentSucceeded = 'PaymentSucceeded',
    CheckOutIsFailed = 'CheckOutIsFailed',
    CheckOutIsSucceeded = 'CheckOutIsSucceeded',
    DeliveryAlreadyBusy = 'DeliveryAlreadyBusy',
    DeliveryCompleted = 'DeliveryCompleted',
    DeliveryFailed = 'DeliveryFailed',
    CheckShoppingCartIsNotCheckedForPayment = 'CheckShoppingCartIsNotCheckedForPayment',
    ShoppingCartIsSaved = 'ShoppingCartIsSaved',
    PaymentNeeded = 'PaymentNeeded',
    ShoppingCartDeliveryTypeIsChanged = 'ShoppingCartDeliveryTypeIsChanged',
    ShoppingCartTypeIsChanged = 'ShoppingCartTypeIsChanged',
    DeviceIsNotReadyForDelivery = 'DeviceIsNotReadyForDelivery',
    ShoppingCartReservationIsCreated = 'ShoppingCartReservationIsCreated',
    ShoppingCartReservationNotFounded = 'ShoppingCartReservationNotFounded',
    ShoppingCartNotCreated = 'ShoppingCartNotCreated'
}

export const DEVICESETTINGS: DeviceSettings = {
    delivery: {
        transportTimeBoxSensorAndDeliveryBox: 1000,
        transportTimeRobotAndDevice: 119000,
        deliveryCameraIp: '200.0.0.121',
        stockLocation: new Array<string>(),
        deliveryOutput: 0,
        autoFixAfterDeliveryFail: true,
        autoSaveShoppingCart: true
    },
    pharmacySoftware:{
        protocol: 'WWKS2',
        usePharmacySoftware: false,
        IP: '',
        type: '',
        sourceId: 851,
        compatibilities: ['Hello', 'KeepAlive', 'ArticleInfo', 'ArticlePrice', 'StockInfo', 'Output', 'ShoppingCart', 'ShoppingCartUpdate', 'StockLocationInfo']
    },
    storageSystem:{
        useRobotStorageSystem: false,
        protocol: 'WWKS2',
        IP: '',
        typeOfMT: true,
        sourceId: 850,
        compatibilities: ['Hello', 'KeepAlive', 'Status', 'StockInfo', 'Output', 'StockLocationInfo'],
    },
    parlephoneIp: '200.0.0.120',
    reservationType: 'pharmacySoftware',
    deviceType: 'matic',
    reInitializeDeviceWhenPLCReleased: false,
    barcodeScannerAvailable: false,
    printer: {
        ticketPrinterAvailable: false,
        ejectTicketPrinterByDelivery: false,
        endPointSavePrinterStatusToLochting: ''
    },
    shoppingcart:{
        currency: 'EUR',
        limitOfProducts: 0, //0 = no limit
        endpointSaveToLochting: ''
    },
    diagnoses: {
        autoSaveToLochting: false,
        autoSaveToLochtingInterval: 300000,
        endPointSaveDiagnosesToLochting: ''
    }
}

export const READYFORDELIVERYSTATUS: ReadyForDeliveryStatus = {
    initDeviceDone: false,
    plc: false,
    wwks2: false,
    storageSystem: false,
    transportChannelBetweenStorageSystemAndDeviceIsBlocked: false,
    internetConnection: false,
    status: false
}

export const CLIENTINFO: ClientInfo = {
    name: '',
    email: '',
    nationalRegisterNumber: '',
    address: '',
    language: '',
    country: '',
    needAdvice: false,
    needEticket: false,
    needTicket: false
};

export const DELIVERYSTATUS: DeliveryStatus = {
    deviceInfo: DEVICEINFO_DEFAULT,
    starttime: 0,
    stoptime: 0,
    errors: [],
    status: 'idle',
    autoSaveShoppingCart: true,
    autoEmptyShoppingCartAfterDelivery: true,
    timestamp: 0,
    transactionId: 0,
    progressId: 0,
    ticketPrintedStatus: '',
    ticketViaEmail: false,
    processLogs: [],
    plc: {
        logs: [],
        eventLogs:[],
        boxDetectionCounter: 0,
        plcMessages: [],
        plcMessagesCodes: [],
        processIds: []
    },
    wwks2: {
        logs: [],
        dialogIds: []
    },
    stockLocation: new Array<string>(),
    barcodesForStockAndDelivery: [],
    barcodesForDeliveryByStorageSystem: [],
    barcodesForDeliveryByPharmacist: [],
    barcodesAreDelivered: [],
    barcodesAreDeliveredByStorageSystem: [],
    barcodesAreDeliveredByPharmacist: [],
    totalNumberOfProductsDelivered: 0,
    totalNumberOfProductsDeliveredByStorageSystem: 0,
    totalNumberOfProductsDeliveredByPharmacist: 0,
    totalNumberOfProductsForByStorageSystem: 0,
    totalNumberOfProductsForByPharmacist: 0,
    totalNumberOfProductsForDelivery: 0,
    totalNumberOfProducts: 0,
    serialNumbersOfDeliveredProducts: [],
    transportChannelBetweenStorageSystemAndDeviceIsBlocked: false,
    finishShoppingCartWithPharmacySoftware: false
}

export const PAYMENTPROCESS: PaymentStatus = {
    busy: false,
    success: false,
    error: false,
    errorMsg: '',
    errorType: '',
    paid: false,
    cancel: false,
    amount: 0,
    currency: 'EUR',
    orderId: '',
    transactionId: '',
    status: '',
    paymentDetails: {},
    paymentInfoType: '',
    starttime: 0,
    stoptime: 0
}

export const SHOPPINGCART: ShoppingCart = {
    id: '',
    pharmacySoftwareSCId: '',
    limitOfProductsInCart: 0, //0 = no limit
    products: [],
    totalAmount: 0,
    totalAmountPaid: 0,
    totalAmountToBePaid: 0,
    currency: 'EUR',
    status: ShoppingCartStatus.NotActive,
    statusPS: ShoppingCartStatus.NotActive,
    isFinishedWithPS: false,
    paymentNeeded: false,
    paymentStatus: PAYMENTPROCESS,
    isCheckedForPayment: false,
    paymentIsPushedToPS: false,
    reservationId: '',
    reservationPickupCode: '',
    reservationType: ShoppingCartReservationType.None,
    type: ShoppingCartType.Sale,
    deliveryType: ShoppingCartDeliveryType.ByStorageSystem,
    deliveryStatus: DELIVERYSTATUS,
    updateStatusLogs: [],
    clientInformation: CLIENTINFO,
    logIsSaved: false,
    ticketData: '',
    confirmationTicketData: false,
    willClientLeaveContactInfoWhenDeliveryFailed: false,
    confirmationIfClientWantLeaveContactInfoWhenDeliveryFailed: false,
    confirmationClientHasTakenDeliveredProducts: false,
    confirmationPharmacistHasDeliveredTheProducts: false
}

export const INITIALIZATION: Initialization = {
    deviceIdIsGiven: false,
    localcommConnected: false,
    timeoutOnPlcReady: false,
    timeoutOnLocalCommReady: false,
    plcModeReceivedByLocalcomm: false,
    plcStateReceivedByLocalcomm: false,
    plcEmergencyStopBtnReceivedByLocalcomm: false,
    plcEmergencyPilzBtnReceivedByLocalcomm: false,
    plcEmergencyStopCircuitReceivedByLocalcomm: false,
    plcIsReady: false,
    rejectProcessIsDone: false,
    rejectProcessIsBusy: false,
    rejectProcessIsFailed: false,
    startConnectionWithStorageSystem: false,
    retryToConnectWithStorageSystem: false,
    connectedWithStorageSystem: false,
    failedToConnectWithStorageSystem: false,
    startConnectionWithPharmacySoftware: false,
    retryToConnectWithPharmacySoftware: false,
    connectedWithPharmacySoftware: false,
    failedToConnectWithPharmacySoftware: false,
    isPharmaSoftwareConnectionNeeded: false,
    isStorageSystemConnectionNeeded: false,
    currentStep: 'init',
    steps: [],
    starttime: 0,
    stoptime: 0,
    busy: false,
    done: false,
    error: false
}

export const PARSEDDATAMATRIX = {
    gtin: '',
    lot: '',
    expirationDate: '',
    bestBeforeDate: '',
    productionDate: '',
    batchNumber: '',
    serialNumber: ''
}


export const DEVICESTATUS: DeviceStatus = {
    device: DEVICEINFO_DEFAULT,
    deviceId: '',
    deviceType: 'vision',
    initialization: INITIALIZATION,
    internetConnection: true,
    wwks2: {
        storageSystem: {
            isStorageSystemConnectionNeeded: false,
            wwks2Dialogs: [],
            wwks2DialogIds: [],
            connectionStatus: false,
            statusInfo: undefined,
            connectedTimestamp: 0,
            closedTimestamp: 0,
            typeOfMT: true,
            id: 0,
            compatibilities: [],
            IP: ''
        },
        pharmacySoftware: {
            wwks2Dialogs: [],
            wwks2DialogIds: [],
            isPharmaSoftwareConnectionNeeded: false,
            type: '',
            connectedTimestamp: 0,
            closedTimestamp: 0,
            connectionStatus: false,
            id: 0,
            compatibilities: [],
            IP: ''
        },
        device: {
            storageSystemId: 0,
            pharmacySoftwareId: 0,
            storageSystemCompatibilities: [],
            pharmaSystemCompatibilities: [],
            deliveryOutput: 0,
            stockLocation: new Array<string>()
        }
    },
    maticDeviceIsInUse: false,
    reInitializeDeviceWhenPLCReleased: true,
    delivery: {
        cvForwardWhenBoxSensorHasDetection: false,
        cvForwardWhenBoxSensorHasDetectionBusy: false,
        autoFixAfterDeliveryFail: true,
        lastShoppingCart: SHOPPINGCART,
        releasedForNewDelivery: false,
        transportChannelBetweenStorageSystemAndDeviceIsBlocked: false,

        transportTimeBoxSensorAndDeliveryBox: 1000,
        transportTimeRobotAndDevice: 30000,
        deliveryCameraIp: '',
        autoEmptyShoppingCartAfterDelivery: false
    },
    errorCodes: [],
    plc: {
        maticType: 'MATIC',
        status: false,
        plcVersion: 0,
        emergencyActivated: false,
        emergencyStatus: '',
        released: false,
        releaseStatus: '',
        failedEventlogs: [],
        failedEventlogIds: []
    },
    barcodeScanner: {
        enabled: false,
        status: false,
        lastValue: '',
        lastValueTimestamp: 0
    },
    shoppingCart:{
        currency: 'EUR',
        limitOfProductsInCart: 0 //0 = no limit
    },
    diagnoses: {
        autoSaveDiagnosesToLochting: false,
        autoSaveDiagnosesToLochtingInterval: 300000,
    }
}