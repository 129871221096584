import * as React from 'react';

import { Domain } from 'api';
import { useGettext } from 'data-store';

export default function DayLabel(props: { day: Domain.Day }) {
    const { gettext } = useGettext();

    let label;
    switch (props.day) {
        case 'Monday':
            label = gettext('Monday');
            break;
        case 'Tuesday':
            label = gettext('Tuesday');
            break;
        case 'Wednesday':
            label = gettext('Wednesday');
            break;
        case 'Thursday':
            label = gettext('Thursday');
            break;
        case 'Friday':
            label = gettext('Friday');
            break;
        case 'Saturday':
            label = gettext('Saturday');
            break;
        case 'Sunday':
            label = gettext('Sunday');
            break;
    }

    if (label) {
        return <>{label}</>;
    }

    return null;
}
