import * as React from 'react';

import classNames from 'classnames';

import { BaseProps } from '@/core';

import styles from './Card.scss';

export type IProps = BaseProps &
    Omit<React.HTMLProps<HTMLDivElement>, 'ref' | 'css'> & {
        vSpacing?: 'none' | 's' | 'm' | 'l';
        hSpacing?: 'none' | 's' | 'm';
        elevated?: boolean;
        elevatedHover?: boolean;
        noOutline?: boolean;
        forwardRef?: React.Ref<HTMLDivElement>;
    };

class Card extends React.PureComponent<IProps> {
    render() {
        const { vSpacing, hSpacing, elevated, elevatedHover, noOutline, className, children, forwardRef, ...rest } = this.props;

        return (
            <div
                {...rest}
                ref={forwardRef}
                className={classNames(
                    styles.Card,
                    vSpacing ? 'v-spacing-' + vSpacing : null,
                    hSpacing ? 'h-spacing-' + hSpacing : null,
                    elevated ? 'elevation-1' : null,
                    elevatedHover ? styles.ElevatedHover : null,
                    noOutline ? 'no-outline' : null,
                    className,
                )}
            >
                {children}
            </div>
        );
    }
}

export default React.forwardRef<HTMLDivElement, IProps>((props, ref) => {
    return (
        <Card
            forwardRef={ref}
            {...props}
        />
    );
});
