import React from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment-timezone';

import { config } from 'config';
import { selectLoggedInUser } from 'data-store';

interface DateTimeDisplayProps {
    date?: string | number | null;
    timezone?: string | null;
    showDate?: boolean;
    showTime?: boolean;
    formatDate?: string;
    formatTime?: string;
}

const DateTimeDisplay = ({
    date,
    timezone = 'UTC',
    showDate = true,
    showTime = false,
    formatDate = config.uiDateFormat,
    formatTime = config.timeFormat,
}: DateTimeDisplayProps) => {
    if (!date) {
        return null;
    }
    const loggedInUser = useSelector(selectLoggedInUser);
    const branchTimezone = loggedInUser?.branchTimezone;
    const userTimeZone = branchTimezone ? branchTimezone : timezone;
    const formattedDate = formateDateTime({
        date,
        timezone: userTimeZone,
        showDate,
        showTime,
        formatDate,
        formatTime,
    });

    return <>{formattedDate}</>;
};

export const formateDateTime = ({
    date,
    timezone,
    showDate = true,
    showTime = false,
    formatDate = config.uiDateFormat,
    formatTime = config.timeFormat,
}: DateTimeDisplayProps) => {
    const isStringOnly = date && typeof date === 'string' && !date?.includes('T');
    if (isStringOnly) {
        return moment(date).format(formatDate);
    }
    const dateTime = moment(date).tz(timezone || 'UTC');
    const format = showDate && showTime ? `${formatDate} ${formatTime}` : showDate ? formatDate : formatTime;
    const formattedDate = dateTime.format(format);
    return formattedDate;
};

export default DateTimeDisplay;
