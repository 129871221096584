import * as React from 'react';

import { Icon } from '../icon';
import { Flex } from '../layout';
import Card, { IProps } from './Card';

import styles from './CardToggle.scss';

export default function CardToggle({ header, children, ...rest }: { header: React.ReactNode; children: React.ReactNode } & IProps) {
    const [isOpened, setIsOpened] = React.useState(false);
    return (
        <Card {...rest}>
            <a
                href=""
                onClick={e => {
                    e.preventDefault();
                    setIsOpened(!isOpened);
                }}
                className={styles.CardToggleHeader}
            >
                <Flex
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <div className={styles.CardToggleHeaderText}>{header}</div>
                    <Icon
                        className={styles.CardToggleHeaderArrow}
                        type={isOpened ? 'action_arrow_up' : 'action_arrow_down'}
                    />
                </Flex>
            </a>

            {isOpened ? <div className={styles.CardToggleBody}>{children}</div> : null}
        </Card>
    );
}
