import { Country, GeowacthPharmacyOnDutyList, GardePharmacyOnDutyList, NotdienstdatenPharmacyOnDutyList } from '@/Domain';

import Endpoint from './Endpoint';

interface GetPharmaciesOnDutyParams {
    country: Country;
    apb?: string | null;
    limit?: number;
    language?: string;
}

export default class PharmacyOnDutyEndpoint extends Endpoint {
    public GetPharmaciesOnDuty = async ({
        country,
        language = 'no_preference',
    }: GetPharmaciesOnDutyParams): Promise<
        GeowacthPharmacyOnDutyList | GardePharmacyOnDutyList | NotdienstdatenPharmacyOnDutyList | null
    > => {
        if (country === 'BE') {
            if (['no_preference', 'de', 'en', 'fr', 'nl'].indexOf(language) === -1) {
                language = 'no_preference';
            }

            try {
                const response = await this.client.get({
                    url: '/device-pharmacy-on-duty-overview',
                });

                this.guardResponseSchema('#/definitions/GeowacthPharmacyOnDutyList', response);
                return response;
            } catch (e) {
                console.error(e);
            }
        } else if (country === 'LU') {
            try {
                const response = await this.client.get({
                    url: '/device-pharmacy-on-duty-overview',
                });
                this.guardResponseSchema('#/definitions/GardePharmacyOnDutyList', response);

                return response;
            } catch (e) {
                console.error(e);
            }
        } else if (country === 'DE') {
            try {
                const response = await this.client.get({
                    url: '/device-pharmacy-on-duty-overview',
                });
                this.guardResponseSchema('#/definitions/NotdienstdatenPharmacyOnDutyList', response);

                return response;
            } catch (e) {
                console.error(e);
            }
        }
        return null;
    };
}
