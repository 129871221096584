import { Domain } from 'api';

import { cmsApi } from '@/api';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';

type CmsItemProps = keyof Domain.CmsItem;

export const defaultSorting: Domain.Sorting<CmsItemProps> = {
    field: 'updatedOn',
    direction: 'descending',
};

export const pageSize = 20;

export const selectors = makeSelectors<Domain.CmsItem, CmsItemProps>({
    getState: rootState => rootState.cmsItem.overview,
});

export const reducerActions = makeReducerActions<Domain.CmsItem, CmsItemProps>({
    reducerPrefix: '@cmsItem/overview',
});

export const actions = makeActions<Domain.CmsItem, CmsItemProps>({
    dataTableSaveKey: 'cmsItem-v1',
    loadApi: options => cmsApi.GetCmsItems(options.pagination, options.sorting, options.filters),
    defaultSorting,
    pageSize,
    getBaseUrl: () => '/cms/items',
    reducerActions,
    selectors,
});

export const overviewReducer = makeReducer<Domain.CmsItem, CmsItemProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
