import { BaseOrderDetails, OrderLine } from './BaseOrder';
import { EnrichedBranch } from './Branch';
import { EnrichedCompany } from './Company';
import { Locale } from './Localisation';
import { Pagination } from './Pagination';
import { ProductCodes } from './ProductCode';
import { SelectedProductDetails } from './ProductSelection';
import { Sorting } from './Sorting';
import { VendingMachineDeliveryType } from './VendingMachineDelivery';
import { WebshopOrderDetails } from './WebshopOrder';

export const PickupOwnStatuses = ['incomplete', 'complete', 'finalised', 'failed', 'canceled', 'failedToDistribute'] as const;
export const PickupStatuses = [
    'incomplete',
    'complete',
    'finalised',
    'failed',
    'canceled',
    'deliveryRequested',
    'deliveryRequestFailed',
    'delivered',
    'deliveryFailed',
    'pickedUp',
    'pickUpFailed',
    'deliveryInProgress',
    'failedToDistribute',
] as const;
export type PickupStatus = (typeof PickupStatuses)[number];
export interface ProductMetaDataV1 {
    packDetails: {
        id?: string | null;
        scanCode?: string | null;
        expiryDate?: string | null;
        externalId?: string | null;
        batchNumber?: string | null;
        reservationPackSequenceId?: string | null;
    }[];
    packIds: string[];
}

export interface ProductMetaDataV2 {
    id?: string | null;
    scanCode?: string | null;
}

export type ProductMetadata = ProductMetaDataV1 | ProductMetaDataV2 | [] | null;

export interface PickupProduct {
    productName: string;
    pickupLocation?: string | null;
    locationType?: VendingMachineDeliveryType;
    toBeReservedByConfiguredIntegrationId?: string | null;
    isComplete?: boolean | null;
    productId?: string | null;
    productCodes?: ProductCodes | [] | null;
    metaData?: ProductMetadata;
    pickupProductId: string;
}

export function isProductMetaDataV1(metaData: ProductMetadata): metaData is ProductMetaDataV1 {
    return !!(
        metaData &&
        !Array.isArray(metaData) &&
        metaData.hasOwnProperty('packDetails') &&
        metaData.hasOwnProperty('packIds') &&
        Array.isArray((metaData as ProductMetaDataV1).packDetails)
    );
}

export function isProductMetaDataV2(metaData: ProductMetadata): metaData is ProductMetaDataV2 {
    return !!(
        metaData &&
        !Array.isArray(metaData) &&
        metaData.hasOwnProperty('id') &&
        (metaData as ProductMetaDataV2).id &&
        metaData.hasOwnProperty('scanCode') &&
        (metaData as ProductMetaDataV2).scanCode
    );
}

export type PickupProductsCollection = PickupProduct[];

type OrderDetails = Omit<
    WebshopOrderDetails | BaseOrderDetails,
    | 'customerName'
    | 'customerAddress'
    | 'customerZipCode'
    | 'customerCity'
    | 'customerCountry'
    | 'shippingAddress'
    | 'shippingZipCode'
    | 'shippingCity'
    | 'shippingCountry'
    | 'paymentMethod'
    | 'shippingName'
>;

export interface Pickup {
    pickupId: string;

    companyId: string;
    company?: EnrichedCompany | null;
    branchId: string;
    branch?: EnrichedBranch | null;

    pickupCode: string;
    pickupStatus: PickupStatus;

    createdAt: string;
    updatedAt?: string | null;

    orderId?: OrderDetails['orderId'] | null;
    orderDetails?:
        | (OrderDetails & {
              orderLines: OrderLine[];
          })
        | null;
    order?:
        | (OrderDetails & {
              orderLines: OrderLine[];
          })
        | null;

    articlesToReserve: PickupProductsCollection;
    productsToDeliver: PickupProductsCollection;
    productDetails: SelectedProductDetails[];
    remarks?: string | null;
    datamatrix?: string | null;

    customerEmail?: string | null;
    customerPhone?: string | null;
    requiresPayment?: boolean | null;

    isOverBoxReservation?: boolean | null;

    reservationDelivery?: {
        id: string;
    } | null;

    deliveries: OrderDetails['deliveries'];
}

export type PickupDetails = Pickup & {
    customerLocale?: Locale | null;
};

export interface PickupsPage {
    items: Pickup[];
    total: number;
    pagination: Pagination;
    sorting?: Sorting<keyof Pickup> | null;
    search?: string | null;
}

export type BacksideStockingPickup = Omit<Pickup, 'companyId' | 'company' | 'branchId' | 'branch' | 'deliveries' | 'productsToDeliver'>;

export interface BacksideStockingPickupsPage {
    items: BacksideStockingPickup[];
    total: number;
    sorting?: Sorting<keyof Pickup> | null;
}

export type CreatePickupProductIdentifiers =
    | {
          productId: string;
          productCodes: ProductCodes;
      }
    | {
          productId: string;
          productCodes?: ProductCodes;
      }
    | {
          productId?: string;
          productCodes: ProductCodes;
      };

export type CreatePickupProduct = {
    productName: string;
    toBeReservedInConfiguredIntegrationId: string;
    priceInCents?: number;
    vatRate?: number;
} & CreatePickupProductIdentifiers;

export type CreatePickupProductsCollection = CreatePickupProduct[];

export interface CreatePickupPayload {
    pickupId: string;
    companyId: string;
    branchId: string;
    products: CreatePickupProductsCollection;
    customerLocale: Locale;
    requiresPayment: boolean;

    emailAddress?: string;
    phoneNumber?: string;
    remarks?: string;
    isOverBoxPickUp?: boolean;
    createForOrderId?: string;
}

export interface OverrideOrderLinesPayload {
    orderId: string;
    orderLineOverrides: {
        orderLineId: string;
        unitPriceIncludingVat: number;
    }[];
}

export interface CancelPickupPayload {
    pickupId: string;
}

export interface SendPickupCanceledByPharmacistEmailPayload {
    pickupId: string;
}

export interface SendPickupFailedForCustomerMailPayload {
    orderId?: string;
    pickupId?: string;
}

export interface SendPickupFailedForPharmacistMailPayload {
    orderId?: string;
    pickupId?: string;
}

export interface UpdatePickupRemarksPayload {
    pickupId: string;
    remarks: string | null;
}

export interface UpdatePickupCustomerEmailAddressPayload {
    pickupId: string;
    emailAddress: string;
}

export interface UpdatePickupCustomerPhoneNumberPayload {
    pickupId: string;
    phoneNumber: string | null;
}
