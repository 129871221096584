import { ThunkAction } from '@/action';
import { infoItemsState } from '@/CmsItem';

export const loadInfoCmsItems = (): ThunkAction => async dispatch => {
    await dispatch(
        infoItemsState.preloadCMSItems([
            'vendingMachineCatalogueIsEnabledInfo',
            'vendingMachineCallPharmacistIsEnabledInfo',
            'vendingMachinePharmacyOnDutyIsEnabledInfo',
            'vendingMachineReservationsAreEnabledInfo',
            'vendingMachineCatalogueIsEnabledWhenOffDutyInfo',
            'vendingMachineCallPharmacistIsEnabledWhenOffDutyInfo',
            'vendingMachinePharmacyOnDutyIsEnabledWhenOffDutyInfo',
            'vendingMachineReservationsAreEnabledWhenOffDutyInfo',
        ]),
    );
};

export const load = (): ThunkAction => async dispatch => {
    await dispatch(loadInfoCmsItems());
};
