import { createAction, Selector, createReducer } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { ThunkAction, withPayloadType } from '@/action';
import { cmsApi } from '@/api';
import { selectCurrentLocale } from '@/I18n';
import { Filters } from '@/makeOverviewState';
import { RootState } from '@/store';

export interface State {
    unreadPublicAnnouncementsCount: number;
    readPublicAnnouncements: string[];
    publicAnnouncements?: Domain.PublicAnnouncementsPage;
    tags?: Domain.CmsTagsPage;
    pagination: Domain.Pagination;
    search: string;
    sorting: Domain.Sorting;
    filters: Filters;
}
export const defaultSorting: Domain.Sorting = {
    field: 'updatedOn',
    direction: 'descending',
};

export const initialState: State = {
    unreadPublicAnnouncementsCount: 0,
    readPublicAnnouncements: [],
    publicAnnouncements: undefined,
    tags: undefined,
    sorting: defaultSorting,
    pagination: {
        page: 1,
        size: 30,
    },
    search: '',
    filters: {},
};

const reducerActions = {
    setPublicAnnouncements: createAction(
        '@cmsItemPublicAnnouncements/setPublicAnnouncements',
        withPayloadType<Domain.PublicAnnouncementsPage>(),
    ),
    setUnreadPublicAnnouncementsCount: createAction(
        '@cmsItemPublicAnnouncements/setUnreadPublicAnnouncementsCount',
        withPayloadType<number>(),
    ),
    setReadPublicAnnouncements: createAction('@cmsItemPublicAnnouncements/setReadPublicAnnouncements', withPayloadType<string[]>()),
    setPagination: createAction('@cmsItemPublicAnnouncements/setPagination', withPayloadType<Domain.Pagination>()),
    setPaginationPage: createAction('@cmsItemPublicAnnouncements/setPaginationPage', withPayloadType<Domain.Pagination['page']>()),
    setPaginationSize: createAction('@cmsItemPublicAnnouncements/setPaginationSize', withPayloadType<Domain.Pagination['size']>()),
    setSearch: createAction('@cmsItemPublicAnnouncements/setSearch', withPayloadType<string>()),
    setFilters: createAction('@cmsItemPublicAnnouncements/setFilters', withPayloadType<Filters>()),
    setTags: createAction('@cmsItemPublicAnnouncements/setTags', withPayloadType<Domain.CmsTagsPage>()),
};

export const publicAnnouncementsOverviewReducer = createReducer(initialState, builder =>
    builder
        .addCase(reducerActions.setUnreadPublicAnnouncementsCount, (state, action) => {
            state.unreadPublicAnnouncementsCount = action.payload;
        })
        .addCase(reducerActions.setReadPublicAnnouncements, (state, action) => {
            state.readPublicAnnouncements = action.payload;
        })
        .addCase(reducerActions.setPublicAnnouncements, (state, action) => {
            state.publicAnnouncements = action.payload;
        })
        .addCase(reducerActions.setPagination, (state, action) => {
            state.pagination = action.payload;
        })
        .addCase(reducerActions.setPaginationPage, (state, action) => {
            state.pagination.page = action.payload;
        })
        .addCase(reducerActions.setPaginationSize, (state, action) => {
            state.pagination.size = action.payload;
        })
        .addCase(reducerActions.setSearch, (state, action) => {
            state.search = action.payload;
        })
        .addCase(reducerActions.setFilters, (state, action) => {
            state.filters = action.payload;
        })
        .addCase(reducerActions.setTags, (state, action) => {
            state.tags = action.payload;
        }),
);

export const maybeSelectPublicAnnouncements: Selector<RootState, Domain.PublicAnnouncementsPage | undefined> = state =>
    state.cmsItem.publicAnnouncementsOverview.publicAnnouncements;
export const selectPublicAnnouncements: Selector<RootState, Domain.PublicAnnouncementsPage> = state => {
    const selectedPublicAnnouncements = state.cmsItem.publicAnnouncementsOverview.publicAnnouncements;

    if (!selectedPublicAnnouncements) {
        throw new Error('Public announcements data not loaded');
    }

    return selectedPublicAnnouncements;
};
export const maybeSelectTags: Selector<RootState, Domain.CmsTagsPage | undefined> = state => state.cmsItem.publicAnnouncementsOverview.tags;
export const selectUnreadPublicAnnouncementsCount: Selector<RootState, number> = state =>
    state.cmsItem.publicAnnouncementsOverview.unreadPublicAnnouncementsCount;
export const selectReadPublicAnnouncements: Selector<RootState, string[]> = state =>
    state.cmsItem.publicAnnouncementsOverview.readPublicAnnouncements;
export const selectPagination: Selector<RootState, Domain.Pagination> = state => state.cmsItem.publicAnnouncementsOverview.pagination;
export const selectSearch: Selector<RootState, string> = state => state.cmsItem.publicAnnouncementsOverview.search;
export const selectFilters: Selector<any, Filters> = state => state.cmsItem.publicAnnouncementsOverview.filters;

export const setUnreadPublicAnnouncementsCount =
    (unreadPublicAnnouncementsCount: number): ThunkAction =>
    async dispatch => {
        await dispatch(reducerActions.setUnreadPublicAnnouncementsCount(unreadPublicAnnouncementsCount));
    };

export const setReadPublicAnnouncements =
    (readPublicAnnouncementsCount: string[]): ThunkAction =>
    async dispatch => {
        await dispatch(reducerActions.setReadPublicAnnouncements(readPublicAnnouncementsCount));
    };

export const setPaginationPage =
    (page: Domain.Pagination['page']): ThunkAction =>
    async dispatch => {
        await dispatch(reducerActions.setPaginationPage(page));
    };

export const setPaginationSize =
    (size: Domain.Pagination['size']): ThunkAction =>
    async dispatch => {
        await dispatch(reducerActions.setPaginationSize(size));
    };

export const setSearch =
    (search: string): ThunkAction =>
    async dispatch => {
        dispatch(reducerActions.setSearch(search));
    };

export const setFilters =
    (filters: Filters): ThunkAction =>
    async dispatch => {
        await dispatch(reducerActions.setFilters(filters));
    };

export const loadPublicAnnouncements = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const locale = selectCurrentLocale(state);
    const search = selectSearch(state);
    const pagination = selectPagination(state);
    const filters = selectFilters(state);

    const publicAnnouncements = await cmsApi.GetPublicAnnouncements(locale, pagination, defaultSorting, search, filters);

    await dispatch(reducerActions.setPublicAnnouncements(publicAnnouncements));
};

export const loadTags = (): ThunkAction => async (dispatch, getState) => {
    const state = getState();
    const locale = selectCurrentLocale(state);

    const tags = await cmsApi.GetPublicCmsTags({ size: 999, page: 1 }, { field: 'createdOn', direction: 'descending' }, locale);

    await dispatch(reducerActions.setTags(tags));
};

export const load = (): ThunkAction => async dispatch => {
    await Promise.all([dispatch(loadPublicAnnouncements()), dispatch(loadTags())]);
};
