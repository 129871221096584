import { Domain } from 'api';

import { ThunkAction } from '@/action';
import { branchCartRulesEndpoint } from '@/api';
import * as categoryTreeState from '@/AvailableProduct/categoriesTreeState';
import { selectGlobalStateBranch } from '@/Branch/globalSelectionState';
import { getCountryCodeFromLocale } from '@/getCountryCodeFromLocale';
import { selectCurrentLocale } from '@/I18n';
import { makeActions, makeReducer, makeReducerActions, makeSelectors } from '@/makeOverviewState';
import { URLParams, URLQuery } from '@/routing';

import { loadScientificCategoryTrees } from './scientificCategoryState';

export type BranchCartRuleProps = keyof Domain.BranchCartRuleItem;

export const defaultSorting: Domain.Sorting<BranchCartRuleProps> = {
    field: 'activeStatus',
    direction: 'ascending',
};

export const pageSize = 999;

export const selectors = makeSelectors<Domain.BranchCartRuleItem, BranchCartRuleProps>({
    getState: rootState => rootState.branchCartRules.overview,
});

export const reducerActions = makeReducerActions<Domain.BranchCartRuleItem, BranchCartRuleProps>({
    reducerPrefix: '@BranchCartRules/overview',
});

export const basicActions = makeActions<
    Domain.BranchCartRuleItem,
    BranchCartRuleProps,
    {
        deviceIds: string;
    }
>({
    dataTableSaveKey: 'BranchCartRulesOverview-v1',
    loadApi: options => {
        const branchId = options.ownershipIds.branchId || '';
        return branchCartRulesEndpoint.GetBranchCartRules(branchId, options.pagination, options.sorting, {
            activeStatus: 'active',
        });
    },
    getBaseUrl: () => '/vending-machines/cart-rules',
    defaultSorting,
    pageSize,
    reducerActions,
    selectors,
});
export const actions = {
    ...basicActions,
    load:
        (options: { urlParams: URLParams; urlQuery: URLQuery }): ThunkAction =>
        async (dispatch, getState) => {
            const state = getState();
            const locale = selectCurrentLocale(state);
            const globalBranchDetails = selectGlobalStateBranch(state);
            const country = globalBranchDetails?.country ? globalBranchDetails.country : getCountryCodeFromLocale(locale);
            await Promise.all([
                dispatch(basicActions.load(options)),
                dispatch(loadScientificCategoryTrees(country)),
                dispatch(
                    categoryTreeState.actions.load({
                        ...options,
                        urlParams: {
                            locale: locale,
                            ...options.urlParams,
                        },
                    }),
                ),
            ]);
        },
};

export const overviewReducer = makeReducer<Domain.BranchCartRuleItem, BranchCartRuleProps>({
    defaultSorting,
    pageSize,
    reducerActions,
});
